import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, ApolloError } from '@apollo/client'
// import { ApolloError } from 'apollo-client'
import { Button, Paper, makeStyles, Typography } from '@material-ui/core'
import { CREATE_ADMIN } from 'mutation'
import { CustomInputComponent } from 'components'

const useStyles = makeStyles(theme => ({
  paper: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // backgroundColor: 'rgba(255,255,255,0.1)',
    // borderRadius: 15,
    margin: '0 auto',
    width: 560,
    padding: '50px 76px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  successText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0)
  },
  text: {
    color: 'black',
    textAlign: 'left',
    width: '100%',
    margin: 0
  },
  invalidEmail: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textAlign: 'left',
    padding: theme.spacing(1)
  }
  // input: {
  //   '& input': {
  //     fontSize: 17,
  //     padding: 19
  //   }
  // }
}))

interface LoginFormValues {
  email: string
  name: string
  lastname: string
  password: string
  confirmPassword: string
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message
}

const LoginForm: React.FC = () => {
  const classes = useStyles()
  const [adminCreated, setAdminCreated] = useState(false)
  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      name: '',
      lastname: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Indirizzo email non valido')
        .required('Campo obbligatorio'),
      password: Yup.string()
        .length(8, 'La password deve essere lunga almeno 8 caratteri')
        .required('Campo obbligatorio'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Le password non coincidono!')
        .required('Campo obbligatorio')
    }),
    onSubmit: async values => {
      await createAdmin({
        variables: {
          data: {
            email: values.email,
            password: values.password,
            name: values.name,
            lastname: values.lastname
          }
        }
      })
    }
  })
  const [createAdmin, { error, loading }] = useMutation(CREATE_ADMIN, {
    onCompleted: data => {
      setAdminCreated(true)
    },
    onError: err => {
      formik.resetForm()
    }
  })

  return (
    <Paper className={classes.paper}>
      {adminCreated ? (
        <>
          <Typography variant="h1" style={{ marginBottom: 24 }}>
            Registrazione admin!
          </Typography>
          <Typography variant="h3" style={{ marginBottom: 32 }}>
            L'operatore è stato registrato con successo!{' '}
            <a href="/login">Clicca qui</a> per loggarti.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h1" style={{ marginBottom: 24 }}>
            Registrazione admin!
          </Typography>
          <Typography variant="h3" style={{ marginBottom: 32 }}>
            Benvenuto nella piattaforma Noloplan2X. Registra l'operator ADMIN.
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.form}
            noValidate
          >
            <CustomInputComponent
              name="email"
              error={formik.touched.email && !!formik.errors.email}
              id="email"
              label="Email"
              value={formik.values.email}
              errorLabel={formik.errors.email}
              onChange={formik.handleChange}
              disabled={loading}
            />
            <CustomInputComponent
              name="name"
              error={formik.touched.name && !!formik.errors.name}
              id="name"
              label="Nome"
              value={formik.values.name}
              errorLabel={formik.errors.name}
              onChange={formik.handleChange}
              disabled={loading}
            />
            <CustomInputComponent
              name="lastname"
              error={formik.touched.lastname && !!formik.errors.lastname}
              id="lastname"
              label="Nome"
              value={formik.values.lastname}
              errorLabel={formik.errors.lastname}
              onChange={formik.handleChange}
              disabled={loading}
            />

            <CustomInputComponent
              name="password"
              error={formik.touched.password && !!formik.errors.password}
              id="password"
              label="Password"
              type="password"
              value={formik.values.password}
              errorLabel={formik.errors.password}
              onChange={formik.handleChange}
              disabled={loading}
            />
            <CustomInputComponent
              name="confirmPassword"
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              id="confirmPassword"
              label="Conferma password"
              type="password"
              value={formik.values.confirmPassword}
              errorLabel={formik.errors.confirmPassword}
              onChange={formik.handleChange}
              disabled={loading}
            />

            {error && (
              <p className={classes.invalidEmail}>{formatError(error)}</p>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              disabled={loading}
              className={classes.submit}
            >
              Registra
            </Button>
          </form>
        </>
      )}
    </Paper>
  )
}

export default LoginForm
