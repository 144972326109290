import React from 'react'

import { Paper, makeStyles, Typography } from '@material-ui/core'

import {
  getClient_getClient as ClientData,
  getClient_getClient_ClientPrivate as ClientPrivateType,
  getClient_getClient_ClientCompany as ClientCompanyType
} from 'generated/schemaTypes'
import { ButtonDelete, ButtonModify } from 'components'

interface ClientDetailProps {
  client: ClientData
  onUpdateClient: () => void
  onDeleteClient?: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4)
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    margin: '20px 0 40px'
  }
}))

const companyTypes = {
  PUBBLICA_AMMINISTRAZIONE: 'Pubblica amministrazione',
  PERSONA_GIURIDICA: 'Persona Giuridica',
  DITTA_INDIVIDUALE: 'Ditta individuale'
}

const ClientDetail: React.FC<ClientDetailProps> = ({
  client,
  onUpdateClient,
  onDeleteClient
}) => {
  const classes = useStyles()
  const isPrivate = client.__typename === 'ClientPrivate'

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h1">
        {isPrivate
          ? `${client.name} ${(client as ClientPrivateType).lastname}`
          : `${client.name}`}
      </Typography>
      <Typography variant="h2">
        (
        {isPrivate
          ? `Persona fisica`
          : companyTypes[(client as ClientCompanyType).companyType]}
        )
      </Typography>

      <div className={classes.ctaWrapper}>
        <ButtonModify label="Modifica cliente" onClick={onUpdateClient} />
        <ButtonDelete
          disabled={!onDeleteClient}
          label="Elimina cliente"
          onClick={onDeleteClient ? onDeleteClient : () => {}}
        />
      </div>

      <div className={classes.data}>
        <Typography variant="h4">Indirizzo</Typography>
        <Typography variant="h5">
          {client.address.addressLine}, {client.address.zip}{' '}
          {client.address.hamlet} {client.address.city} (
          {client.address.province})
        </Typography>
        <Typography variant="h4">Email</Typography>
        <Typography variant="h5">{client.email}</Typography>
        {!isPrivate && (
          <>
            <Typography variant="h4">PEC</Typography>
            <Typography variant="h5">
              {(client as ClientCompanyType).pec || '-'}
            </Typography>
          </>
        )}
        <Typography variant="h4">Cellulare</Typography>
        <Typography variant="h5">{client.mobile}</Typography>
        <Typography variant="h4">Telefono fisso</Typography>
        <Typography variant="h5">{client.phone || '-'}</Typography>
        <Typography variant="h4">Fax</Typography>
        <Typography variant="h5">{client.fax || '-'}</Typography>
        <Typography variant="h4">Codice fiscale</Typography>
        <Typography variant="h5">{client.cf}</Typography>
        <Typography variant="h4">IBAN</Typography>
        <Typography variant="h5">{client.iban}</Typography>
        {!isPrivate && (
          <>
            <Typography variant="h4">Partita Iva</Typography>
            <Typography variant="h5">
              {(client as ClientCompanyType).vat}
            </Typography>
            <Typography variant="h4">Legale rappresentante</Typography>
            <Typography variant="h5">
              {(client as ClientCompanyType).legalRepresentativeName || '-'}
            </Typography>
            <Typography variant="h4">
              Legale rappresentante (codice fiscale)
            </Typography>
            <Typography variant="h5">
              {(client as ClientCompanyType).legalRepresentativeCF || '-'}
            </Typography>
          </>
        )}
        <Typography variant="h4">Tipologia contatto</Typography>
        <Typography variant="h5">{client.contactType || '-'}</Typography>
        <Typography variant="h4">Privacy</Typography>
        <Typography variant="h5">
          {client.privacy ? 'Accettata' : 'Non accettata'}
        </Typography>
      </div>
    </Paper>
  )
}

export default ClientDetail
