import React from 'react'

import { useQuery, useMutation } from '@apollo/client'
import { TextField, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import gql from 'graphql-tag'

import { GET_OPERATORS_LIST_SIMPLE } from 'query'

import { SET_CAR_OWNER } from 'mutation'
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2)
    }
  },
  textSearch: {
    marginBottom: theme.spacing(4)
  },
  autocompleteOption: {},
  autocomplete: {}
}))

interface CarOwnershipProps {
  operatorId: string | null
  carId: string
}

const CarOwnership: React.FC<CarOwnershipProps> = ({ operatorId, carId }) => {
  const {
    loading: loadingOperators,
    data: dataOperators,
    error: errorOperators
  } = useQuery(GET_OPERATORS_LIST_SIMPLE)

  const [setCarOwner] = useMutation(SET_CAR_OWNER, {
    onCompleted: () => {},
    onError: err => {
      console.log('Errore', err)
    },
    update: async (cache, { data: setCarOwner }) => {
      cache.writeFragment({
        id: `Car:${carId}`,
        fragment: gql`
          fragment operator on Car {
            operator {
              id
            }
          }
        `,
        data: {
          operator: setCarOwner.setCarOwner
            ? {
                id: setCarOwner.setCarOwner,
                __typename: 'Operator'
              }
            : null,
          __typename: 'Car'
        }
      })
    }
  })

  const classes = useStyles()
  if (loadingOperators) return <div>Loading operators...</div>
  if (errorOperators)
    return <div>Impossibile caricare la lista degli operatori</div>

  const options = dataOperators.getOperators

  const currentOperator =
    options.find((operator: any) => operator.id === operatorId) || null

  return (
    <Autocomplete
      className={classes.autocomplete}
      options={options}
      onChange={(event: any, value: any, reason: string) => {
        // onChange(value)
        console.log('ownership', value)
        const variables: any = {
          carId
        }
        if (value) variables.operatorId = value.id
        setCarOwner({
          variables
        })
      }}
      value={currentOperator}
      // popupIcon={<ArrowDownSVG />}
      getOptionLabel={(option: any) => `${option.name} ${option.lastname}`}
      getOptionSelected={(option: any, value: any) => {
        // console.log(option)
        // console.log(currentOwner)
        return option.id === operatorId
      }}
      renderOption={(option: any) => (
        <div className={classes.autocompleteOption}>
          {option.name} {option.lastname}
        </div>
      )}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth
          label="Assegna operatore"
          color="primary"
          // variant="outlined"
          size="small"
        />
      )}
    />
  )
}

export default CarOwnership
