import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { provinces } from 'data/province'

import { CREATE_DEALER, UPDATE_DEALER } from 'mutation'
import { CustomInputComponent } from 'components'
import { color } from 'theme'

import {
  getDealer_getDealer as DealerType,
  createDealerVariables,
  updateDealerVariables,
  createDealer,
  updateDealer
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  baseInfoWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    gridTemplateRows: 'auto auto auto',
    position: 'relative',
    marginBottom: 30
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  grid: {
    display: 'grid',
    margin: 'auto',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    columnGap: 50
  },
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignContent: 'space-between',
    '& > *': {
      width: '100%',
      '& > *': {
        width: '100%'
      }
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 360,
    height: 180,
    backgroundPosition: 'center'
  }
}))

export interface DealerFormValues {
  displayName: string
  company: string
  password: string
  pi: string
  cf: string
  sdi: string
  phone: string
  email: string
  iban: string
  addressLine: string
  zip: string
  hamlet: string
  city: string
  province: string
  primaryContactName: string
  primaryContactLastname: string
  primaryContactPhone: string
  secondaryContactName: string
  secondaryContactLastname: string
  secondaryContactPhone: string
}

const defaultInitialValues = {
  password: '',
  displayName: '',
  company: '',
  pi: '',
  cf: '',
  sdi: '',
  email: '',
  phone: '',
  addressLine: '',
  zip: '',
  city: '',
  hamlet: '',
  iban: '',
  province: '',
  primaryContactName: '',
  primaryContactLastname: '',
  primaryContactPhone: '',
  secondaryContactName: '',
  secondaryContactLastname: '',
  secondaryContactPhone: ''
}

const getValidationSchema = (create: boolean): Yup.ObjectSchema => {
  let schema: any = {
    displayName: Yup.string().required('Campo obbligatorio'),
    company: Yup.string().required('Campo obbligatorio'),
    pi: Yup.string().required('Campo obbligatorio'),
    cf: Yup.string().required('Campo obbligatorio'),
    sdi: Yup.string().required('Campo obbligatorio'),
    phone: Yup.string().required('Campo obbligatorio'),
    iban: Yup.string().required('Campo obbligatorio'),
    addressLine: Yup.string().required('Campo obbligatorio'),
    zip: Yup.string().required('Campo obbligatorio'),
    city: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    primaryContactName: Yup.string().required('Campo obbligatorio'),
    primaryContactLastname: Yup.string().required('Campo obbligatorio'),
    primaryContactPhone: Yup.string().required('Campo obbligatorio'),
    email: Yup.string()
      .email('Indirizzo email non valido')
      .required('Campo obbligatorio')
  }
  if (create)
    schema.password = Yup.string()
      .min(8, 'Lunghezza minima 8 caratteri')
      .required('Campo obbligatorio')
  return Yup.object(schema)
}

const parseInitialValue = (input: DealerType): DealerFormValues => {
  return {
    password: '',
    company: input.company,
    displayName: input.displayName,
    cf: input.cf,
    pi: input.pi,
    sdi: input.sdi,
    email: input.email,
    phone: input.phone,
    iban: input.iban,
    addressLine: input.address.addressLine,
    zip: input.address.zip,
    city: input.address.city,
    hamlet: input.address.hamlet ? input.address.hamlet : '',
    province: input.address.province,
    primaryContactName: input.primaryContact.name,
    primaryContactLastname: input.primaryContact.lastname,
    primaryContactPhone: input.primaryContact.phone,
    secondaryContactName: input.secondaryContact
      ? input.secondaryContact.name
      : '',
    secondaryContactLastname: input.secondaryContact
      ? input.secondaryContact.lastname
      : '',
    secondaryContactPhone: input.secondaryContact
      ? input.secondaryContact.phone
      : ''
  }
}

interface DealerFormProps {
  open: boolean
  handleClose: () => void
  dealer: DealerType | null
}

const ClientForm: React.FC<DealerFormProps> = ({
  open,
  dealer,
  handleClose
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const updateMode = !!dealer
  // const [showError, setShowError] = useState(true)
  const classes = useStyles()

  const initialValues = dealer ? parseInitialValue(dealer) : null

  const [createDealerMutation, { error }] = useMutation<
    createDealer,
    createDealerVariables
  >(CREATE_DEALER, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Concessionario creato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
      history.push(`/dealers/${data.createDealer.id}`)
    },
    onError: err => {
      console.log('Errore!', err)
    }
  })

  const [updateDealerMutation] = useMutation<
    updateDealer,
    updateDealerVariables
  >(UPDATE_DEALER, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Concessionario modificato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore', err)
    }
  })

  const formik = useFormik<DealerFormValues>({
    initialValues: initialValues || defaultInitialValues,
    validationSchema: getValidationSchema(!updateMode),
    enableReinitialize: true,
    onSubmit: async values => {
      let variables: any = {
        data: {
          company: values.company,
          displayName: values.displayName,
          cf: values.cf,
          pi: values.pi,
          sdi: values.sdi,
          email: values.email,
          iban: values.iban,
          phone: values.phone || null,
          address: {
            addressLine: values.addressLine,
            zip: values.zip,
            city: values.city,
            province: values.province,
            hamlet: values.hamlet || null
          },
          primaryContact: {
            name: values.primaryContactName,
            lastname: values.primaryContactLastname,
            phone: values.primaryContactPhone
          },
          secondaryContact:
            values.secondaryContactName &&
            values.secondaryContactLastname &&
            values.secondaryContactPhone
              ? {
                  name: values.secondaryContactName,
                  lastname: values.secondaryContactLastname,
                  phone: values.secondaryContactPhone
                }
              : null
        }
      }
      if (updateMode) {
        variables.id = dealer?.id
        await updateDealerMutation({ variables })
      } else {
        variables.data.password = values.password
        await createDealerMutation({ variables })
      }
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '1264px', padding: '50px 80px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Informazioni di base
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* DISPLAYNAME */}
            <CustomInputComponent
              name="displayName"
              error={formik.touched.displayName && !!formik.errors.displayName}
              id="displayName"
              label="Nome breve*"
              value={formik.values.displayName}
              errorLabel={formik.errors.displayName}
              onChange={formik.handleChange}
            />
            {/* RAGIONE SOCIALE */}
            <CustomInputComponent
              name="company"
              error={formik.touched.company && !!formik.errors.company}
              id="company"
              label="Ragione sociale*"
              value={formik.values.company}
              errorLabel={formik.errors.company}
              onChange={formik.handleChange}
            />

            {/* CODICE FISCALE */}
            <CustomInputComponent
              name="cf"
              error={formik.touched.cf && !!formik.errors.cf}
              id="cf"
              label="Codice fiscale*"
              value={formik.values.cf}
              errorLabel={formik.errors.cf}
              onChange={formik.handleChange}
            />
            {/* PARTITA IVA */}
            <CustomInputComponent
              name="pi"
              error={formik.touched.pi && !!formik.errors.pi}
              id="pi"
              label="Partita iva*"
              value={formik.values.pi}
              errorLabel={formik.errors.pi}
              onChange={formik.handleChange}
            />
            {/* PARTITA SDI */}
            <CustomInputComponent
              name="sdi"
              error={formik.touched.sdi && !!formik.errors.sdi}
              id="sdi"
              label="Codice fatturazione elettronica*"
              value={formik.values.sdi}
              errorLabel={formik.errors.sdi}
              onChange={formik.handleChange}
            />
            {/* PARTITA IBAN */}
            <CustomInputComponent
              name="iban"
              error={formik.touched.iban && !!formik.errors.iban}
              id="iban"
              label="IBAN*"
              value={formik.values.iban}
              errorLabel={formik.errors.iban}
              onChange={formik.handleChange}
            />
            {/* EMAIL */}
            <CustomInputComponent
              name="email"
              error={formik.touched.email && !!formik.errors.email}
              id="email"
              label="Email*"
              value={formik.values.email}
              errorLabel={formik.errors.email}
              onChange={formik.handleChange}
            />
            {!updateMode && (
              <CustomInputComponent
                name="password"
                error={formik.touched.password && !!formik.errors.password}
                id="password"
                label="Password*"
                type="password"
                value={formik.values.password}
                errorLabel={formik.errors.password}
                onChange={formik.handleChange}
              />
            )}

            {/* TELEFONO FISSO */}
            <CustomInputComponent
              name="phone"
              error={formik.touched.phone && !!formik.errors.phone}
              id="phone"
              label="Telefono*"
              value={formik.values.phone}
              errorLabel={formik.errors.phone}
              onChange={formik.handleChange}
            />

            {/* INDIRIZZO */}
            <CustomInputComponent
              name="addressLine"
              error={formik.touched.addressLine && !!formik.errors.addressLine}
              id="addressLine"
              label="Indirizzo*"
              value={formik.values.addressLine}
              errorLabel={formik.errors.addressLine}
              onChange={formik.handleChange}
            />
            {/* CITTÀ */}
            <CustomInputComponent
              name="city"
              error={formik.touched.city && !!formik.errors.city}
              id="city"
              label="Città*"
              value={formik.values.city}
              errorLabel={formik.errors.city}
              onChange={formik.handleChange}
            />
            {/* PROVINCIA */}
            <CustomInputComponent
              name="province"
              error={formik.touched.province && !!formik.errors.province}
              id="province"
              label="Provincia*"
              value={formik.values.province}
              errorLabel={formik.errors.province}
              onChange={formik.handleChange}
              type="select"
            >
              {provinces.map(province => (
                <MenuItem key={province.value} value={province.value}>
                  {province.label}
                </MenuItem>
              ))}
            </CustomInputComponent>
            {/* CAP */}
            <CustomInputComponent
              name="zip"
              error={formik.touched.zip && !!formik.errors.zip}
              id="zip"
              label="CAP*"
              value={formik.values.zip}
              errorLabel={formik.errors.zip}
              onChange={formik.handleChange}
            />

            {/* LOCALITÀ */}
            <CustomInputComponent
              name="hamlet"
              error={formik.touched.hamlet && !!formik.errors.hamlet}
              id="hamlet"
              label="Località"
              value={formik.values.hamlet}
              errorLabel={formik.errors.hamlet}
              onChange={formik.handleChange}
            />
          </div>
          <Typography
            variant="h5"
            style={{ marginBottom: '2em' }}
            color="error"
          >
            {error && error.graphQLErrors[0].message}
          </Typography>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Contatto principale
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* NOME */}
            <CustomInputComponent
              name="primaryContactName"
              error={
                formik.touched.primaryContactName &&
                !!formik.errors.primaryContactName
              }
              id="primaryContactName"
              label="Nome*"
              value={formik.values.primaryContactName}
              errorLabel={formik.errors.primaryContactName}
              onChange={formik.handleChange}
            />
            {/* COGNOME */}
            <CustomInputComponent
              name="primaryContactLastname"
              error={
                formik.touched.primaryContactLastname &&
                !!formik.errors.primaryContactLastname
              }
              id="primaryContactLastname"
              label="Cognome*"
              value={formik.values.primaryContactLastname}
              errorLabel={formik.errors.primaryContactLastname}
              onChange={formik.handleChange}
            />
            {/* NOME */}
            <CustomInputComponent
              name="primaryContactPhone"
              error={
                formik.touched.primaryContactPhone &&
                !!formik.errors.primaryContactPhone
              }
              id="primaryContactPhone"
              label="Telefono*"
              value={formik.values.primaryContactPhone}
              errorLabel={formik.errors.primaryContactPhone}
              onChange={formik.handleChange}
            />
          </div>

          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Contatto secondario (opzionale)
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* NOME */}
            <CustomInputComponent
              name="secondaryContactName"
              error={
                formik.touched.secondaryContactName &&
                !!formik.errors.secondaryContactName
              }
              id="secondaryContactName"
              label="Nome*"
              value={formik.values.secondaryContactName}
              errorLabel={formik.errors.secondaryContactName}
              onChange={formik.handleChange}
            />
            {/* COGNOME */}
            <CustomInputComponent
              name="secondaryContactLastname"
              error={
                formik.touched.secondaryContactLastname &&
                !!formik.errors.secondaryContactLastname
              }
              id="secondaryContactLastname"
              label="Cognome*"
              value={formik.values.secondaryContactLastname}
              errorLabel={formik.errors.secondaryContactLastname}
              onChange={formik.handleChange}
            />
            {/* NOME */}
            <CustomInputComponent
              name="secondaryContactPhone"
              error={
                formik.touched.secondaryContactPhone &&
                !!formik.errors.secondaryContactPhone
              }
              id="secondaryContactPhone"
              label="Telefono*"
              value={formik.values.secondaryContactPhone}
              errorLabel={formik.errors.secondaryContactPhone}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          {updateMode ? 'Modifica concessionario' : 'Aggiungi concessionario'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientForm
