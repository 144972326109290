import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { Avatar } from 'components'

const useStyles = makeStyles(theme => ({
  avatar: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const DealerAvatar: React.FC<{ id: string; displayName: string }> = ({
  id,
  displayName
}) => {
  const classes = useStyles()

  return (
    <div className={classes.avatar}>
      <Avatar label={displayName[0]} color={parseInt(id[0], 16)} />
      <Typography style={{ marginLeft: 10 }} variant="h6" display="inline">
        {displayName}
      </Typography>
    </div>
  )
}

export default DealerAvatar
