import React, { useState, useEffect } from 'react'
import { useReactiveVar, useQuery } from '@apollo/client'
import { GET_CONTRACTS } from 'query'
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  makeStyles,
  Typography
} from '@material-ui/core'

import {
  getContracts,
  getContractsVariables,
  getContracts_getContracts_contracts as ContractType,
  ContractStatus,
  ContractOrderByInput
} from 'generated/schemaTypes'

import {
  ContractSummaryCard,
  GlobalOwnershipFilter,
  NoElements,
  OrderSelect,
  SearchFilter
} from 'components'
import {
  currentDealerVar,
  filterContractStatusVar,
  filterOwnerVar,
  orderContractVar,
  roleVar,
  searchContractVar
} from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  row1: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%'
  },
  row2: {
    paddingTop: 100,
    overflow: 'auto',
    height: `calc(100% + ${theme.spacing(4)}px)`
  },
  createClient: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '& .MuiSpeedDial-fab': {
      backgroundColor: theme.palette.primary.main
    }
  },
  statusSelectWrapper: {
    minWidth: 200
  },
  statusSelect: {
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 5
  },
  statusSelectLabel: {
    fontSize: 14
  }
}))

type ContractStatusExtended = 'ALL' | ContractStatus

export default function Contracts() {
  const classes = useStyles()
  const currentDealerCache = useReactiveVar(currentDealerVar)
  const filterOwnerCache = useReactiveVar(filterOwnerVar)
  const filterContractStatusCache = useReactiveVar(filterContractStatusVar)
  const orderContractCache = useReactiveVar(orderContractVar)
  const searchContractCache = useReactiveVar(searchContractVar)
  const roleCache = useReactiveVar(roleVar)
  const [showLoadMore, setShowLoadMore] = useState(false)

  const where: any = {}
  if (currentDealerCache) where.dealerIds = [currentDealerCache]
  if (filterOwnerCache) where.owner = filterOwnerCache

  if (searchContractCache.length > 3) {
    where.searchPattern = searchContractCache.toLowerCase()
  }

  if (filterContractStatusCache !== 'ALL') {
    where.status = filterContractStatusCache
  }

  const filters =
    filterContractStatusCache !== 'ALL' ||
    searchContractCache.length > 3 ||
    filterOwnerCache

  const {
    data: contractsData,
    loading: contractsLoading,
    error: contractsError,
    fetchMore,
    startPolling,
    stopPolling
  } = useQuery<getContracts, getContractsVariables>(GET_CONTRACTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderBy: orderContractCache as ContractOrderByInput
    },
    onCompleted: ({ getContracts }) => {
      setShowLoadMore(getContracts.contracts.length === 10)
    }
  })

  useEffect(() => {
    startPolling(60000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  const contracts = contractsData ? contractsData.getContracts.contracts : []
  const cursor = contractsData ? contractsData.getContracts.cursor : null

  if (contractsError)
    return <div>Errore durante il caricamento delle pratiche!</div>

  if (contracts.length === 0 && !filters && !contractsLoading)
    return (
      <NoElements>
        <Typography color="primary" variant="h1" align="center">
          Non ci sono pratiche
        </Typography>
      </NoElements>
    )

  return (
    <>
      <div className={classes.root}>
        <div className={classes.row1}>
          <SearchFilter
            value={searchContractCache}
            label="Cerca un contratto"
            onChange={
              (val: string) => searchContractVar(val)
              // client.writeData({ data: { searchContract: val } })
            }
          />

          {/* ContractStatus */}
          <FormControl className={classes.statusSelectWrapper}>
            <InputLabel className={classes.statusSelectLabel}>
              Stato della pratica
            </InputLabel>
            <Select
              value={filterContractStatusCache}
              onChange={
                e =>
                  filterContractStatusVar(e.target.value as string | undefined)
                // client.writeData({
                //   data: { filterContractStatus: e.target.value }
                // })
              }
              className={classes.statusSelect}
            >
              <MenuItem value="ALL">Qualsiasi stato</MenuItem>
              <MenuItem value={ContractStatus.CREATED}>Pending</MenuItem>
              <MenuItem value={ContractStatus.WAITING_DOCS}>
                In attesa dei documenti
              </MenuItem>
              <MenuItem value={ContractStatus.WAITING_SCORING}>
                Scoring in corso
              </MenuItem>
              <MenuItem value={ContractStatus.WAITING_DEPOSIT}>
                In attesa del bonifico
              </MenuItem>
              <MenuItem value={ContractStatus.WAITING_DIGITAL_SIGN}>
                In attesa della firma
              </MenuItem>
              <MenuItem value={ContractStatus.CLOSED}>Chiusa</MenuItem>
            </Select>
          </FormControl>

          {roleCache !== 'DEALER' && (
            <>
              <GlobalOwnershipFilter />
            </>
          )}
          <OrderSelect
            name="orderBy"
            type="select"
            label="Ordina per"
            id="orderBy"
            value={orderContractCache}
            onChange={e => {
              orderContractVar(e.target.value as string | undefined)
              // client.writeData({ data: { orderContract: e.target.value } })
            }}
          >
            <MenuItem value="CREATION_ASC">Data ↑</MenuItem>
            <MenuItem value="CREATION_DESC">Data ↓</MenuItem>
          </OrderSelect>
        </div>
        <div className={classes.row2}>
          {contractsLoading ? (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Caricamento delle pratiche in corso...
            </Typography>
          ) : (
            <>
              {contracts.map((contract: ContractType) => {
                return (
                  <React.Fragment key={contract.id}>
                    <ContractSummaryCard contract={contract} />
                  </React.Fragment>
                )
              })}
              {contracts.length === 0 && filters && (
                <Typography
                  variant="h4"
                  align="center"
                  style={{ marginTop: '100px', height: 50 }}
                >
                  Non ci sono pratiche che soddisfano i criteri di ricerca.
                </Typography>
              )}
              {showLoadMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '30px 0',
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      fetchMore({
                        query: GET_CONTRACTS,
                        variables: {
                          cursor,
                          where,
                          orderBy: orderContractCache
                        },
                        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                          const newResults = fetchMoreResult as getContracts
                          const prevResults = prev as getContracts
                          if (
                            !newResults ||
                            (newResults &&
                              newResults.getContracts.contracts.length === 0)
                          ) {
                            setShowLoadMore(false)
                            return prev
                          }
                          if (newResults.getContracts.contracts.length < 10)
                            setShowLoadMore(false)
                          return {
                            ...newResults,
                            getContracts: {
                              ...newResults.getContracts,
                              contracts: [
                                ...prevResults.getContracts.contracts,
                                ...newResults.getContracts.contracts
                              ]
                            }
                          }
                        }
                      })
                    }}
                  >
                    Carica altri contratti
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
