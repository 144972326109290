import React from 'react'

import { useReactiveVar, useQuery } from '@apollo/client'
import { TextField, makeStyles, Paper } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { GET_DEALERS_LIST_SIMPLE } from 'query'
import { currentDealerVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2)
    }
  },
  textSearch: {
    marginBottom: theme.spacing(4)
  },
  inputDealer: {
    minWidth: 280
  },
  autocompleteOption: {},
  autocomplete: {}
}))

const DealersFilter: React.FC = () => {
  const currentDealerCache = useReactiveVar(currentDealerVar)
  const {
    loading: loadingDealers,
    data: dataDealers,
    error: errorDealers
  } = useQuery(GET_DEALERS_LIST_SIMPLE, { fetchPolicy: 'cache-and-network' })

  const setDealer = (id: string) => {
    localStorage.setItem('currentDealer', id)
    currentDealerVar(id)
    // client.writeData({ data: { currentDealer: id } })
  }
  const resetDealer = () => {
    localStorage.setItem('currentDealer', '')
    currentDealerVar('')
    // client.writeData({ data: { currentDealer: '' } })
  }
  const classes = useStyles()

  // if (loadingDealers) return <div>Loading dealers...</div>

  const currentDealer = dataDealers
    ? dataDealers.getDealers.dealers.find(
        (dealer: any) => dealer.id === currentDealerCache
      ) || ''
    : ''

  if (errorDealers) return <div>Errore nel caricamento concessionari!</div>
  return (
    <Paper elevation={0} className={classes.root}>
      <div>
        <Autocomplete
          className={classes.autocomplete}
          options={loadingDealers ? [] : dataDealers.getDealers.dealers}
          onChange={(event: any, value: any, reason: string) => {
            value ? setDealer(value.id) : resetDealer()
          }}
          value={currentDealer || null}
          getOptionLabel={(option: any) => option.displayName}
          getOptionSelected={(option: any, value: any) => {
            return option === currentDealer
          }}
          renderOption={option => (
            <div className={classes.autocompleteOption}>
              {option.displayName}
            </div>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              className={classes.inputDealer}
              label="Filtra concessionario"
              color="secondary"
              variant="outlined"
            />
          )}
          // disabled={formik.isSubmitting}
        />
      </div>
    </Paper>
  )
}

export default DealersFilter
