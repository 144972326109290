import React, { useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useParams, Redirect } from 'react-router-dom'
import { GET_DEALER } from 'query'
import { Typography, makeStyles } from '@material-ui/core'
import { Back, DealerForm, DealerDetail } from 'components'

import {
  getDealer,
  getDealerVariables,
  getDealer_getDealer as DealerData,
  setDealerStatus,
  setDealerStatusVariables,
  UserStatus
} from 'generated/schemaTypes'
import { SET_DEALER_STATUS } from 'mutation'
import { roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  }
}))

export default function Client() {
  const classes = useStyles()
  const { id } = useParams()
  const [showDealerForm, setShowDealerForm] = useState(false)
  const roleCache = useReactiveVar(roleVar)
  const {
    data: dealerData,
    loading: dealerLoading,
    error: dealerError
  } = useQuery<getDealer, getDealerVariables>(GET_DEALER, {
    fetchPolicy: 'cache-first',
    variables: {
      id
    }
  })

  const [setDealerStatusMutation] = useMutation<
    setDealerStatus,
    setDealerStatusVariables
  >(SET_DEALER_STATUS)

  if (dealerError) return <div>Error!</div>

  const dealer = dealerData?.getDealer

  if (roleCache === 'DEALER') return <Redirect to={{ pathname: '/cars' }} />

  return (
    <>
      {dealerLoading ? (
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: '100px', height: 50 }}
        >
          Caricamento del concessionario in corso...
        </Typography>
      ) : (
        <>
          <div className={classes.root}>
            <Back url="/dealers">Torna all'elenco concessionari</Back>
            <DealerDetail
              isOperator={true}
              dealer={dealer as DealerData}
              onUpdateDealer={() => {
                setShowDealerForm(true)
              }}
              onSuspendDealer={async () => {
                await setDealerStatusMutation({
                  variables: {
                    userId: dealer?.id as string,
                    status: UserStatus.SUSPENDED
                  }
                })
                //setShowDealerForm(true)
              }}
              onActivateDealer={async () => {
                await setDealerStatusMutation({
                  variables: {
                    userId: dealer?.id as string,
                    status: UserStatus.ACTIVE
                  }
                })
                //setShowDealerForm(true)
              }}
            />
            <DealerForm
              open={showDealerForm}
              dealer={dealer as DealerData}
              handleClose={() => {
                setShowDealerForm(false)
              }}
            />
          </div>
        </>
      )}
    </>
  )
}
