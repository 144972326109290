const brands = [
  { id: 'ABARTH', label: 'Abart' },
  { id: 'ALFA_ROMEO', label: 'Alfa Romeo' },
  { id: 'ALPINE', label: 'Alpine' },
  { id: 'ASTON_MARTIN', label: 'Aston Martin' },
  { id: 'AUDI', label: 'Audi' },
  { id: 'BENTLEY', label: 'Bentley' },
  { id: 'BMW', label: 'BMW' },
  { id: 'CADILLAC', label: 'Cadillac' },
  { id: 'CITROEN', label: 'Citroën' },
  { id: 'CUPRA', label: 'Cupra' },
  { id: 'DACIA', label: 'Dacia' },
  { id: 'DALLARA', label: 'Dallara' },
  { id: 'DR', label: 'DR' },
  { id: 'DS', label: 'DS' },
  { id: 'EVO', label: 'EVO' },
  { id: 'FERRARI', label: 'Ferrari' },
  { id: 'FIAT', label: 'Fiat' },
  { id: 'FORD', label: 'Ford' },
  { id: 'GIOTTI_VICTORIA', label: 'Giotti Victoria' },
  { id: 'HAVAL', label: 'Haval' },
  { id: 'HONDA', label: 'Honda' },
  { id: 'HYUNDAI', label: 'Hyundai' },
  { id: 'INFINITI', label: 'Infiniti' },
  { id: 'JAGUAR', label: 'Jaguar' },
  { id: 'JEEP', label: 'Jeep' },
  { id: 'KIA', label: 'Kia' },
  { id: 'LAMBORGHINI', label: 'Lamborghini' },
  { id: 'LANCIA', label: 'Lancia' },
  { id: 'LAND_ROVER', label: 'Land Rover' },
  { id: 'LEXUS', label: 'Lexus' },
  { id: 'LOTUS', label: 'Lotus' },
  { id: 'MAHINDRA', label: 'Mahindra' },
  { id: 'MASERATI', label: 'Maserati' },
  { id: 'MAZDA', label: 'Mazda' },
  { id: 'MAZZANTI', label: 'Mazzanti' },
  { id: 'MCLAREN', label: 'McLaren' },
  { id: 'MERCEDES', label: 'Mercedes' },
  { id: 'MILITEM', label: 'Militem' },
  { id: 'MINI', label: 'Mini' },
  { id: 'MITSUBISHI', label: 'Mitsubishi' },
  { id: 'MPM_MOTORS', label: 'MPM Motors' },
  { id: 'NISSAN', label: 'Nissan' },
  { id: 'OPEL', label: 'Opel' },
  { id: 'PEUGEOT', label: 'Peugeot' },
  { id: 'PORSCHE', label: 'Porsche' },
  { id: 'RENAULT', label: 'Renault' },
  { id: 'ROLLS_ROYCE', label: 'Rolls Royce' },
  { id: 'ROMEO_FERRARIS', label: 'Romeo Ferraris' },
  { id: 'SEAT', label: 'Seat' },
  { id: 'SKODA', label: 'Škoda' },
  { id: 'SMART', label: 'Smart' },
  { id: 'SSANGYONG', label: 'Ssangyong' },
  { id: 'SUBARU', label: 'Subaru' },
  { id: 'SUZUKI', label: 'Suzuki' },
  { id: 'TAZZARI_EV', label: 'Tazzari EV' },
  { id: 'TESLA', label: 'Tesla' },
  { id: 'TOYOTA', label: 'Toyota' },
  { id: 'VOLKSWAGEN', label: 'Volkswagen' },
  { id: 'VOLVO', label: 'Volvo' },
  { id: 'XEV', label: 'XEX' }
]

const brandLabel = (id: string): string => {
  const brand = brands.find(el => el.id === id)
  return brand ? brand.label : ''
}

export { brands, brandLabel }
