import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError, useMutation } from '@apollo/client'
import { Button, Link, Paper, makeStyles, Typography } from '@material-ui/core'
import { RESET_PASSWORD } from 'mutation'
import { CustomInputComponent } from 'components'

const useStyles = makeStyles(theme => ({
  paper: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // backgroundColor: 'rgba(255,255,255,0.1)',
    // borderRadius: 15,
    margin: '0 auto',
    width: 560,
    padding: '50px 76px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  successText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },
  text: {
    color: 'black',
    textAlign: 'left',
    width: '100%',
    margin: 0
  },
  invalidEmail: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textAlign: 'left',
    padding: theme.spacing(1)
  }
  // input: {
  //   '& input': {
  //     fontSize: 17,
  //     padding: 19
  //   }
  // }
}))

interface ResetPasswordFormValues {
  password: string
  confirmPassword: string
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message
}

const ResetPasswordForm: React.FC<{ token: string }> = ({ token }) => {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const formik = useFormik<ResetPasswordFormValues>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'La password deve essere lunga almeno 8 caratteri')
        .required('Campo obbligatorio'),
      // .matches(
      //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      // ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Le password non coincidono!')
        .required('Campo obbligatorio')
    }),
    onSubmit: async values => {
      await resetPasswordMutation({
        variables: {
          password: values.password,
          confirmPassword: values.confirmPassword,
          resetToken: token
        }
      })
    }
  })
  const [
    resetPasswordMutation,
    { error: resetPasswordError, loading: isLoading }
  ] = useMutation(RESET_PASSWORD, {
    onCompleted: data => {
      setSuccess(true)
    },
    onError: err => {
      formik.resetForm()
    }
  })

  return (
    <Paper className={classes.paper}>
      <>
        <Typography variant="h1" style={{ marginBottom: 24 }}>
          Reimposta password
        </Typography>
        {!success && (
          <>
            <Typography variant="h3" style={{ marginBottom: 32 }}>
              Inserisci la nuova password (lunghezza minima 8 caratteri)
            </Typography>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.form}
              noValidate
            >
              <CustomInputComponent
                name="password"
                error={formik.touched.password && !!formik.errors.password}
                id="password"
                label="Password"
                type="password"
                value={formik.values.password}
                errorLabel={formik.errors.password}
                onChange={formik.handleChange}
                disabled={isLoading}
              />
              <CustomInputComponent
                name="confirmPassword"
                error={
                  formik.touched.confirmPassword &&
                  !!formik.errors.confirmPassword
                }
                id="confirmPassword"
                label="Conferma password"
                type="password"
                value={formik.values.confirmPassword}
                errorLabel={formik.errors.confirmPassword}
                onChange={formik.handleChange}
                disabled={isLoading}
              />

              {resetPasswordError && (
                <p className={classes.invalidEmail}>
                  {formatError(resetPasswordError)}
                </p>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                disabled={isLoading}
                className={classes.submit}
              >
                Entra
              </Button>
            </form>
          </>
        )}
        {success && (
          <p>
            La password è stata modificata con successo, fai{' '}
            <Link href="/login">login</Link>
          </p>
        )}
      </>
    </Paper>
  )
}

export default ResetPasswordForm
