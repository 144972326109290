import gql from 'graphql-tag'

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

const CREATE_CAR = gql`
  mutation createCar($data: CarCreateInput!) {
    createCar(data: $data) {
      id
    }
  }
`

const DELETE_CAR = gql`
  mutation deleteCar($id: ID!) {
    deleteCar(id: $id) {
      message
    }
  }
`

const UPDATE_CAR = gql`
  mutation updateCar($data: CarUpdateInput!, $id: ID!) {
    updateCar(data: $data, id: $id) {
      id
      brand
      model
      image
      trimLevel
      displacement
      fuelSystem
      manufacturerCode
      power
      plate
      km
      registration
      listPrice
      dealerPrice
      offer
      optionals {
        price
        code
        description
      }
      optionalsAfterMarket {
        price
        description
      }
    }
  }
`

const SET_CAR_OWNER = gql`
  mutation setCarOwner($carId: ID!, $operatorId: ID) {
    setCarOwner(carId: $carId, operatorId: $operatorId)
  }
`

const CREATE_PROPOSAL = gql`
  mutation createProposal($data: ProposalCreateInput!) {
    createProposal(data: $data) {
      id
    }
  }
`

const UPDATE_PROPOSAL = gql`
  mutation updateProposal($data: ProposalUpdateInput!, $id: ID!) {
    updateProposal(data: $data, id: $id) {
      id
      months
      km
      mss
      rent
      infoCar
      winterTires
      summerTires
      allSeasonTires
      deposit
      replacementCar
      windows
      kasko
      ard
      pai
    }
  }
`

const DELETE_PROPOSAL = gql`
  mutation deleteProposal($id: ID!) {
    deleteProposal(id: $id) {
      message
    }
  }
`

const CREATE_DOCUMENT = gql`
  mutation createDocument($data: DocumentCreateInput!) {
    createDocument(data: $data) {
      id
      label
      description
    }
  }
`

const UPDATE_DOCUMENT = gql`
  mutation updateDocument($data: DocumentUpdateInput!, $documentId: ID!) {
    updateDocument(data: $data, documentId: $documentId) {
      id
      label
      description
    }
  }
`

const CREATE_LIST = gql`
  mutation createList($data: ListCreateInput!) {
    createList(data: $data) {
      id
      label
      description
      documents {
        id
        label
        description
      }
    }
  }
`

const UPDATE_LIST = gql`
  mutation updateList($data: ListUpdateInput!, $listId: ID!) {
    updateList(data: $data, listId: $listId) {
      id
      label
      description
      documents {
        id
        label
        description
      }
    }
  }
`

const CREATE_CLIENT_PRIVATE = gql`
  mutation createClientPrivate($data: ClientPrivateCreateInput!) {
    createClientPrivate(data: $data) {
      id
      name
      lastname
      cf
      mobile
      email
      phone
      fax
      iban
      privacy
      address {
        addressLine
        zip
        city
        province
        hamlet
      }
    }
  }
`

const UPDATE_CLIENT_PRIVATE = gql`
  mutation updateClientPrivate(
    $data: ClientPrivateUpdateInput!
    $clientId: ID!
  ) {
    updateClientPrivate(data: $data, clientId: $clientId) {
      id
      name
      lastname
      cf
      mobile
      email
      phone
      fax
      iban
      privacy
      address {
        addressLine
        zip
        city
        province
        hamlet
      }
    }
  }
`

const CREATE_CLIENT_COMPANY = gql`
  mutation createClientCompany($data: ClientCompanyCreateInput!) {
    createClientCompany(data: $data) {
      id
      companyType
      vat
      name
      cf
      mobile
      email
      phone
      fax
      iban
      privacy
      legalRepresentativeName
      legalRepresentativeCF
      contactType
      pec
      address {
        addressLine
        zip
        city
        province
        hamlet
      }
    }
  }
`

const UPDATE_CLIENT_COMPANY = gql`
  mutation updateClientCompany(
    $data: ClientCompanyUpdateInput!
    $clientId: ID!
  ) {
    updateClientCompany(data: $data, clientId: $clientId) {
      id
      companyType
      vat
      name
      cf
      mobile
      email
      phone
      fax
      iban
      privacy
      legalRepresentativeName
      legalRepresentativeCF
      contactType
      pec
      address {
        addressLine
        zip
        city
        province
        hamlet
      }
    }
  }
`

const CREATE_DEALER = gql`
  mutation createDealer($data: DealerCreateInput!) {
    createDealer(data: $data) {
      id
      displayName
      address {
        addressLine
        city
        province
        zip
      }
      email
      company
      pi
      cf
      sdi
      phone
      logo
      primaryContact {
        name
        lastname
        phone
      }
      secondaryContact {
        name
        lastname
        phone
      }
      iban
    }
  }
`

const DELETE_CLIENT = gql`
  mutation deleteClient($clientId: ID!) {
    deleteClient(clientId: $clientId) {
      message
    }
  }
`

const UPDATE_DEALER = gql`
  mutation updateDealer($data: DealerUpdateInput!, $id: ID!) {
    updateDealer(data: $data, id: $id) {
      id
      displayName
      address {
        addressLine
        city
        province
        zip
      }
      email
      company
      pi
      cf
      sdi
      phone
      logo
      primaryContact {
        name
        lastname
        phone
      }
      secondaryContact {
        name
        lastname
        phone
      }
      iban
    }
  }
`

const SET_DEALER_STATUS = gql`
  mutation setDealerStatus($userId: ID!, $status: UserStatus!) {
    setDealerStatus(userId: $userId, status: $status) {
      id
      status
    }
  }
`

const SET_OPERATOR_STATUS = gql`
  mutation setOperatorStatus($userId: ID!, $status: UserStatus!) {
    setOperatorStatus(userId: $userId, status: $status) {
      id
      status
    }
  }
`

const CREATE_OPERATOR = gql`
  mutation createOperator($data: OperatorCreateInput!) {
    createOperator(data: $data) {
      id
      name
      lastname
      email
    }
  }
`

const DELETE_OPERATOR = gql`
  mutation deleteOperator($id: ID!) {
    deleteOperator(id: $id) {
      message
    }
  }
`

const CREATE_ADMIN = gql`
  mutation createAdmin($data: OperatorCreateInput!) {
    createAdmin(data: $data) {
      id
      name
      lastname
      email
    }
  }
`

const UPDATE_OPERATOR = gql`
  mutation updateOperator($data: OperatorUpdateInput!, $id: ID!) {
    updateOperator(data: $data, id: $id) {
      id
      name
      lastname
      email
    }
  }
`

const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      message
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword(
    $resetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
    ) {
      message
    }
  }
`

const CREATE_CONTRACT = gql`
  mutation createContract($data: ContractCreatInput!) {
    createContract(data: $data) {
      id
    }
  }
`

const DELETE_CONTRACT = gql`
  mutation deleteContract($contractId: ID!) {
    deleteContract(contractId: $contractId) {
      message
    }
  }
`

const SET_CONTRACT_OWNER = gql`
  mutation setContractOwner($contractId: ID!, $operatorId: ID) {
    setContractOwner(contractId: $contractId, operatorId: $operatorId)
  }
`

const SET_CONTRACT_STATUS = gql`
  mutation setContractStatus($contractId: ID!, $status: ContractStatus!) {
    setContractStatus(contractId: $contractId, status: $status) {
      id
      status
    }
  }
`

const SET_CONTRACT_OUTCOME = gql`
  mutation setContractOutcome($contractId: ID!, $outcome: ContractOutcome!) {
    setContractOutcome(contractId: $contractId, outcome: $outcome) {
      id
      outcome
    }
  }
`

const SET_CONTRACT_DETAIL = gql`
  mutation setContractDetail(
    $contractId: ID!
    $r2gContractId: String!
    $r2gClientId: String!
    $documentListId: ID!
  ) {
    setContractDetail(
      contractId: $contractId
      r2gContractId: $r2gContractId
      r2gClientId: $r2gClientId
      documentListId: $documentListId
    ) {
      id
      r2gContractId
      r2gClientId
      fileContract
      documentList {
        id
        label
      }
    }
  }
`

const SET_CONTRACT_FILE = gql`
  mutation setContractFile($contractId: ID!, $file: String) {
    setContractFile(contractId: $contractId, file: $file) {
      id
      fileContract
    }
  }
`

const SET_CONTRACT_DOCUMENT_STATUS = gql`
  mutation setContractDocumentStatus(
    $contractId: ID!
    $documentId: ID!
    $status: DocumentStatus!
    $notes: String
  ) {
    setContractDocumentStatus(
      contractId: $contractId
      documentId: $documentId
      status: $status
      notes: $notes
    ) {
      id
      documents {
        documentId
        status
        notes
        url
      }
    }
  }
`

const SET_CONTRACT_DOCUMENT_FILE = gql`
  mutation setContractDocumentFile(
    $contractId: ID!
    $documentId: ID!
    $url: String
  ) {
    setContractDocumentFile(
      contractId: $contractId
      documentId: $documentId
      url: $url
    ) {
      id
      documents {
        documentId
        status
        notes
        url
      }
    }
  }
`

const SET_CONTRACT_DEPOSIT_STATUS = gql`
  mutation setContractDepositStatus(
    $contractId: ID!
    $status: DocumentStatus!
    $notes: String
  ) {
    setContractDepositStatus(
      contractId: $contractId
      status: $status
      notes: $notes
    ) {
      id
      deposit {
        status
        notes
        url
      }
    }
  }
`

const SET_CONTRACT_RENTDEPOSIT_FILE = gql`
  mutation setContractRentDepositFile($contractId: ID!, $url: String) {
    setContractRentDepositFile(contractId: $contractId, url: $url) {
      id
      rentDeposit {
        status
        notes
        url
      }
    }
  }
`
const SET_CONTRACT_RENTDEPOSIT_STATUS = gql`
  mutation setContractRentDepositStatus(
    $contractId: ID!
    $status: DocumentStatus!
    $notes: String
  ) {
    setContractRentDepositStatus(
      contractId: $contractId
      status: $status
      notes: $notes
    ) {
      id
      rentDeposit {
        status
        notes
        url
      }
    }
  }
`

const SET_CONTRACT_DEPOSIT_FILE = gql`
  mutation setContractDepositFile($contractId: ID!, $url: String) {
    setContractDepositFile(contractId: $contractId, url: $url) {
      id
      deposit {
        status
        notes
        url
      }
    }
  }
`

const SET_CONTRACT_PROPOSAL = gql`
  mutation setContractProposal(
    $proposalId: ID!
    $contractId: ID!
    $data: ProposalUpdateInput!
  ) {
    setContractProposal(
      proposalId: $proposalId
      contractId: $contractId
      data: $data
    ) {
      id
      proposal {
        id
        months
        km
        mss
        rent
        infoCar
        winterTires
        summerTires
        allSeasonTires
        deposit
        replacementCar
        windows
        kasko
        ard
        pai
      }
    }
  }
`

const SET_SETTINGS = gql`
  mutation setSettings(
    $emailScoringR2G: String
    $vat: Int
    $emailDigitalSignR2G: String
  ) {
    setSettings(
      emailScoringR2G: $emailScoringR2G
      vat: $vat
      emailDigitalSignR2G: $emailDigitalSignR2G
    ) {
      emailScoringR2G
      emailDigitalSignR2G
      vat
    }
  }
`

const UPDATE_PASSWORD = gql`
  mutation updatePassword($userId: ID!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      message
    }
  }
`

export {
  CREATE_ADMIN,
  CREATE_CAR,
  CREATE_CLIENT_COMPANY,
  CREATE_CLIENT_PRIVATE,
  CREATE_CONTRACT,
  CREATE_DEALER,
  CREATE_DOCUMENT,
  CREATE_OPERATOR,
  CREATE_LIST,
  CREATE_PROPOSAL,
  DELETE_CAR,
  DELETE_PROPOSAL,
  DELETE_CLIENT,
  DELETE_CONTRACT,
  DELETE_OPERATOR,
  LOGIN,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SET_CAR_OWNER,
  SET_CONTRACT_DETAIL,
  SET_CONTRACT_OWNER,
  SET_CONTRACT_OUTCOME,
  SET_CONTRACT_STATUS,
  SET_CONTRACT_FILE,
  SET_CONTRACT_DOCUMENT_STATUS,
  SET_CONTRACT_DOCUMENT_FILE,
  SET_CONTRACT_DEPOSIT_STATUS,
  SET_CONTRACT_DEPOSIT_FILE,
  SET_CONTRACT_RENTDEPOSIT_STATUS,
  SET_CONTRACT_RENTDEPOSIT_FILE,
  SET_CONTRACT_PROPOSAL,
  SET_DEALER_STATUS,
  SET_OPERATOR_STATUS,
  SET_SETTINGS,
  UPDATE_CAR,
  UPDATE_CLIENT_COMPANY,
  UPDATE_CLIENT_PRIVATE,
  UPDATE_DEALER,
  UPDATE_DOCUMENT,
  UPDATE_LIST,
  UPDATE_OPERATOR,
  UPDATE_PROPOSAL,
  UPDATE_PASSWORD
}
