import React, { ReactNode } from 'react'

import {
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  InputBaseComponentProps
} from '@material-ui/core'

import { PriceInputField, PowerInputField } from 'components'

export enum InputType {
  km = 'km',
  price = 'price',
  select = 'select',
  text = 'text',
  password = 'password',
  textarea = 'textarea',
  power = 'power',
  number = 'number'
}

interface CustomInputComponentProps extends InputBaseComponentProps {
  type?: string
  error?: boolean
  errorLabel?: string
  onChange: any
  onBlur?: any
  value: any
  children?: ReactNode
  name: string
  id: string
  label?: string
  placeholder?: string
}

const CustomInputComponent: React.FC<CustomInputComponentProps> = ({
  type = 'text',
  error = false,
  errorLabel = '',
  onChange,
  onBlur,
  value,
  children,
  name,
  id,
  label,
  placeholder
}) => {
  switch (type) {
    case 'select': {
      return (
        <FormControl
          style={{ marginBottom: 16, width: '100%' }}
          variant="outlined"
        >
          <InputLabel error={error} id={`${id}-label`}>
            {label}
          </InputLabel>
          <Select
            name={name}
            value={value}
            labelId={`${id}-label`}
            id={id}
            label={label}
            error={error}
            onChange={onChange}
          >
            {children}
          </Select>
          {error && (
            <FormHelperText error={error} variant="standard">
              {errorLabel}
            </FormHelperText>
          )}
        </FormControl>
      )
    }
    // case 'number': {
    //   return (
    //     <FormControl style={{ marginBottom: 16, width: '100%' }}>
    //       <TextField
    //         name={name}
    //         value={value}
    //         error={error}
    //         id={id}
    //         label={label}
    //         helperText={error && errorLabel}
    //         variant="outlined"
    //         onChange={onChange}
    //         InputProps={{
    //           inputComponent: NumberInputField as any
    //         }}
    //       />
    //     </FormControl>
    //   )
    // }
    case 'power': {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            id={id}
            label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
            InputProps={{
              inputComponent: PowerInputField as any
            }}
          />
        </FormControl>
      )
    }
    case 'price': {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            id={id}
            label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
            InputProps={{
              inputComponent: PriceInputField as any
            }}
          />
        </FormControl>
      )
    }

    case 'date': {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            id={id}
            label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
            InputProps={{
              inputComponent: PriceInputField as any
            }}
          />
        </FormControl>
      )
    }

    case 'textarea': {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            id={id}
            // label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
            multiline
            rows={4}
            placeholder={placeholder}
          />
        </FormControl>
      )
    }

    case 'password': {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            type="password"
            id={id}
            label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
          />
        </FormControl>
      )
    }

    case 'number': {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            type="number"
            id={id}
            label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
          />
        </FormControl>
      )
    }

    default: {
      return (
        <FormControl style={{ marginBottom: 16, width: '100%' }}>
          <TextField
            name={name}
            value={value}
            error={error}
            id={id}
            label={label}
            helperText={error && errorLabel}
            variant="outlined"
            onChange={onChange}
          />
        </FormControl>
      )
    }
  }

  // return <Select {...props} IconComponent={ArrowDownSVG} />
}

export default CustomInputComponent
