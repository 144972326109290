import React from 'react'

import { useQuery, useReactiveVar } from '@apollo/client'
import { TextField, makeStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { GET_OPERATORS_LIST_SIMPLE } from 'query'
import { filterOwnerVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2)
    }
  },
  textSearch: {
    marginBottom: theme.spacing(4)
  },
  inputOperator: {
    minWidth: 200
  },
  autocompleteOption: {},
  autocomplete: {}
}))

const GlobalOwnershipFilter: React.FC = () => {
  const {
    loading: loadingOperators,
    data: dataOperators,
    error: errorOperators
  } = useQuery(GET_OPERATORS_LIST_SIMPLE, { fetchPolicy: 'cache-and-network' })

  const filterOwner = useReactiveVar(filterOwnerVar)

  const setOwner = (id: string) => {
    localStorage.setItem('filterOwner', id)
    filterOwnerVar(id)
    // client.writeData({ data: { filterOwner: id } })
  }

  const classes = useStyles()
  if (loadingOperators) return <div>Loading operators...</div>

  const currentOperator =
    dataOperators.getOperators.find(
      (operator: any) => operator.id === filterOwner
    ) || ''

  if (errorOperators)
    return <div>Errore: impossibile caricare gli operatori!</div>

  return (
    <Autocomplete
      className={classes.autocomplete}
      options={dataOperators.getOperators}
      onChange={(event: any, value: any, reason: string) => {
        setOwner(value ? value.id : '')
      }}
      value={currentOperator || null}
      // popupIcon={<ArrowDownSVG />}
      getOptionLabel={(option: any) => `${option.name} ${option.lastname}`}
      getOptionSelected={(option: any, value: any) => {
        return option === currentOperator
      }}
      renderOption={option => (
        <div className={classes.autocompleteOption}>
          {option.name} {option.lastname}
        </div>
      )}
      renderInput={(params: any) => (
        <TextField
          {...params}
          className={classes.inputOperator}
          // placeholder="Filtra Operatore"
          label="Operatore selezionato"
          color="primary"
          // variant="outlined"
          size="small"
        />
      )}
    />
  )
}

export default GlobalOwnershipFilter
