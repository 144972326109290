import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CREATE_DOCUMENT, UPDATE_DOCUMENT } from 'mutation'
import { GET_DOCUMENTS } from 'query'
import { CustomInputComponent } from 'components'

import {
  getDocument_getDocument as DocumentType,
  createDocument as createDocumentMutation,
  createDocumentVariables,
  updateDocument as updateDocumentMutation,
  updateDocumentVariables
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  }
}))

export interface DocumentFormValues {
  label: string
  description: string
}

const defaultInitialValues = {
  label: '',
  description: ''
}

const validationSchema = Yup.object({
  label: Yup.string().required('Campo obbligatorio'),
  description: Yup.string().required('Campo obbligatorio')
})

interface DocumentFormProps {
  open: boolean
  handleClose: () => void
  document: DocumentType | null
}

const DocumentForm: React.FC<DocumentFormProps> = ({
  open,
  document,
  handleClose
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const updateMode = !!document

  const classes = useStyles()

  const initialValues = document
    ? { label: document.label, description: document.description }
    : null

  const [createDocument] = useMutation<
    createDocumentMutation,
    createDocumentVariables
  >(CREATE_DOCUMENT, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Documento creato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore!', err)
    },
    refetchQueries: [{ query: GET_DOCUMENTS }]
  })

  const [updateDocument] = useMutation<
    updateDocumentMutation,
    updateDocumentVariables
  >(UPDATE_DOCUMENT, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Documento modificato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore', err)
    }
  })

  const formik = useFormik<DocumentFormValues>({
    initialValues: initialValues || defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const variables: any = {
        data: {
          ...values
        }
      }
      if (updateMode) {
        variables.documentId = (document as DocumentType).id
        await updateDocument({ variables })
      } else {
        await createDocument({ variables })
      }
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => {
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '560px', padding: '45px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h3" style={{ marginBottom: '1em' }}>
          {updateMode ? 'Modifica il documento' : 'Crea un nuovo documento'}
        </Typography>

        <div style={{ width: '100%' }}>
          {/* Label */}
          <CustomInputComponent
            name="label"
            error={formik.touched.label && !!formik.errors.label}
            id="label"
            label="Nome del documento"
            value={formik.values.label}
            errorLabel={formik.errors.label}
            onChange={formik.handleChange}
          />
        </div>
        <div>
          {/* Description */}
          <CustomInputComponent
            name="description"
            type="textarea"
            error={formik.touched.description && !!formik.errors.description}
            id="description"
            label="Descrizione"
            value={formik.values.description}
            errorLabel={formik.errors.description}
            onChange={formik.handleChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          {updateMode ? 'Modifica documento' : 'Aggiungi documento'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DocumentForm
