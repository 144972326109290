import React from 'react'

import {
  FormControl,
  InputLabel,
  SelectProps,
  Select,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 100
  },
  root: {
    fontSize: 14,
    paddingTop: 4,
    paddingBottom: 5
  },
  label: {
    fontSize: 14
  }
}))

const OrderSelect: React.FC<SelectProps> = props => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.label}>{props.label}</InputLabel>
      <Select className={classes.root} {...props}>
        {props.children}
      </Select>
    </FormControl>
  )
}

export default OrderSelect
