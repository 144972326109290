import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps
} from 'react-router-dom'

import { useReactiveVar } from '@apollo/client'

import {
  Car,
  Cars,
  Client,
  Clients,
  Contract,
  Contracts,
  Documents,
  Dealer,
  Dealers,
  Login,
  Library,
  Lists,
  Operator,
  Operators,
  Profile,
  RegisterAdmin,
  RequestToken,
  ResetPassword,
  Settings,
  Spot
} from 'routes'
// import { FullscreenLoader } from 'components'
import { PageLayout } from 'components'
import { isLoggedInVar } from 'cache'

interface ProtectedRouteProps extends RouteProps {
  withLayout: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  if (isLoggedIn) {
    if (props.withLayout) {
      return (
        <PageLayout>
          <Route {...props} />
        </PageLayout>
      )
    } else {
      return <Route {...props} />
    }
  } else {
    const renderComponent = () => <Redirect to={{ pathname: '/login' }} />
    return <Route {...props} component={renderComponent} render={undefined} />
  }
}

const RouteManager: React.FC = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  return (
    <Router>
      {isLoggedIn ? (
        <>
          <Switch>
            <ProtectedRoute
              withLayout={false}
              path="/spot/:id"
              component={Spot}
            />
            <ProtectedRoute
              withLayout={true}
              path="/cars/:id"
              component={Car}
            />
            <ProtectedRoute withLayout={true} path="/cars" component={Cars} />
            <ProtectedRoute
              withLayout={true}
              path="/library"
              component={Library}
            />
            <ProtectedRoute withLayout={true} path="/lists" component={Lists} />
            <ProtectedRoute
              withLayout={true}
              path="/documents"
              component={Documents}
            />
            <ProtectedRoute
              withLayout={true}
              path="/clients/:id"
              component={Client}
            />
            <ProtectedRoute
              withLayout={true}
              path="/clients"
              component={Clients}
            />
            <ProtectedRoute
              withLayout={true}
              path="/dealers/:id"
              component={Dealer}
            />
            <ProtectedRoute
              withLayout={true}
              path="/dealers"
              component={Dealers}
            />
            <ProtectedRoute
              withLayout={true}
              path="/operators/:id"
              component={Operator}
            />
            <ProtectedRoute
              withLayout={true}
              path="/operators"
              component={Operators}
            />
            <ProtectedRoute
              withLayout={true}
              path="/contracts/:id"
              component={Contract}
            />
            <ProtectedRoute
              withLayout={true}
              path="/contracts"
              component={Contracts}
            />
            <ProtectedRoute
              withLayout={true}
              path="/profile"
              component={Profile}
            />
            <ProtectedRoute
              withLayout={true}
              path="/settings"
              component={Settings}
            />
            <Redirect from="/" to="/cars" />
          </Switch>
        </>
      ) : (
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/requestToken" component={RequestToken} />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/init" component={RegisterAdmin} />
          <Redirect from="/" to="/login" />
        </Switch>
      )}
    </Router>
  )
}

export default RouteManager
