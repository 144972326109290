import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_SPOT_MODULE } from 'query'

import { getSpotModule, getSpotModuleVariables } from 'generated/schemaTypes'

export default function Spot() {
  const { id } = useParams()

  const { data: spotData, loading: spotLoading, error: spotError } = useQuery<
    getSpotModule,
    getSpotModuleVariables
  >(GET_SPOT_MODULE, {
    variables: {
      contractId: id
    }
  })

  if (spotError) return <div>Error!</div>
  if (spotLoading) return <div>Loading</div>
  if (spotData) {
    document.body.innerHTML = spotData.getSpotModule || ''
    window.print()
  }

  return null
}
