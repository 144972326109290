import React, { useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useParams, Redirect } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { GET_OPERATOR } from 'query'
import { DELETE_OPERATOR, SET_OPERATOR_STATUS } from 'mutation'

import { Typography, makeStyles } from '@material-ui/core'
import {
  Back,
  OperatorForm,
  OperatorDetail,
  ConfirmDialog,
  MessageDialog
} from 'components'

import {
  getOperator,
  getOperatorVariables,
  getOperator_getOperator as OperatorData,
  deleteOperator,
  deleteOperatorVariables,
  setOperatorStatus,
  setOperatorStatusVariables,
  UserStatus
} from 'generated/schemaTypes'
import { roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  }
}))

export default function Operator() {
  const classes = useStyles()
  const { id } = useParams()
  const [showOperatorForm, setShowOperatorForm] = useState(false)
  const [showDeleteMessage, setShowDeleteMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const roleCache = useReactiveVar(roleVar)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [setOperatorStatusMutation] = useMutation<
    setOperatorStatus,
    setOperatorStatusVariables
  >(SET_OPERATOR_STATUS)

  const {
    data: operatorData,
    loading: operatorLoading,
    error: operatorError
  } = useQuery<getOperator, getOperatorVariables>(GET_OPERATOR, {
    fetchPolicy: 'cache-first',
    variables: {
      id
    }
  })

  const [deleteOperatorMutation, { loading: isDeletingOperator }] = useMutation<
    deleteOperator,
    deleteOperatorVariables
  >(DELETE_OPERATOR, {
    variables: { id },
    onCompleted: async data => {
      enqueueSnackbar('Operatore eliminato con successo', {
        variant: 'success'
      })
      history.push('/operators')
    },
    onError: () => {
      setShowDeleteMessage(false)
      setShowErrorMessage(true)
    }
  })

  if (roleCache !== 'ADMIN') return <Redirect to={{ pathname: '/cars' }} />

  if (operatorError) return <div>Error!</div>

  const operator = operatorData?.getOperator

  return (
    <>
      {operatorLoading ? (
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: '100px', height: 50 }}
        >
          Caricamento dell'operatore in corso...
        </Typography>
      ) : (
        <>
          <div className={classes.root}>
            <Back url="/operators">Torna all'elenco operatori</Back>
            <OperatorDetail
              operator={operator as OperatorData}
              onUpdateOperator={() => {
                setShowOperatorForm(true)
              }}
              onDeleteOperator={() => {
                setShowDeleteMessage(true)
              }}
              onSuspendOperator={async () => {
                await setOperatorStatusMutation({
                  variables: {
                    userId: operator?.id as string,
                    status: UserStatus.SUSPENDED
                  }
                })
                //setShowDealerForm(true)
              }}
              onActivateOperator={async () => {
                await setOperatorStatusMutation({
                  variables: {
                    userId: operator?.id as string,
                    status: UserStatus.ACTIVE
                  }
                })
                //setShowDealerForm(true)
              }}
            />
            <OperatorForm
              open={showOperatorForm}
              operator={operator as OperatorData}
              handleClose={() => {
                setShowOperatorForm(false)
              }}
            />
          </div>

          <ConfirmDialog
            onConfirm={async () => {
              await deleteOperatorMutation()
            }}
            onClose={() => {
              setShowDeleteMessage(false)
            }}
            title="Attenzione"
            open={showDeleteMessage}
            loading={isDeletingOperator}
          >
            Sei sicuro di voler cancellare questo cliente? L'operazione è
            irreversibile.
          </ConfirmDialog>
          <MessageDialog
            open={showErrorMessage}
            title="Attenzione"
            onClose={() => setShowErrorMessage(false)}
          >
            Impossibile cancellare l'operatore! Verifica che non ci siano auto o
            pratiche associate!
          </MessageDialog>
        </>
      )}
    </>
  )
}
