import React from 'react'

import { Button, Paper, makeStyles, Typography } from '@material-ui/core'

import { getDealer_getDealer as DealerData } from 'generated/schemaTypes'
import { ButtonModify, FileLogo } from 'components'

interface DealerProfileProps {
  dealer: DealerData
  onUpdateDealer: () => void
  onChangePassword: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4),
    position: 'relative'
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const DealerProfile: React.FC<DealerProfileProps> = ({
  dealer,
  onUpdateDealer,
  onChangePassword
}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h1" style={{ marginBottom: '1em' }}>
        I miei dati
      </Typography>

      <div className={classes.ctaWrapper}>
        <ButtonModify onClick={onUpdateDealer} />
      </div>

      <div className={classes.data}>
        <Typography variant="h4">Ragione sociale</Typography>
        <Typography variant="h5">{dealer.company}</Typography>
        <Typography variant="h4">Nome visualizzato</Typography>
        <Typography variant="h5">{dealer.displayName}</Typography>
        <Typography variant="h4">Indirizzo</Typography>
        <Typography variant="h5">
          {dealer.address.addressLine}, {dealer.address.zip}{' '}
          {dealer.address.city} ({dealer.address.province})
        </Typography>
        <Typography variant="h4">Email</Typography>
        <Typography variant="h5">{dealer.email}</Typography>

        <Typography variant="h4">Telefono fisso</Typography>
        <Typography variant="h5">{dealer.phone || '-'}</Typography>

        <Typography variant="h4">Codice fiscale</Typography>
        <Typography variant="h5">{dealer.cf}</Typography>

        <Typography variant="h4">Partita Iva</Typography>
        <Typography variant="h5">{dealer.pi}</Typography>

        <Typography variant="h4">
          Codice univoco fatturazione elettronica
        </Typography>
        <Typography variant="h5">{dealer.sdi}</Typography>

        <Typography variant="h4">IBAN</Typography>
        <Typography variant="h5">{dealer.iban}</Typography>

        <Typography variant="h4">Contatto principale</Typography>
        <Typography variant="h5">
          {dealer.primaryContact.name} {dealer.primaryContact.lastname}{' '}
          {dealer.primaryContact.phone}
        </Typography>

        <Typography variant="h4">Contatto secondario</Typography>
        <Typography variant="h5">
          {dealer.secondaryContact
            ? `${dealer.secondaryContact.name} ${dealer.secondaryContact.lastname} ${dealer.secondaryContact.phone}`
            : '-'}
        </Typography>

        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 300 }}
            onClick={onChangePassword}
          >
            Modifica password
          </Button>
          <div style={{ width: 300, marginTop: 30 }}>
            <FileLogo dealerId={dealer.id} url={dealer.logo} />
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default DealerProfile
