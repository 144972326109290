import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    role: String!
    currentDealer: String!
    orderCar: String
    userId: ID
    filterOwner: ID
    filterProvince: [String!]!
    filterContractStatus: String
    filterCarWithProposals: Boolean
    filterCarWithoutProposals: Boolean
    filterCarIncludeSold: Boolean
    filterCarIncludeExpired: Boolean
    orderContract: String
    orderDealer: String
    orderOperator: String
    orderClient: String
    searchCar: String
    searchContract: String
    searchDealer: String
    searchOperator: String
    searchClient: String
  }
`
