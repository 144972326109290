import React from 'react'

import {
  Button,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
  Tooltip
} from '@material-ui/core'

import {
  CheckboxMarkedCircleOutline,
  AlertCircleOutline
} from 'mdi-material-ui'

import {
  DocumentStatus,
  getContract_getContract_documents as DocumentType,
  getContract_getContract_deposit as DepositType
} from 'generated/schemaTypes'
import { DownloadIcon, UploadIcon } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button:last-child': {
      marginLeft: theme.spacing(1)
    }
  },
  roundButton: {
    padding: 0,
    marginRight: theme.spacing(1)
  },
  head: {
    display: 'flex'
  }
}))

interface DocumentItemProps {
  title: string
  description: string
  document: DocumentType | DepositType
  isOperator: boolean
  isLoading: boolean
  isUploading: boolean
  onApprove: () => void
  onRefuse: () => void
  onUpload: () => void
  onDelete: () => void
}

const DocumentItem: React.FC<DocumentItemProps> = ({
  title,
  description,
  document,
  isOperator,
  isLoading,
  isUploading,
  onApprove,
  onRefuse,
  onUpload,
  onDelete
}) => {
  const classes = useStyles()

  if (isLoading)
    return (
      <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
        <div>Operazione in corso...</div>
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      </div>
    )

  if (isUploading)
    return (
      <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
        <div>Caricamento del file in corso...</div>
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      </div>
    )

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        {document.status === DocumentStatus.PENDING ||
        document.status === DocumentStatus.REFUSED ? (
          <IconButton onClick={onUpload} className={classes.roundButton}>
            <UploadIcon stroke="#AAAAAA" />
          </IconButton>
        ) : (
          <IconButton
            href={document.url as string}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.roundButton}
          >
            <DownloadIcon stroke="#23A7E0" />
          </IconButton>
        )}
        {document.status === DocumentStatus.PENDING && (
          <Typography display="inline" variant="h5" color="initial">
            {title}
          </Typography>
        )}
        {document.status === DocumentStatus.REFUSED && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography display="inline" variant="h4" color="secondary">
              {title}
            </Typography>
            <Tooltip title={document.notes as string}>
              <AlertCircleOutline fontSize="small" color="secondary" />
            </Tooltip>
          </div>
        )}
        {[DocumentStatus.UPLOADED, DocumentStatus.APPROVED].includes(
          document.status
        ) && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography display="inline" variant="h4" color="primary">
              {title}
            </Typography>
            {document.status === DocumentStatus.APPROVED && (
              <CheckboxMarkedCircleOutline color="primary" fontSize="small" />
            )}
          </div>
        )}
      </div>
      <Typography variant="subtitle1">{description}</Typography>
      <div>
        {isOperator && document.status === DocumentStatus.UPLOADED && (
          <div className={classes.buttons}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={onRefuse}
            >
              Rifiuta
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={onApprove}
            >
              Approva
            </Button>
          </div>
        )}
        {!isOperator && document.status === DocumentStatus.UPLOADED && (
          <div className={classes.buttons}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={onDelete}
            >
              Elimina
            </Button>
          </div>
        )}
      </div>
      <div>
        {isOperator && document.status === DocumentStatus.APPROVED && (
          <div className={classes.buttons}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={onRefuse}
            >
              Rifiuta
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DocumentItem
