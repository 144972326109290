import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import numeral from 'numeral'

interface CarOptionalsListProps {
  optionals: IOptional[]
  optionalsAfterMarket: IOptionalAfterMarket[]
}

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // '& h4': {
    //   width: '50%'
    // },
    // '& h5': {
    //   width: '50%'
    // }
  },
  table3col: {
    width: '100%',
    '& td': {
      width: '33.3%',
      height: 30,
      padding: 0
    },
    marginBottom: 30
  },
  table2col: {
    width: '100%',
    '& td': {
      height: 30,
      width: '50%',
      padding: 0
    }
  }
}))

const CarOptionalsList: React.FC<CarOptionalsListProps> = ({
  optionals,
  optionalsAfterMarket
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h4" style={{ marginBottom: 10 }}>
        Accessori
      </Typography>
      {optionals.length === 0 && (
        <Typography variant="h6">Nessun accessorio</Typography>
      )}
      <table className={classes.table3col}>
        <tbody>
          {optionals.map((optional: IOptional) => {
            return (
              <tr key={optional.code}>
                <td>
                  <Typography variant="h6">{optional.code}</Typography>
                </td>
                <td>
                  <Typography variant="h6">{optional.description}</Typography>
                </td>
                <td>
                  <Typography variant="h6" align="right">
                    {numeral(optional.price / 100).format('0,0.00')} €
                  </Typography>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Typography variant="h4" style={{ marginBottom: 10 }}>
        Accessori after market
      </Typography>
      {optionalsAfterMarket.length === 0 && (
        <Typography variant="h6">Nessun accessorio after market</Typography>
      )}
      <table className={classes.table2col}>
        <tbody>
          {optionalsAfterMarket.map(
            (optional: IOptionalAfterMarket, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Typography variant="h6">{optional.description}</Typography>
                  </td>
                  <td>
                    <Typography variant="h6" align="right">
                      {numeral(optional.price / 100).format('0,0.00')} €
                    </Typography>
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </div>
  )
}

export default CarOptionalsList
