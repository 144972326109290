import React, { useState } from 'react'

import { InputAdornment, TextField, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputLabel-outlined': {
      paddingLeft: 25
    },
    '& .MuiInputLabel-shrink': {
      paddingLeft: 0
    }
  }
}))

const SearchFilter: React.FC<{
  label: string
  value: any
  onChange: (val: any) => void
}> = ({ label, onChange, value }) => {
  const [shrink, setShrink] = useState(false)
  const classes = useStyles()

  return (
    <TextField
      // defaultValue={value}
      // value={value}
      value={value}
      className={classes.root}
      label={label}
      InputLabelProps={{
        shrink: shrink || value !== ''
      }}
      type="search"
      color="secondary"
      variant="outlined"
      onChange={e => {
        // setSearchQuery(e.target.value)
        onChange(e.target.value)
      }}
      // value={searchQuery}
      onFocus={() => setShrink(true)}
      onBlur={() => setShrink(false)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchFilter
