import React from 'react'

import { useReactiveVar } from '@apollo/client'
import { Button, Paper, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import numeral from 'numeral'
import { AvatarWithLabel, CarHeader, ContractOwnership } from 'components'
import { color } from 'theme'
import {
  ContractStatus,
  getContracts_getContracts_contracts as ContractType,
  getContracts_getContracts_contracts_client_ClientPrivate as ClientPrivateType,
  getContracts_getContracts_contracts_client_ClientCompany as ClientCompanyType
} from 'generated/schemaTypes'

import { brandLabel } from 'data/brands'
import { roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between'
  },
  col: {
    alignContent: 'space-between',
    flexWrap: 'wrap',
    display: 'flex',
    width: '33.33%'
  },
  col1: {},
  col2: {},
  col3: {
    maxWidth: 250,
    '& > div': {
      width: '100%'
    }
  },
  carHeaderWrapper: {
    marginBottom: theme.spacing(2)
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    marginLeft: -theme.spacing(4),
    marginBottom: -theme.spacing(4)
  },
  thumb: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '50%',
    backgroundPosition: 'center'
  },
  goToAutoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(80,80,80,0.8)',
    opacity: 0,
    transition: 'opacity 0.25s ease',
    '&:hover': {
      opacity: 1
    }
  },
  goToAuto: {
    position: 'absolute',
    padding: '10px 15px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    minWidth: 220,
    borderRadius: theme.spacing(1),
    border: 'solid 2px white'
  },
  ProposalRecap: {
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '50%'
    },
    '& > div:last-child': {
      borderLeft: `solid 1px ${color.MediumLightGrey}`,
      textAlign: 'right'
    }
  },
  addButton: {
    marginLeft: '20px'
  },

  data: {
    marginBottom: '1em'
  },
  head: {
    marginBottom: '1em'
  }
}))

const companyTypes = {
  PUBBLICA_AMMINISTRAZIONE: 'Pubblica amministrazione',
  PERSONA_GIURIDICA: 'Persona Giuridica',
  DITTA_INDIVIDUALE: 'Ditta individuale'
}

const ContractSummaryCard: React.FC<{
  contract: ContractType
}> = ({ contract }) => {
  const classes = useStyles()
  const roleCache = useReactiveVar(roleVar)
  const isOperator = ['OPERATOR', 'ADMIN'].includes(roleCache)
  const isPrivate = contract.client.__typename === 'ClientPrivate'

  let status: string
  switch (contract.status) {
    case ContractStatus.CREATED:
      status = 'PENDING'
      break
    case ContractStatus.WAITING_DEPOSIT:
      status = 'IN ATTESA DEL BONIFICO'
      break
    case ContractStatus.WAITING_DIGITAL_SIGN:
      status = 'IN ATTESA DELLA FIRMA'
      break
    case ContractStatus.WAITING_DOCS:
      status = 'IN ATTESA DEI DOCUMENTI'
      break
    case ContractStatus.WAITING_SCORING:
      status = 'SCORING IN CORSO'
      break
    case ContractStatus.CLOSED:
      status = 'CHIUSA'
      break
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={clsx(classes.col1, classes.col)}>
        <div className={classes.carHeaderWrapper}>
          <CarHeader
            model={contract.car.model}
            brand={brandLabel(contract.car.brand)}
            trimLevel={contract.car.trimLevel}
            registration={contract.car.registration}
            insertDate={contract.car.insertDate}
            km={contract.car.km}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Typography variant="h1">
            {numeral(contract.proposal.rent / 100).format('0,0')} €/m
          </Typography>
          <Typography variant="h5">{contract.proposal.months} mesi</Typography>
          <Typography variant="h5">
            {numeral(contract.proposal.km).format()} km
          </Typography>
        </div>
        <div style={{ width: '100%' }}>
          <Button
            href={`/contracts/${contract.id}`}
            variant="contained"
            size="medium"
            color="primary"
          >
            Dettaglio pratica
          </Button>
        </div>
      </div>
      <div className={clsx(classes.col2, classes.col)}>
        <div style={{ width: '100%' }}>
          <div className={classes.head}>
            <Typography variant="h1">
              {contract.client.name}{' '}
              {isPrivate && (contract.client as ClientPrivateType).lastname}
            </Typography>
            <Typography variant="h2">
              (
              {isPrivate
                ? `Persona fisica`
                : companyTypes[
                    (contract.client as ClientCompanyType).companyType
                  ]}
              )
            </Typography>
          </div>

          <div>
            <div className={classes.data}>
              <Typography variant="h4">Indirizzo</Typography>
              <Typography variant="h5">
                {contract.client.address.addressLine},{' '}
                {contract.client.address.zip} {contract.client.address.hamlet}{' '}
                {contract.client.address.city} (
                {contract.client.address.province})
              </Typography>
            </div>
            {!isPrivate && (
              <div className={classes.data}>
                <Typography variant="h5">Partita iva</Typography>
                <Typography variant="h5">
                  {(contract.client as ClientCompanyType).vat}
                </Typography>
              </div>
            )}
            <div className={classes.data}>
              <Typography variant="h4">Codice fiscale</Typography>
              <Typography variant="h5">{contract.client.cf}</Typography>
            </div>
            <div className={classes.data}>
              <Typography variant="h4">Email</Typography>
              <Typography variant="h5">{contract.client.email}</Typography>
            </div>
            <div className={classes.data}>
              <Typography variant="h4">Cellulare</Typography>
              <Typography variant="h5">{contract.client.mobile}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(classes.col3, classes.col)}>
        <div>
          {isOperator && (
            <>
              <ContractOwnership
                contractId={contract.id}
                operatorId={contract.operator?.id || null}
              />
              <div style={{ margin: '32px 0' }}>
                <AvatarWithLabel
                  id={contract.dealer.id}
                  smallLabel={contract.dealer.displayName[0]}
                  fullLabel={contract.dealer.displayName}
                />
              </div>
            </>
          )}
          <Typography variant="h4">Stato della pratica: {status}</Typography>
        </div>

        <div>
          <Typography variant="h6">
            ID pratica Rent2Go: {contract.r2gContractId || 'TBD'}
          </Typography>
          <Typography variant="h6">
            ID cliente Rent2Go: {contract.r2gClientId || 'TBD'}
          </Typography>
        </div>
      </div>
    </Paper>
  )
}

export default ContractSummaryCard
