import React, { useRef, useState } from 'react'

import { LinearProgress, Typography } from '@material-ui/core'
import { useMutation, useApolloClient } from '@apollo/client'
import { getContract } from 'generated/schemaTypes'
import { GET_FILE_URL_FILECONTRACT, GET_CONTRACT } from 'query'
import { SET_CONTRACT_FILE } from 'mutation'
import { MessageDialog } from 'components'

interface FileContractProps {
  url: string | null
  contractId: string
  dealerId: string
}

const FileContract: React.FC<FileContractProps> = ({
  url,
  dealerId,
  contractId
}) => {
  const [showError, setShowError] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(
    'La dimensione del file non può superare i 4MB'
  )
  const client = useApolloClient()
  const inputEl = useRef<HTMLInputElement>(null)
  const [setContractFile] = useMutation(SET_CONTRACT_FILE, {
    update(
      cache,
      {
        data: {
          setContractFile: { fileContract, id }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CONTRACT,
        variables: { id }
      }) as getContract
      cache.writeQuery({
        query: GET_CONTRACT,
        variables: { id },
        data: { getContract: { ...data.getContract, fileContract } }
      })
    }
  })

  const onSelectFile = () => {
    if (inputEl && inputEl.current) inputEl.current.click()
  }

  if (isUploading)
    return (
      <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
        <div>Caricamento del file in corso...</div>
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      </div>
    )

  return (
    <>
      <input
        accept="image/jpeg,image/gif,image/png,application/pdf"
        ref={inputEl}
        type="file"
        id="input-upload"
        style={{ display: 'none' }}
        onChange={async e => {
          const files = e.target.files
          setIsUploading(true)
          if (files && files.length > 0 && files[0].size < 4 * 1024 * 1024) {
            try {
              const urlRequest = await client.query({
                query: GET_FILE_URL_FILECONTRACT,
                variables: {
                  dealerId,
                  contractId,
                  filename: `${files[0].name}`
                }
              })
              const signedUrl = urlRequest.data.getSignedUrlForContractFile
              const fileUrl = signedUrl.split('?')[0]
              const response = await fetch(signedUrl, {
                method: 'PUT',
                body: files[0]
              })
              setIsUploading(false)
              if (response.ok) {
                setContractFile({
                  variables: { contractId, file: fileUrl }
                })
              }
            } catch (err) {
              setError(err)
              console.log('Error', err)
            }
          } else {
            setError('La dimensione del file non può superare i 2MB')
            setIsUploading(false)
            setShowError(true)
          }
        }}
      />
      {!url ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" display="inline">
            File contratto non caricato!&nbsp;
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            style={{ cursor: 'pointer' }}
            display="inline"
            onClick={onSelectFile}
          >
            CARICA
          </Typography>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" display="inline">
            File contratto:&nbsp;
          </Typography>
          <Typography variant="h4" color="primary" display="inline">
            <a
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              SCARICA
            </a>
          </Typography>
          <Typography variant="h4" color="primary" display="inline">
            &nbsp;|&nbsp;
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            style={{ cursor: 'pointer' }}
            display="inline"
            onClick={onSelectFile}
          >
            MODIFICA
          </Typography>
          <Typography variant="h4" color="primary" display="inline">
            &nbsp;|&nbsp;
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            style={{ cursor: 'pointer' }}
            display="inline"
            onClick={() =>
              setContractFile({
                variables: { contractId }
              })
            }
          >
            ELIMINA
          </Typography>
        </div>
      )}
      <MessageDialog
        open={showError}
        title="Attenzione"
        onClose={() => setShowError(false)}
      >
        {error}
      </MessageDialog>
    </>
  )
}

export default FileContract
