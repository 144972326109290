import React from 'react'

import { Typography, Paper, makeStyles } from '@material-ui/core'
import numeral from 'numeral'
import { getContract_getContract_car as CarData } from 'generated/schemaTypes'
import { format } from 'date-fns'
// import numeral from 'numeral'

interface ContractDetailCarProps {
  car: CarData
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexWrap: 'wrap'
    // alignContent: 'space-between'
  },
  head: {
    marginBottom: '1em',
    borderBottom: `solid 1px ${theme.palette.text.primary}`,
    minHeight: '70px',
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap'
  },
  row: {
    marginBottom: '1em'
  }
}))

const ContractDetailCar: React.FC<ContractDetailCarProps> = ({ car }) => {
  const classes = useStyles()

  const imageUrl =
    car.image || 'https://via.placeholder.com/360x180?text=placeholder'

  return (
    <Paper elevation={0} className={classes.root}>
      <div style={{ width: '100%' }}>
        <div className={classes.head}>
          <Typography variant="h1" style={{ width: '100%' }}>
            {car.brand} {car.model}
          </Typography>
          <Typography variant="h4" style={{ marginBottom: '0.6em' }}>
            {car.trimLevel}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h5" display="inline">
            Chilometri:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {car.km}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography
            variant="h5"
            display="inline"
            style={{ marginBottom: '0.3em' }}
          >
            Immatricolata il:{' '}
          </Typography>
          <Typography
            variant="h4"
            display="inline"
            style={{ marginBottom: '0.3em' }}
          >
            {format(new Date(car.registration), 'dd/MM/yyyy')}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h5" display="inline">
            Alimentazione:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {car.fuelSystem}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h5" display="inline">
            Potenza:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {car.power} cv ({Math.round(car.power / 1.36)} kw)
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h5" display="inline">
            Cilindrata:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {car.displacement}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="h5" display="inline">
            Prezzo finale:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {numeral(car.totalPrice / 100).format('0,0.00')} € (i.e.)
          </Typography>
        </div>
      </div>
      <div>
        <img style={{ maxWidth: '100%' }} src={imageUrl} alt="" />
      </div>
    </Paper>
  )
}

export default ContractDetailCar
