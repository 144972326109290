import React, { useState } from 'react'
import { useQuery, useApolloClient, useReactiveVar } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'
import { GET_CLIENT } from 'query'
import { DELETE_CLIENT } from 'mutation'
import { Typography, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
  Back,
  ClientDetail,
  ClientPrivateForm,
  ClientCompanyForm,
  ConfirmDialog
} from 'components'

import {
  getClient,
  getClientVariables,
  getClient_getClient as ClientData,
  getClient_getClient_ClientPrivate as ClientPrivateType,
  getClient_getClient_ClientCompany as ClientCompanyType
} from 'generated/schemaTypes'
import { currentDealerVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  }
}))

export default function Client() {
  const classes = useStyles()
  const { id } = useParams()
  const [showClientForm, setShowClientForm] = useState(false)
  const [showDeleteClient, setShowDeleteClient] = useState(false)
  const history = useHistory()
  const currentDealerCache = useReactiveVar(currentDealerVar)
  const apolloClient = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const {
    data: clientData,
    loading: clientLoading,
    error: clientError
  } = useQuery<getClient, getClientVariables>(GET_CLIENT, {
    fetchPolicy: 'cache-first',
    variables: {
      id
    }
  })

  if (clientError) return <div>Error!</div>

  const client = clientData?.getClient

  return (
    <>
      {clientLoading ? (
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: '100px', height: 50 }}
        >
          Caricamento del cliente in corso...
        </Typography>
      ) : (
        <>
          <div className={classes.root}>
            <Back url="/clients">Torna all'elenco clienti</Back>
            <ClientDetail
              client={client as ClientData}
              onUpdateClient={() => {
                setShowClientForm(true)
              }}
              {...(client?.contractsCount === 0 && {
                onDeleteClient: () => setShowDeleteClient(true)
              })}
            />
            <ClientPrivateForm
              open={showClientForm && client?.__typename === 'ClientPrivate'}
              client={client as ClientPrivateType}
              dealerId={currentDealerCache}
              handleClose={() => {
                setShowClientForm(false)
              }}
            />
            <ClientCompanyForm
              open={showClientForm && client?.__typename === 'ClientCompany'}
              client={client as ClientCompanyType}
              dealerId={currentDealerCache}
              handleClose={() => {
                setShowClientForm(false)
              }}
            />
            <ConfirmDialog
              onConfirm={async () => {
                await apolloClient.mutate({
                  mutation: DELETE_CLIENT,
                  variables: { clientId: id }
                })
                setShowDeleteClient(false)
                enqueueSnackbar('Cliente eliminato', {
                  variant: 'success'
                })
                history.push('/clients')
              }}
              onClose={() => {
                setShowDeleteClient(false)
              }}
              title="Attenzione"
              open={showDeleteClient}
            >
              Sei sicuro di voler cancellare questa cliente? L'operazione è
              irreversibile.
            </ConfirmDialog>
          </div>
        </>
      )}
    </>
  )
}
