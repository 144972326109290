import React from 'react'

import { IconButton, makeStyles, Typography } from '@material-ui/core'

import {
  getContract_getContract_documents as DocumentType,
  getContract_getContract_deposit as DepositType
} from 'generated/schemaTypes'
import { DownloadIcon } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button:last-child': {
      marginLeft: theme.spacing(1)
    }
  },
  roundButton: {
    padding: 0,
    marginRight: theme.spacing(1)
  },
  head: {
    display: 'flex'
  }
}))

interface DocumentLinkProps {
  document: DocumentType | DepositType
  title: string
}

const DocumentLink: React.FC<DocumentLinkProps> = ({ document, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <IconButton
          href={document.url as string}
          rel="noopener noreferrer"
          target="_blank"
          className={classes.roundButton}
        >
          <DownloadIcon stroke="#23A7E0" />
        </IconButton>
        <Typography display="inline" variant="h4" color="primary">
          {title}
        </Typography>
      </div>
    </div>
  )
}

export default DocumentLink
