import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@material-ui/core'

interface CheckboxControlProps {
  label: string
  checked: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  name: string
  disabled?: boolean
  position?: 'left' | 'right'
  error?: boolean
  errorLabel?: string
}

const CheckboxControl: React.FC<CheckboxControlProps> = ({
  label,
  checked,
  onChange,
  name,
  disabled = false,
  position = 'right',
  error = false,
  errorLabel = ''
}) => {
  return (
    <FormControl style={{ marginBottom: 16, width: '100%' }} variant="outlined">
      <FormControlLabel
        label={label}
        labelPlacement={position === 'left' ? 'end' : 'start'}
        control={
          <Checkbox
            color="primary"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            name={name}
          />
        }
      />
      {error && (
        <FormHelperText error={error} variant="standard">
          {errorLabel}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default CheckboxControl
