import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { Paper, makeStyles, Typography } from '@material-ui/core'
import {
  MenuCarIcon,
  MenuCarActiveIcon,
  MenuContractIcon,
  MenuContractActiveIcon,
  MenuDealerIcon,
  MenuDealerActiveIcon,
  MenuUserIcon,
  MenuUserActiveIcon
} from 'components'
import { roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 2)
    }
  },
  itemLink: {
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'center',
    marginBottom: 69,
    display: 'block'
  },
  iconWrapper: {
    width: 48,
    height: 48,
    // background: '#98BBC6',
    margin: '0 auto',
    borderRadius: 8,
    '& svg': {
      width: '100%',
      height: '100%'
    }
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  listItem: {
    color: theme.palette.primary.main
  },
  listItemSelected: {
    color: 'white'
  }
}))

interface MenuItemProps {
  icon?: React.ReactElement
  iconActive?: React.ReactElement
  label: string
  to: string
}

function MenuItem(props: MenuItemProps) {
  const { label, to, icon, iconActive } = props
  const classes = useStyles()

  let match = useRouteMatch({
    path: to
  })
  return (
    <RouterLink className={classes.itemLink} to={to}>
      <div className={classes.iconWrapper}>{match ? iconActive : icon}</div>
      <Typography variant="h6" color="primary" style={{ marginTop: 10 }}>
        {label}
      </Typography>
    </RouterLink>
  )
}

const VerticalNav: React.FC = () => {
  const classes = useStyles()
  const roleCache = useReactiveVar(roleVar)
  return (
    <Paper elevation={0} className={classes.root}>
      <MenuItem
        to="/cars"
        label="Auto"
        icon={<MenuCarIcon />}
        iconActive={<MenuCarActiveIcon />}
      />
      <MenuItem
        to="/contracts"
        label="Pratiche"
        icon={<MenuContractIcon />}
        iconActive={<MenuContractActiveIcon />}
      />
      {['ADMIN', 'OPERATOR'].includes(roleCache) && (
        <>
          <MenuItem
            to="/dealers"
            label="Concessionari"
            icon={<MenuDealerIcon />}
            iconActive={<MenuDealerActiveIcon />}
          />
        </>
      )}

      <MenuItem
        to="/clients"
        label="Clienti"
        icon={<MenuUserIcon />}
        iconActive={<MenuUserActiveIcon />}
      />
    </Paper>
  )
}

export default VerticalNav
