import React, { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Redirect, useHistory } from 'react-router-dom'
import { GET_OPERATORS } from 'query'
import {
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  getOperators,
  getOperators_getOperators,
  getOperatorsVariables,
  OperatorOrderByInput
} from 'generated/schemaTypes'

import {
  AddFab,
  Avatar,
  NoElements,
  OperatorForm,
  OrderSelect,
  SearchFilter
} from 'components'
import { orderOperatorVar, roleVar, searchOperatorVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  row1: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%'
  },
  row2: {
    paddingTop: 100,
    overflow: 'auto',
    height: `calc(100% + ${theme.spacing(4)}px)`
  }
}))

export default function Operators() {
  const classes = useStyles()
  const history = useHistory()
  const orderOperatorCache = useReactiveVar(orderOperatorVar)
  const roleCache = useReactiveVar(roleVar)
  const searchOperatorCache = useReactiveVar(searchOperatorVar)
  const [showOperatorForm, setShowOperatorForm] = useState(false)

  const where: any = {
    includeSuspended: true
  }

  if (searchOperatorCache.length > 3) {
    where.searchPattern = searchOperatorCache.toLowerCase()
  }
  const filters = searchOperatorCache.length > 3

  const {
    data: operatorsData,
    loading: operatorsLoading,
    error: operatorsError
  } = useQuery<getOperators, getOperatorsVariables>(GET_OPERATORS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderBy: orderOperatorCache as OperatorOrderByInput
    }
  })

  if (roleCache !== 'ADMIN') return <Redirect to={{ pathname: '/cars' }} />

  const operators = operatorsData ? operatorsData.getOperators : []

  if (operatorsError) return <div>Error!</div>

  if (operators.length === 0 && !filters && !operatorsLoading)
    return (
      <>
        <NoElements>
          <Typography color="primary" variant="h1" align="center">
            Non ci sono operatori qui,
            <br />
            inserisci il primo operatore
          </Typography>
        </NoElements>
        <AddFab
          onClick={() => {
            setShowOperatorForm(true)
          }}
        />
        <OperatorForm
          open={showOperatorForm}
          operator={null}
          handleClose={() => {
            setShowOperatorForm(false)
          }}
        />
      </>
    )

  return (
    <>
      <div className={classes.root}>
        <div className={classes.row1}>
          <SearchFilter
            value={searchOperatorVar}
            label="Cerca un operatore"
            onChange={
              (val: string) => searchOperatorVar(val)
              // client.writeData({ data: { searchOperator: val } })
            }
          />

          <OrderSelect
            name="orderBy"
            type="select"
            label="Ordina per"
            id="orderBy"
            value={orderOperatorCache}
            onChange={e => {
              orderOperatorVar(e.target.value as string | undefined)
              // client.writeData({ data: { orderOperator: e.target.value } })
            }}
          >
            <MenuItem value="LASTNAME_ASC">Cognome ↑</MenuItem>
            <MenuItem value="LASTNAME_DESC">Cognome ↓</MenuItem>
          </OrderSelect>
        </div>
        <div className={classes.row2}>
          {operatorsLoading ? (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Caricamento degli operatori in corso...
            </Typography>
          ) : operators.length > 0 ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Nome</TableCell>
                      <TableCell align="left">Cognome</TableCell>
                      <TableCell align="left">Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {operators.map((operator: getOperators_getOperators) => {
                      return (
                        <TableRow
                          key={operator.id}
                          hover
                          style={{ cursor: 'pointer' }}
                          onClick={event => {
                            history.push(`/operators/${operator.id}`)
                          }}
                        >
                          <TableCell align="left">
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="left">
                            <Avatar
                              label={`${operator.name[0]}${operator.lastname[0]}`}
                              color={parseInt(operator.id[0], 16)}
                            />
                          </TableCell>
                          <TableCell align="left">{operator.name}</TableCell>
                          <TableCell align="left">
                            {operator.lastname}
                          </TableCell>
                          <TableCell align="left">{operator.email}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Non ci sono operatori che soddisfano i criteri di ricerca.
            </Typography>
          )}
        </div>
      </div>
      <AddFab
        onClick={() => {
          setShowOperatorForm(true)
        }}
      />
      <OperatorForm
        open={showOperatorForm}
        operator={null}
        handleClose={() => {
          setShowOperatorForm(false)
        }}
      />
    </>
  )
}
