import React from 'react'
import { useMutation } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  MenuItem,
  Typography,
  Button
} from '@material-ui/core'

import { SET_CONTRACT_STATUS, SET_CONTRACT_OUTCOME } from 'mutation'
import {
  ContractOutcome,
  ContractStatus,
  setContractOutcome,
  setContractOutcomeVariables,
  setContractStatus,
  setContractStatusVariables
} from 'generated/schemaTypes'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CustomInputComponent } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  }
}))

export interface OutcomeFormValues {
  outcome: string
}

const defaultInitialValues = {
  outcome: ''
}

const validationSchema = Yup.object({
  outcome: Yup.string().required('Campo obbligatorio')
})

interface OutcomeFormProps {
  open: boolean
  hasProposalDeposit: boolean
  contractId: string
  onClose: () => void
}

const OutcomeForm: React.FC<OutcomeFormProps> = ({
  open,
  contractId,
  hasProposalDeposit,
  onClose
}) => {
  const classes = useStyles()

  const [
    setContractStatusMutation,
    { loading: isChangingContractStatus }
  ] = useMutation<setContractStatus, setContractStatusVariables>(
    SET_CONTRACT_STATUS
  )

  const [
    setContractOutcomeMutation,
    { loading: isChangingContractOutcome }
  ] = useMutation<setContractOutcome, setContractOutcomeVariables>(
    SET_CONTRACT_OUTCOME
  )

  const isLoading = isChangingContractOutcome || isChangingContractStatus

  const formik = useFormik<OutcomeFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      await setContractOutcomeMutation({
        variables: {
          contractId,
          outcome: values.outcome as ContractOutcome
        }
      })
      let status: ContractStatus
      if (values.outcome === ContractOutcome.REFUSED) {
        status = ContractStatus.CLOSED
      } else if (values.outcome === ContractOutcome.APPROVED_RENT0) {
        if (hasProposalDeposit) {
          status = ContractStatus.WAITING_DEPOSIT
        } else {
          status = ContractStatus.WAITING_DIGITAL_SIGN
        }
      } else {
        status = ContractStatus.WAITING_DEPOSIT
      }
      await setContractStatusMutation({
        variables: {
          contractId,
          status
        }
      })
      onClose()
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '560px', padding: '45px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h1" style={{ marginBottom: '1em' }}>
          Esito scoring
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '1em' }}>
          Imposta l'esito della procedura di scoring selezionando una dei valori
        </Typography>

        <div>
          {/* Outcome */}
          <CustomInputComponent
            name="outcome"
            type="select"
            error={formik.touched.outcome && !!formik.errors.outcome}
            id="outcome"
            label="Esito scoring"
            value={formik.values.outcome}
            errorLabel={formik.errors.outcome}
            onChange={formik.handleChange}
          >
            <MenuItem value="APPROVED_RENT12">
              Approvato con 12 canoni di anticipo
            </MenuItem>
            <MenuItem value="APPROVED_RENT9">
              Approvato con 9 canoni di anticipo
            </MenuItem>
            <MenuItem value="APPROVED_RENT6">
              Approvato con 6 canoni di anticipo
            </MenuItem>
            <MenuItem value="APPROVED_RENT3">
              Approvato con 3 canoni di anticipo
            </MenuItem>
            <MenuItem value="APPROVED_RENT0">
              Approvato senza canoni di anticipo
            </MenuItem>
            <MenuItem value="REFUSED">Rifiutato</MenuItem>
          </CustomInputComponent>
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={onClose}>
          Indietro
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          color="secondary"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OutcomeForm
