import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton
} from '@material-ui/core'

import { Add as AddIcon } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { format } from 'date-fns'
import numeral from 'numeral'
import { CREATE_CAR, UPDATE_CAR } from 'mutation'
import { GET_FILE_URL_FILEOFFER, GET_CAR } from 'query'
import { FileOfferUploadButton, CustomInputComponent } from 'components'
import { color } from 'theme'
import { ReactComponent as TrashSVG } from 'assets/trash.svg'
import { ReactComponent as CalendarSVG } from 'assets/calendar.svg'
import { priceStringToNumber } from 'utils'
import { brands } from 'data/brands'
import { getCar } from 'generated/schemaTypes'

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  baseInfoWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    gridTemplateRows: 'auto auto auto',
    position: 'relative',
    marginBottom: 30
  },
  optionalsWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '160px 432px 280px',
    gridTemplateRows: 'auto',
    position: 'relative'
  },
  optionalsListWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '160px 432px 280px',
    gridTemplateRows: 'auto',
    position: 'relative',
    marginBottom: theme.spacing(3),
    '& div:nth-child(1)': {
      paddingLeft: theme.spacing(2)
    },
    '& div:nth-child(2)': {
      paddingLeft: theme.spacing(2)
    },
    '& div:nth-child(3)': {
      textAlign: 'right',
      paddingRight: 40
    }
  },
  optionalsAfterMarketWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '616px 280px',
    gridTemplateRows: 'auto',
    position: 'relative'
  },
  optionalsAfterMarketListWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '616px 280px',
    gridTemplateRows: 'auto',
    position: 'relative',
    marginBottom: theme.spacing(3),
    '& div:nth-child(1)': {
      paddingLeft: theme.spacing(2)
    },
    '& div:nth-child(2)': {
      textAlign: 'right',
      paddingRight: 40
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export interface Optional {
  code: string
  description: string
  price: string
}

export interface OptionalAfterMarket {
  description: string
  price: string
}

export interface CarFormValues {
  brand: string
  fuelSystem: string
  km: string
  model: string
  manufacturerCode: string
  plate: string
  trimLevel: string
  displacement: string
  listPrice: string
  registration: Date | undefined | null
  power: string
  dealerPrice: string
  optionals: Array<Optional>
  offer: string | null
  optionalsAfterMarket: Array<OptionalAfterMarket>
}

const initialValues = {
  brand: '',
  fuelSystem: '',
  km: '',
  model: '',
  manufacturerCode: '',
  plate: '',
  trimLevel: '',
  displacement: '',
  listPrice: '',
  power: '',
  dealerPrice: '',
  optionals: [],
  offer: '',
  optionalsAfterMarket: [],
  registration: null
}

const validationSchema = Yup.object({
  brand: Yup.mixed().required('Campo obbligatorio'),
  fuelSystem: Yup.mixed().required('Campo obbligatorio'),
  km: Yup.number().required('Campo obbligatorio'),
  model: Yup.mixed().required('Campo obbligatorio'),
  manufacturerCode: Yup.mixed().required('Campo obbligatorio'),
  plate: Yup.mixed().required('Campo obbligatorio'),
  trimLevel: Yup.mixed().required('Campo obbligatorio'),
  displacement: Yup.mixed().required('Campo obbligatorio'),
  listPrice: Yup.number().required('Campo obbligatorio'),
  dealerPrice: Yup.number().required('Campo obbligatorio'),
  power: Yup.number().required('Campo obbligatorio'),
  registration: Yup.mixed().required('Campo obbligatorio')
})

interface CarFormProps {
  open: boolean
  handleClose: () => void
  carId?: string
  dealerId?: string
  car?: CarFormValues
  useLocalStorage: boolean
}

const CarForm: React.FC<CarFormProps> = ({
  open,
  dealerId = null,
  car,
  handleClose,
  carId,
  useLocalStorage
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const client = useApolloClient()

  const [file, setFile] = useState<File | null>(null)
  const [optional, setOptional] = useState<Optional>({
    code: '',
    description: '',
    price: ''
  })
  const [optionalAfterMarket, setOptionalAfterMarket] = useState<
    OptionalAfterMarket
  >({
    description: '',
    price: ''
  })

  const [updateFileOffer] = useMutation(UPDATE_CAR)

  const [createCar] = useMutation(CREATE_CAR, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Auto creata con successo', {
        variant: 'success'
      })
      const carId = data.createCar.id
      if (file) {
        try {
          const urlRequest = await client.query({
            query: GET_FILE_URL_FILEOFFER,
            variables: { dealerId, carId, filename: `${file.name}` }
          })
          const signedUrl = urlRequest.data.getSignedUrlForOfferFile
          const fileUrl = signedUrl.split('?')[0]
          const response = await fetch(signedUrl, {
            method: 'PUT',
            body: file
          })
          if (response.ok) {
            await updateFileOffer({
              variables: { id: carId, data: { offer: fileUrl } }
            })
          }
        } catch (err) {
          console.log('Error', err)
        }
      }
      formik.resetForm()
      handleClose()
      history.push(`/cars/${carId}`)
    },
    onError: err => {
      enqueueSnackbar('Si è verificato un errore', {
        variant: 'error'
      })
      formik.resetForm()
      handleClose()
      console.log('Si è verificato un errore', err)
    }
  })

  const [updateCar] = useMutation(UPDATE_CAR, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Auto modificata con successo', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      enqueueSnackbar('Si è verificato un errore', {
        variant: 'error'
      })
      formik.resetForm()
      handleClose()
      console.log('Si è verificato un errore', err)
    },
    refetchQueries: [{ query: GET_CAR, variables: { id: carId } }]
  })

  const updateMode = !!car

  const carDraft = localStorage.getItem('carDraft')
  const draft: CarFormValues | {} =
    carDraft && useLocalStorage ? JSON.parse(carDraft) : {}

  const classes = useStyles()

  const formik = useFormik<CarFormValues>({
    initialValues: car || Object.assign({}, initialValues, draft),
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const variables: any = {
        data: {
          ...values,
          registration: format(values.registration as Date, 'yyyy-MM-dd'),
          km: priceStringToNumber(values.km),
          displacement: priceStringToNumber(values.displacement),
          listPrice: priceStringToNumber(values.listPrice),
          power: priceStringToNumber(values.power),
          dealerPrice: priceStringToNumber(values.dealerPrice),
          optionals: values.optionals.map(({ price, code, description }) => {
            return { code, description, price: priceStringToNumber(price) }
          }),
          optionalsAfterMarket: values.optionalsAfterMarket.map(
            ({ price, description }) => {
              return { description, price: priceStringToNumber(price) }
            }
          ),
          // offer: values.offer ? values.offer : null,
          offer: file ? file.name : null,
          dealerId
        }
      }
      if (updateMode) {
        delete variables.data.dealerId
        delete variables.data.offer
        variables.id = carId
        await updateCar({ variables })
      } else {
        await createCar({ variables })
      }
    }
  })

  // if (useLocalStorage) formik.resetForm()

  // const discountPercentage = () => {
  //   const listPrice = priceStringToNumber(formik.values.listPrice)
  //   const dealerPrice = priceStringToNumber(formik.values.dealerPrice)
  //   if (listPrice <= 0 || listPrice <= 0) return '-'
  //   const optionalsPrice = formik.values.optionals.reduce((tot, { price }) => {
  //     return tot + priceStringToNumber(price)
  //   }, 0)
  //   const discount = `${(
  //     ((listPrice + optionalsPrice - dealerPrice) /
  //       (listPrice + optionalsPrice)) *
  //     100
  //   ).toFixed(2)}%`
  //   return discount
  // const priceWithoutAccessories =
  //   priceStringToNumber(formik.values.dealerPrice) - optionalsPrice
  // if (priceWithoutAccessories < 0) return '-'
  //return `${((1 - priceWithoutAccessories / listPrice) * 100).toFixed(2)}%`
  // }

  const totalPrice = () => {
    const dealerPrice = formik.values.dealerPrice
      ? priceStringToNumber(formik.values.dealerPrice)
      : 0
    const optionalsAfterMarketPrice = formik.values.optionalsAfterMarket.reduce(
      (tot, { price }) => {
        return tot + priceStringToNumber(price)
      },
      0
    )
    if (dealerPrice <= 0) return '-'
    return numeral((dealerPrice + optionalsAfterMarketPrice) / 100).format(
      '0,0.00'
    )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        if (!updateMode) {
          if (_.isEqual(formik.values, initialValues)) {
            localStorage.removeItem('carDraft')
          } else {
            localStorage.setItem('carDraft', JSON.stringify(formik.values))
          }
        }
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '1200px', padding: '48px 140px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Informazioni di base
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* BRAND */}
            <CustomInputComponent
              name="brand"
              error={formik.touched.brand && !!formik.errors.brand}
              id="brand"
              label="Marca"
              value={formik.values.brand}
              errorLabel={formik.errors.brand}
              onChange={formik.handleChange}
              type="select"
            >
              {brands.map(brand => (
                <MenuItem key={brand.id} value={brand.id}>
                  {brand.label}
                </MenuItem>
              ))}
            </CustomInputComponent>
            {/* FUEL SYSTEM */}
            <CustomInputComponent
              name="fuelSystem"
              type="select"
              error={formik.touched.fuelSystem && !!formik.errors.fuelSystem}
              id="fuelSystem"
              label="Alimentazione"
              value={formik.values.fuelSystem}
              errorLabel={formik.errors.fuelSystem}
              onChange={formik.handleChange}
            >
              <MenuItem value="BENZINA">Benzina</MenuItem>
              <MenuItem value="DIESEL">Diesel</MenuItem>
              <MenuItem value="ELETTRICA">Elettrica</MenuItem>
              <MenuItem value="GPL">Gpl</MenuItem>
              <MenuItem value="IBRIDO">Ibrido</MenuItem>
              <MenuItem value="METANO">Metano</MenuItem>
              <MenuItem value="BIFUEL">Bifuel - Benzina/GPL</MenuItem>
            </CustomInputComponent>
            {/* KM */}
            <CustomInputComponent
              type="number"
              name="km"
              error={formik.touched.km && !!formik.errors.km}
              id="km"
              label="Chilometri"
              value={formik.values.km}
              errorLabel={formik.errors.km}
              onChange={formik.handleChange}
            />
            {/* MODELLO */}
            <CustomInputComponent
              name="model"
              error={formik.touched.model && !!formik.errors.model}
              id="model"
              label="Modello"
              value={formik.values.model}
              errorLabel={formik.errors.model}
              onChange={formik.handleChange}
            />
            {/* CODICE COSTRUTTORE */}
            <CustomInputComponent
              name="manufacturerCode"
              error={
                formik.touched.manufacturerCode &&
                !!formik.errors.manufacturerCode
              }
              id="manufacturerCode"
              label="Codice costruttore"
              value={formik.values.manufacturerCode}
              errorLabel={formik.errors.manufacturerCode}
              onChange={formik.handleChange}
            />
            {/* PLATE */}
            <CustomInputComponent
              name="plate"
              error={formik.touched.plate && !!formik.errors.plate}
              id="plate"
              label="Targa / Telaio"
              value={formik.values.plate}
              errorLabel={formik.errors.plate}
              onChange={formik.handleChange}
            />
            {/* ALLESTIMENTO */}
            <CustomInputComponent
              name="trimLevel"
              error={formik.touched.trimLevel && !!formik.errors.trimLevel}
              id="trimLevel"
              label="Allestimento"
              value={formik.values.trimLevel}
              errorLabel={formik.errors.trimLevel}
              onChange={formik.handleChange}
            />
            {/* CILINDRATA */}
            <CustomInputComponent
              type="number"
              name="displacement"
              error={
                formik.touched.displacement && !!formik.errors.displacement
              }
              id="displacement"
              label="Cilindrata"
              value={formik.values.displacement}
              errorLabel={formik.errors.displacement}
              onChange={formik.handleChange}
            />
            {/* PREZZO DI LISTINO */}
            <CustomInputComponent
              name="listPrice"
              error={formik.touched.listPrice && !!formik.errors.listPrice}
              id="listPrice"
              label="Prezzo listino (i.e.)"
              value={formik.values.listPrice}
              errorLabel={formik.errors.listPrice}
              onChange={formik.handleChange}
              type="price"
            />
            {/* DATA DI IMMATRICOLAZIONE */}
            <FormControl style={{ marginBottom: 16 }}>
              <KeyboardDatePicker
                keyboardIcon={<CalendarSVG />}
                autoOk
                // clearable
                variant="inline"
                error={
                  formik.touched.registration && !!formik.errors.registration
                }
                inputVariant="outlined"
                label={formik.values.registration ? 'Immatricolazione' : null}
                format="dd/MM/yyyy"
                placeholder="Data di immatricolazione"
                name="registration"
                value={formik.values.registration}
                InputAdornmentProps={{ position: 'start' }}
                onChange={date =>
                  formik.setFieldValue('registration', date as Date)
                }
              />
              {formik.touched.registration && !!formik.errors.registration && (
                <FormHelperText error={true} variant="standard">
                  {formik.errors.registration}
                </FormHelperText>
              )}
            </FormControl>
            {/* CV */}
            <CustomInputComponent
              type="power"
              name="power"
              error={formik.touched.power && !!formik.errors.power}
              id="power"
              label="Potenza CV"
              value={formik.values.power}
              errorLabel={formik.errors.power}
              onChange={formik.handleChange}
            />
            {/* PREZZO DEL CONCESSIONARIO */}
            <CustomInputComponent
              name="dealerPrice"
              error={formik.touched.dealerPrice && !!formik.errors.dealerPrice}
              id="dealerPrice"
              label="Prezzo scontato (i.e.)"
              value={formik.values.dealerPrice}
              errorLabel={formik.errors.dealerPrice}
              onChange={formik.handleChange}
              type="price"
            />
          </div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Accessori
          </Typography>
          <div className={classes.optionalsWrapper}>
            {/* CODICE OPTIONAL */}
            <CustomInputComponent
              name="optionalCode"
              id="optionalCode"
              label="Codice"
              value={optional.code}
              onChange={(e: any) =>
                setOptional({ ...optional, code: e.target.value })
              }
            />
            {/* DESCRIZIONE OPTIONAL */}
            <CustomInputComponent
              name="optionalDescription"
              id="optionalDescription"
              label="Descrizione accessorio"
              value={optional.description}
              onChange={(e: any) =>
                setOptional({ ...optional, description: e.target.value })
              }
            />
            {/* PREZZO OPTIONAL */}
            <CustomInputComponent
              name="optionalPrice"
              id="optionalPrice"
              label="Prezzo i.e."
              type="price"
              value={optional.price}
              onChange={(e: any) =>
                setOptional({ ...optional, price: e.target.value })
              }
            />
            <IconButton
              disabled={
                !(optional.code && optional.description && optional.price)
              }
              className={classes.addButton}
              onClick={() => {
                formik.setFieldValue('optionals', [
                  ...formik.values.optionals,
                  {
                    code: optional.code,
                    price: optional.price,
                    description: optional.description
                  }
                ])
                setOptional({
                  code: '',
                  description: '',
                  price: ''
                })
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
          {formik.values.optionals.map((opt: any, index: number) => (
            <div key={index} className={classes.optionalsListWrapper}>
              <div>{opt.code}</div>
              <div>{opt.description}</div>
              <div>{opt.price}</div>
              <IconButton
                className={classes.deleteButton}
                onClick={ev => {
                  const arr = [...formik.values.optionals]
                  arr.splice(index, 1)
                  formik.setFieldValue('optionals', arr)
                }}
              >
                <TrashSVG />
              </IconButton>
            </div>
          ))}

          <div className={classes.optionalsAfterMarketWrapper}>
            {/* DESCRIZIONE OPTIONAL */}
            <CustomInputComponent
              name="optionalAfterMarketDescription"
              id="optionalAfterMarketDescription"
              label="Descrizione accessorio after market"
              value={optionalAfterMarket.description}
              onChange={(e: any) =>
                setOptionalAfterMarket({
                  ...optionalAfterMarket,
                  description: e.target.value
                })
              }
            />

            {/* PREZZO OPTIONAL */}
            <CustomInputComponent
              name="optionalAfterMarketPrice"
              id="optionalAfterMarketPrice"
              label="Prezzo i.e."
              type="price"
              value={optionalAfterMarket.price}
              onChange={(e: any) =>
                setOptionalAfterMarket({
                  ...optionalAfterMarket,
                  price: e.target.value
                })
              }
            />
            <IconButton
              disabled={
                !(optionalAfterMarket.description && optionalAfterMarket.price)
              }
              className={classes.addButton}
              onClick={() => {
                formik.setFieldValue('optionalsAfterMarket', [
                  ...formik.values.optionalsAfterMarket,
                  {
                    price: optionalAfterMarket.price,
                    description: optionalAfterMarket.description
                  }
                ])
                setOptionalAfterMarket({
                  price: '',
                  description: ''
                })
              }}
            >
              <AddIcon />
            </IconButton>
          </div>

          {formik.values.optionalsAfterMarket.map((opt: any, index: number) => (
            <div
              key={index}
              className={classes.optionalsAfterMarketListWrapper}
            >
              <div>{opt.description}</div>
              <div>{opt.price}</div>
              <IconButton
                className={classes.deleteButton}
                onClick={ev => {
                  const arr = [...formik.values.optionalsAfterMarket]
                  arr.splice(index, 1)
                  formik.setFieldValue('optionalsAfterMarket', arr)
                }}
              >
                <TrashSVG />
              </IconButton>
            </div>
          ))}
        </div>
        {/* <div className={classes.calculatedPrice}>
          <TextField
            inputProps={{
              readOnly: true
            }}
            label="Prezzo totale iva esclusa"
            value={numeral(totalPrice() / 100).format('0,0[.] €')}
          />
        </div> */}
        <div className={classes.calculatedPrice}>
          <TextField
            inputProps={{
              readOnly: true
            }}
            label="Prezzo finale i.e."
            value={formik.values.dealerPrice ? totalPrice() : '-'}
          />
        </div>
        <div>
          {!updateMode && (
            <>
              <FileOfferUploadButton
                onChange={file => {
                  setFile(file)
                }}
                file={file}
                onDelete={() => setFile(null)}
              />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            localStorage.removeItem('carDraft')
            formik.submitForm()
          }}
        >
          {updateMode ? 'Modifica auto' : 'Aggiungi auto'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CarForm
