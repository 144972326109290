import React, { useState } from 'react'

import { useQuery, useReactiveVar } from '@apollo/client'
import {
  DealerForm,
  DealerProfile,
  OperatorProfile,
  OperatorForm,
  PasswordForm
} from 'components'
import { CURRENT_USER } from 'query'
import {
  me as UserType,
  me_me_Operator as OperatorType,
  me_me_Dealer as DealerType
} from 'generated/schemaTypes'
import { Typography } from '@material-ui/core'
import { roleVar } from 'cache'

export default function Profile() {
  const [showForm, setShowForm] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const roleCache = useReactiveVar(roleVar)
  const { data: userData, loading: loadingUserData } = useQuery<UserType>(
    CURRENT_USER
  )

  if (loadingUserData)
    return (
      <Typography
        variant="h4"
        align="center"
        style={{ marginTop: '100px', height: 50 }}
      >
        Caricamento del profilo in corso...
      </Typography>
    )

  return (
    <>
      {roleCache === 'DEALER' ? (
        <>
          <DealerProfile
            dealer={userData?.me as DealerType}
            onUpdateDealer={() => {
              setShowForm(true)
            }}
            onChangePassword={() => setShowChangePassword(true)}
          />
          <DealerForm
            open={showForm}
            dealer={userData?.me as DealerType}
            handleClose={() => {
              setShowForm(false)
            }}
          />
        </>
      ) : (
        <>
          <OperatorProfile
            operator={userData?.me as OperatorType}
            onUpdateOperator={() => setShowForm(true)}
            onChangePassword={() => setShowChangePassword(true)}
          />
          <OperatorForm
            open={showForm}
            operator={userData?.me as OperatorType}
            handleClose={() => {
              setShowForm(false)
            }}
          />
        </>
      )}
      <PasswordForm
        open={showChangePassword}
        handleClose={() => setShowChangePassword(false)}
      />
    </>
  )
}
