import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { SettingsDetail, SettingsForm } from 'components'
import { GET_SETTINGS } from 'query'
import {
  getSettings_getSettings as SettingsType,
  getSettings
} from 'generated/schemaTypes'
import { Typography } from '@material-ui/core'

export default function Settings() {
  const [showForm, setShowForm] = useState(false)
  const { data: settings, loading: loadingSettings } = useQuery<getSettings>(
    GET_SETTINGS
  )

  return (
    <>
      {loadingSettings ? (
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: '100px', height: 50 }}
        >
          Caricamento delle impostazioni in corso...
        </Typography>
      ) : (
        <>
          <SettingsDetail
            settings={settings?.getSettings as SettingsType}
            onUpdateSettings={() => setShowForm(true)}
          />
          <SettingsForm
            settings={settings?.getSettings as SettingsType}
            open={showForm}
            onClose={() => setShowForm(false)}
          />
        </>
      )}
    </>
  )
}
