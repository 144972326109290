import React, { useRef, useState } from 'react'

import { Button, Typography } from '@material-ui/core'
import { useMutation, useApolloClient } from '@apollo/client'
import { getCar } from 'generated/schemaTypes'
import { GET_FILE_URL_FILEOFFER, GET_CAR } from 'query'
import { UPDATE_CAR } from 'mutation'
import { MessageDialog } from 'components'

interface FileOfferProps {
  url: string | null
  carId: string
  dealerId: string
}

const FileOffer: React.FC<FileOfferProps> = ({ url, dealerId, carId }) => {
  const [showError, setShowError] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [error] = useState('La dimensione del file non può superare i 4MB')
  const client = useApolloClient()
  const inputEl = useRef<HTMLInputElement>(null)
  const [updateFileOffer] = useMutation(UPDATE_CAR, {
    update(
      cache,
      {
        data: {
          updateCar: { offer, id }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CAR,
        variables: { id }
      }) as getCar
      cache.writeQuery({
        query: GET_CAR,
        variables: { id },
        data: { getCar: { ...data.getCar, offer } }
      })
    }
  })

  const onSelectFile = () => {
    if (inputEl && inputEl.current) inputEl.current.click()
  }

  if (isUploading) return <div>Caricamento del file in corso</div>

  return (
    <>
      <input
        ref={inputEl}
        type="file"
        accept="image/jpeg,image/gif,image/png,application/pdf"
        id="input-upload"
        style={{ display: 'none' }}
        onChange={async e => {
          const files = e.target.files
          setIsUploading(true)
          if (files && files.length > 0 && files[0].size < 4 * 1024 * 1024) {
            try {
              const urlRequest = await client.query({
                query: GET_FILE_URL_FILEOFFER,
                variables: { dealerId, carId, filename: `${files[0].name}` }
              })
              const signedUrl = urlRequest.data.getSignedUrlForOfferFile
              const fileUrl = signedUrl.split('?')[0]
              const response = await fetch(signedUrl, {
                method: 'PUT',
                body: files[0]
              })
              setIsUploading(false)
              if (response.ok) {
                updateFileOffer({
                  variables: { id: carId, data: { offer: fileUrl } }
                })
              }
            } catch (err) {
              console.log('Error', err)
            }
          } else {
            setIsUploading(false)
            setShowError(true)
          }
        }}
      />
      {!url ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" display="inline">
            File offerta non caricato!{' '}
          </Typography>
          <Button size="small" onClick={onSelectFile}>
            Carica
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" display="inline">
            File offerta:{' '}
          </Typography>
          <Button
            size="small"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            Scarica
          </Button>
          <Typography variant="h5" display="inline">
            {' | '}
          </Typography>
          <Button size="small" onClick={onSelectFile}>
            Modifica
          </Button>
          <Typography variant="h5" display="inline">
            {' | '}
          </Typography>
          <Button
            size="small"
            onClick={() =>
              updateFileOffer({
                variables: { id: carId, data: { offer: null } }
              })
            }
          >
            Elimina
          </Button>
        </div>
      )}
      <MessageDialog
        open={showError}
        title="Attenzione"
        onClose={() => setShowError(false)}
      >
        {error}
      </MessageDialog>
    </>
  )
}

export default FileOffer
