import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { cache, isLoggedInVar } from './cache'
import numeral from 'numeral'
import { onError } from '@apollo/client/link/error'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  concat,
  NormalizedCacheObject
} from '@apollo/client'
import { typeDefs } from './typedefs'
import theme from './theme'
import RouteManager from 'routes/routeManager'
// import { GraphQLError } from 'graphql'
import { ReactComponent as ConfirmationSVG } from 'assets/confirmation.svg'

// load a locale
numeral.register('locale', 'it', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function(number) {
    return '°'
  },
  currency: {
    symbol: '€'
  }
})

// switch between locales
numeral.locale('it')

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData
// })

// import { possibleTypes } from '../introspection-result';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`)
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (extensions && extensions.code) {
        console.log(
          `[GraphQL error]: Message: ${message} Code: ${extensions.code}`
        )
        // handle errors differently based on its error code
        switch (extensions.code) {
          case 'UNAUTHENTICATED':
            localStorage.removeItem('token')
            isLoggedInVar(false)
            // client.writeData({ data: { isLoggedIn: false } })
            break
          default:
            // localStorage.removeItem('token')
            // client.writeData({ data: { isLoggedIn: false } })
            break
        }
      }
    })
  }
})

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT}/graphql`
  // credentials: 'include'
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')
  operation.setContext({
    headers: {
      authorization: token ? `Bearer: ${token}` : ''
    }
  })
  return forward(operation)
})

const link = ApolloLink.from([errorLink, concat(authMiddleware, httpLink)])

// const cache = new InMemoryCache({
//   possibleTypes: {
//     Profile: ['Dealer', 'Operator'],
//     User: ['Dealer', 'Operator'],
//     Client: ['ClientPrivate', 'ClientCompany'],
//     ClientCommon: ['ClientPrivate', 'ClientCompany']
//   }
// })

// cache.writeData({
//   data: {
//     isLoggedIn: !!localStorage.getItem('token'),
//     currentDealer: localStorage.getItem('currentDealer') || '',
//     filterProvince: [],
//     filterOwner: '',
//     filterContractStatus: 'ALL',
//     filterCarWithProposals: true,
//     filterCarWithoutProposals: true,
//     filterCarIncludeSold: false,
//     filterCarIncludeExpired: false,
//     orderCar: 'CREATION_DESC',
//     orderContract: 'CREATION_DESC',
//     orderDealer: 'DISPLAYNAME_ASC',
//     orderOperator: 'LASTNAME_ASC',
//     orderClient: 'NAME_ASC',
//     searchCar: '',
//     searchContract: '',
//     searchDealer: '',
//     searchOperator: '',
//     searchClient: '',
//     role: localStorage.getItem('role') || 'DEALER',
//     userId: localStorage.getItem('userId') || null
//   }
// })

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link,
  resolvers: {},
  typeDefs
})

// const styles = {
//   success: { backgroundColor: 'purple' },
//   error: { backgroundColor: 'blue' },
//   warning: { backgroundColor: 'green' },
//   info: { backgroundColor: 'yellow' }
// }

// const StyledSnackbar = withStyles(styles)(SnackbarProvider)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          classes={{
            variantSuccess: 'alert--success',
            variantError: 'alert--error',
            variantWarning: 'alert--warning',
            variantInfo: 'alert--info'
          }}
          iconVariant={{
            success: <ConfirmationSVG />,
            error: <ConfirmationSVG />,
            warning: <ConfirmationSVG />,
            info: <ConfirmationSVG />
          }}
        >
          <CssBaseline />
          <RouteManager />
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
