import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CREATE_LIST, UPDATE_LIST } from 'mutation'
import { GET_LISTS, GET_DOCUMENTS } from 'query'
import { CheckboxControl, CustomInputComponent } from 'components'

import {
  getLists_getLists as ListType,
  createList as createListMutation,
  createListVariables,
  updateList as updateListMutation,
  updateListVariables,
  getDocuments
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  listItem: {
    width: '50%'
  }
}))

export interface ListFormValues {
  label: string
  description: string
  documents: Array<string>
}

const defaultInitialValues = {
  label: '',
  description: '',
  documents: []
}

const validationSchema = Yup.object({
  label: Yup.string().required('Campo obbligatorio'),
  description: Yup.string().required('Campo obbligatorio')
})

interface ListFormProps {
  open: boolean
  handleClose: () => void
  list: ListType | null
}

const ListForm: React.FC<ListFormProps> = ({ open, list, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar()

  const updateMode = !!list

  const classes = useStyles()

  const initialValues = list
    ? {
        label: list.label,
        description: list.description,
        documents: list.documents.map(doc => doc.id)
      }
    : null

  const [createList] = useMutation<createListMutation, createListVariables>(
    CREATE_LIST,
    {
      onCompleted: async data => {
        formik.resetForm()
        enqueueSnackbar('Lista creata', {
          variant: 'success'
        })
        formik.resetForm()
        handleClose()
      },
      onError: err => {
        console.log('Errore!', err)
      },
      refetchQueries: [{ query: GET_LISTS }]
    }
  )

  const [updateList] = useMutation<updateListMutation, updateListVariables>(
    UPDATE_LIST,
    {
      onCompleted: async data => {
        formik.resetForm()
        enqueueSnackbar('Lista modificata', {
          variant: 'success'
        })
        formik.resetForm()
        handleClose()
      },
      onError: err => {
        console.log('Errore', err)
      }
    }
  )

  const {
    data: dataDocuments
    // loading: loadingDocuments,
    // error: errorDocuments
  } = useQuery<getDocuments>(GET_DOCUMENTS, {
    fetchPolicy: 'network-only'
  })

  const documents = dataDocuments ? dataDocuments.getDocuments : []

  const formik = useFormik<ListFormValues>({
    initialValues: initialValues || defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const variables: any = {
        data: {
          ...values
        }
      }
      if (updateMode) {
        variables.listId = (list as ListType).id
        await updateList({ variables })
      } else {
        await createList({ variables })
      }
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { maxWidth: '560px', padding: '45px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h3" style={{ marginBottom: '1em' }}>
          {updateMode ? 'Modifica la lista' : 'Crea una nuovo lista'}
        </Typography>

        <div style={{ width: '100%' }}>
          {/* Label */}
          <CustomInputComponent
            name="label"
            error={formik.touched.label && !!formik.errors.label}
            id="label"
            label="Nome della lista"
            value={formik.values.label}
            errorLabel={formik.errors.label}
            onChange={formik.handleChange}
          />
        </div>
        <div>
          {/* Description */}
          <CustomInputComponent
            name="description"
            type="textarea"
            error={formik.touched.description && !!formik.errors.description}
            id="description"
            label="Descrizione"
            value={formik.values.description}
            errorLabel={formik.errors.description}
            onChange={formik.handleChange}
          />
        </div>
        {/* Documents */}
        <div>
          <ul className={classes.list}>
            {documents.map(doc => {
              return (
                <li key={doc.id} className={classes.listItem}>
                  <CheckboxControl
                    label={doc.label}
                    position="left"
                    checked={formik.values.documents.includes(doc.id)}
                    onChange={() => {
                      formik.values.documents.includes(doc.id)
                        ? formik.setFieldValue(
                            'documents',
                            formik.values.documents.filter(d => d !== doc.id)
                          )
                        : formik.setFieldValue('documents', [
                            ...formik.values.documents,
                            doc.id
                          ])
                    }}
                    name={doc.id}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          {updateMode ? 'Modifica lista' : 'Crea lista'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ListForm
