import React, { useRef, useState } from 'react'

import { Button } from '@material-ui/core'
import { useMutation, useApolloClient } from '@apollo/client'
import { GET_FILE_URL_LOGO } from 'query'
import { UPDATE_DEALER } from 'mutation'
import { MessageDialog } from 'components'
import { CloseCircle } from 'mdi-material-ui'

interface FileLogoProps {
  url: string | null
  dealerId: string
}

const FileLogo: React.FC<FileLogoProps> = ({ url, dealerId }) => {
  const [showError, setShowError] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [error] = useState('La dimensione del file non può superare i 4MB')
  const client = useApolloClient()
  const inputEl = useRef<HTMLInputElement>(null)
  const [updateDealer] = useMutation(UPDATE_DEALER, {
    // update(
    //   cache,
    //   {
    //     data: {
    //       updateCar: { offer, id }
    //     }
    //   }
    // ) {
    //   const data = cache.readQuery({
    //     query: GET_CAR,
    //     variables: { id }
    //   }) as getCar
    //   cache.writeQuery({
    //     query: GET_CAR,
    //     variables: { id },
    //     data: { getCar: { ...data.getCar, offer } }
    //   })
    // }
  })

  const onSelectFile = () => {
    if (inputEl && inputEl.current) inputEl.current.click()
  }

  if (isUploading) return <div>Caricamento del file in corso</div>

  return (
    <>
      <input
        ref={inputEl}
        type="file"
        accept="image/jpeg,image/gif,image/png"
        id="input-upload"
        style={{ display: 'none' }}
        onChange={async e => {
          const files = e.target.files
          setIsUploading(true)
          if (files && files.length > 0 && files[0].size < 4 * 1024 * 1024) {
            try {
              const urlRequest = await client.query({
                query: GET_FILE_URL_LOGO,
                variables: { id: dealerId, filename: `${files[0].name}` }
              })
              const signedUrl = urlRequest.data.getSignedUrlForLogo
              const fileUrl = signedUrl.split('?')[0]
              const response = await fetch(signedUrl, {
                method: 'PUT',
                body: files[0]
              })
              setIsUploading(false)
              if (response.ok) {
                updateDealer({
                  variables: { id: dealerId, data: { logo: fileUrl } }
                })
              }
            } catch (err) {
              console.log('Error', err)
            }
          } else {
            setIsUploading(false)
            setShowError(true)
          }
        }}
      />
      {!url ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onSelectFile}
            style={{ width: '100%' }}
          >
            Carica un logo (200x55)
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            border: 'solid 1px #444444'
          }}
        >
          <img src={url} alt="" style={{ width: '100%', height: 'auto' }} />
          <div
            style={{
              position: 'absolute',
              top: -17,
              right: -17,
              cursor: 'pointer'
            }}
            onClick={() =>
              updateDealer({
                variables: { id: dealerId, data: { logo: null } }
              })
            }
          >
            <CloseCircle htmlColor="#444444" />
          </div>
        </div>
      )}
      <MessageDialog
        open={showError}
        title="Attenzione"
        onClose={() => setShowError(false)}
      >
        {error}
      </MessageDialog>
    </>
  )
}

export default FileLogo
