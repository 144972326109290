import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import { FuelSystem } from 'generated/schemaTypes'
import numeral from 'numeral'

interface CarInfoTableProps {
  displacement: number
  fuelSystem: FuelSystem
  power: number
  plate: string
  listPrice: number
  finalPrice: number
  manufacturerCode: string
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: 'repeat(7, minmax(50px, auto))'
    // flexWrap: 'wrap',
    // '& h4': {
    //   width: '50%'
    // },
    // '& h5': {
    //   width: '50%'
    // }
  }
}))

const CarInfoTable: React.FC<CarInfoTableProps> = ({
  displacement,
  fuelSystem,
  power,
  plate,
  listPrice,
  finalPrice,
  manufacturerCode
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h4">Alimentazione</Typography>
      <Typography variant="h5">{fuelSystem}</Typography>
      <Typography variant="h4">Codice costruttore</Typography>
      <Typography variant="h5">{manufacturerCode}</Typography>
      <Typography variant="h4">Potenza</Typography>
      <Typography variant="h5">
        {power} cv ({Math.round(power / 1.36)} kw)
      </Typography>
      <Typography variant="h4">Cilindrata</Typography>
      <Typography variant="h5">{displacement}</Typography>
      <Typography variant="h4">Telaio/Targa</Typography>
      <Typography variant="h5">{plate}</Typography>
      <Typography variant="h4">Prezzo di listino i.e.</Typography>
      <Typography variant="h5">
        {numeral(listPrice / 100).format('0,0.00')} €
      </Typography>
      <Typography variant="h4">
        Prezzo scontato i.e. (con accessori after market)
      </Typography>
      <Typography variant="h5">
        {numeral(finalPrice / 100).format('0,0.00')} €
      </Typography>
    </div>
  )
}

export default CarInfoTable
