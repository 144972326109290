import React from 'react'

import { Paper, Typography, makeStyles } from '@material-ui/core'

import {
  getContract_getContract_client as ClientType,
  getContract_getContract_client_ClientPrivate as ClientPrivateType,
  getContract_getContract_client_ClientCompany as ClientCompanyType
} from 'generated/schemaTypes'

interface ContractDetailClientProps {
  client: ClientType
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    width: '100%'
    // alignContent: 'space-between'
  },
  data: {
    marginBottom: '1em'
  },
  head: {
    width: '100%',
    marginBottom: '1em',
    borderBottom: `solid 1px ${theme.palette.text.primary}`,
    minHeight: '70px',
    alignItems: 'flex-start'
  }
}))

const companyTypes = {
  PUBBLICA_AMMINISTRAZIONE: 'Pubblica amministrazione',
  PERSONA_GIURIDICA: 'Persona Giuridica',
  DITTA_INDIVIDUALE: 'Ditta individuale'
}

const ContractDetailClient: React.FC<ContractDetailClientProps> = ({
  client
}) => {
  const classes = useStyles()

  const isPrivate = client.__typename === 'ClientPrivate'

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.head}>
        <Typography variant="h1">
          {client.name} {isPrivate && (client as ClientPrivateType).lastname}
        </Typography>
        <Typography variant="h4">
          (
          {isPrivate
            ? `Persona fisica`
            : companyTypes[(client as ClientCompanyType).companyType]}
          )
        </Typography>
      </div>

      <div>
        <div className={classes.data}>
          <Typography variant="h4">Indirizzo</Typography>
          <Typography variant="h5">
            {client.address.addressLine}, {client.address.zip}{' '}
            {client.address.hamlet} {client.address.city} (
            {client.address.province})
          </Typography>
        </div>
        {!isPrivate && (
          <div className={classes.data}>
            <Typography variant="h5">Partita iva</Typography>
            <Typography variant="h5">
              {(client as ClientCompanyType).vat}
            </Typography>
          </div>
        )}
        <div className={classes.data}>
          <Typography variant="h4">Codice fiscale</Typography>
          <Typography variant="h5">{client.cf}</Typography>
        </div>
        <div className={classes.data}>
          <Typography variant="h4">Email</Typography>
          <Typography variant="h5">{client.email}</Typography>
        </div>
        <div className={classes.data}>
          <Typography variant="h4">IBAN</Typography>
          <Typography variant="h5">{client.iban}</Typography>
        </div>
        {!isPrivate && (
          <div className={classes.data}>
            <Typography variant="h4">PEC</Typography>
            <Typography variant="h5">
              {(client as ClientCompanyType).pec || '-'}
            </Typography>
          </div>
        )}
        <div className={classes.data}>
          <Typography variant="h4">Cellulare</Typography>
          <Typography variant="h5">{client.mobile}</Typography>
        </div>
        <div className={classes.data}>
          <Typography variant="h4">Telefono fisso</Typography>
          <Typography variant="h5">{client.phone || '-'}</Typography>
        </div>
        <div className={classes.data}>
          <Typography variant="h4">Fax</Typography>
          <Typography variant="h5">{client.fax || '-'}</Typography>
        </div>
      </div>
    </Paper>
  )
}

export default ContractDetailClient
