import React, { useRef, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'

import { ReactComponent as ClipSVG } from 'assets/clip.svg'
import { ReactComponent as DeleteSVG } from 'assets/trash.svg'

const useStyles = makeStyles(theme => ({
  clip: {
    width: 24,
    height: 26,
    [theme.breakpoints.up('lg')]: {
      width: 33,
      height: 36
    }
  },
  delete: {
    width: 20,
    height: 24,
    [theme.breakpoints.up('lg')]: {
      width: 20,
      height: 31
    }
  }
}))

const FileOfferUploadButton: React.FC<{
  file: File | null
  onChange: (f: File | null) => void
  onDelete: () => void
}> = ({ file, onChange, onDelete }) => {
  const classes = useStyles()
  const [showError, setShowError] = useState(false)
  const inputEl = useRef<HTMLInputElement>(null)
  const onButtonClick = () => {
    if (inputEl && inputEl.current) inputEl.current.click()
  }

  return (
    <div>
      {file ? (
        <div>
          <Typography variant="h4" display="inline">
            {file.name}
          </Typography>
          <IconButton onClick={onDelete}>
            <DeleteSVG />
          </IconButton>
        </div>
      ) : (
        <>
          <Button
            onClick={onButtonClick}
            color="primary"
            startIcon={<ClipSVG className={classes.clip} />}
          >
            Allega file offerta
          </Button>

          <input
            ref={inputEl}
            type="file"
            id="input-upload"
            accept="image/jpeg,image/gif,image/png,application/pdf"
            style={{ display: 'none' }}
            onChange={e => {
              // Verifica dimensione file
              const files = e.target.files
              // console.log(files)
              if (
                files &&
                files.length > 0 &&
                files[0].size < 4 * 1024 * 1024
              ) {
                onChange(files[0])
              } else {
                setShowError(true)
                onChange(null)
              }
            }}
          />
        </>
      )}

      {showError && (
        <Dialog
          open={showError}
          onClose={() => setShowError(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Attenzione</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              La dimensione del file non può superare i 4MB!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowError(false)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default FileOfferUploadButton
