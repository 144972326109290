import React from 'react'

import { Typography } from '@material-ui/core'
import { format } from 'date-fns'
import numeral from 'numeral'

const CarHeader: React.FC<{
  trimLevel: string
  brand: string
  model: string
  registration: Date
  insertDate: Date
  km: number
}> = ({ trimLevel, brand, model, registration, km, insertDate }) => {
  return (
    <div>
      <Typography variant="h1">
        {brand} {model}
      </Typography>
      <Typography variant="h2" style={{ marginBottom: '0.6em' }}>
        {trimLevel}
      </Typography>
      <div>
        <Typography variant="h4" display="inline">
          Chilometri:{' '}
        </Typography>
        <Typography variant="h5" display="inline">
          {numeral(km).format()}
        </Typography>
      </div>
      <div>
        <Typography
          variant="h4"
          display="inline"
          style={{ marginBottom: '0.3em' }}
        >
          Immatricolazione:{' '}
        </Typography>
        <Typography
          variant="h5"
          display="inline"
          style={{ marginBottom: '0.3em' }}
        >
          {format(new Date(registration), 'dd/MM/yyyy')}
        </Typography>
      </div>
      <div>
        {/* <Typography
          variant="h4"
          display="inline"
          style={{ marginBottom: '0.3em' }}
        >
          Inserimento:{' '}
        </Typography>
        <Typography
          variant="h5"
          display="inline"
          style={{ marginBottom: '0.3em' }}
        >
          {format(new Date(insertDate), 'dd/MM/yyyy')}
        </Typography> */}
      </div>
    </div>
  )
}

export default CarHeader
