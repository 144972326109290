import gql from 'graphql-tag'

// const IS_LOGGED_IN = gql`
//   query isLoggedIn {
//     isLoggedIn @client
//   }
// `

// const USER_ROLE = gql`
//   query userRole {
//     role @client
//   }
// `

// const USER_ID = gql`
//   query userId {
//     userId @client
//   }
// `

// const FILTERS = gql`
//   query filters {
//     currentDealer @client
//     filterProvince @client
//     filterOwner @client
//     filterContractStatus @client
//     filterCarWithProposals @client
//     filterCarWithoutProposals @client
//     filterCarIncludeSold @client
//     filterCarIncludeExpired @client
//     orderCar @client
//     orderContract @client
//     orderDealer @client
//     orderOperator @client
//     orderClient @client
//     searchCar @client
//     searchContract @client
//     searchDealer @client
//     searchOperator @client
//     searchClient @client
//   }
// `

const CURRENT_USER = gql`
  query me {
    me {
      __typename
      ... on Dealer {
        displayName
        status
        id
        role
        address {
          addressLine
          city
          province
          zip
          hamlet
        }
        email
        company
        pi
        cf
        sdi
        phone
        logo
        primaryContact {
          name
          lastname
          phone
        }
        secondaryContact {
          name
          lastname
          phone
        }
        iban
      }
      ... on Operator {
        status
        name
        lastname
        email
        id
        role
      }
    }
  }
`

const GET_DEALERS_LIST_SIMPLE = gql`
  query getDealersListSimple {
    getDealers {
      dealers {
        id
        displayName
        address {
          province
        }
      }
    }
  }
`

const GET_DEALERS = gql`
  query getDealers(
    $where: DealerWhereInput
    $orderBy: DealerOrderByInput
    $cursor: Int
  ) {
    getDealers(where: $where, orderBy: $orderBy, cursor: $cursor) {
      dealers {
        id
        displayName
        status
        address {
          addressLine
          city
          province
          zip
          hamlet
        }
        email
        company
        pi
        cf
        sdi
        phone
        logo
        primaryContact {
          name
          lastname
          phone
        }
        secondaryContact {
          name
          lastname
          phone
        }
        iban
      }
      cursor
    }
  }
`

const GET_OPERATORS = gql`
  query getOperators(
    $where: OperatorWhereInput
    $orderBy: OperatorOrderByInput
  ) {
    getOperators(where: $where, orderBy: $orderBy) {
      id
      email
      name
      lastname
      status
    }
  }
`

const GET_OPERATOR = gql`
  query getOperator($id: ID!) {
    getOperator(id: $id) {
      id
      email
      name
      lastname
      status
    }
  }
`

const GET_DEALER = gql`
  query getDealer($id: ID!) {
    getDealer(id: $id) {
      id
      displayName
      status
      address {
        addressLine
        city
        hamlet
        province
        zip
      }
      email
      company
      pi
      cf
      sdi
      phone
      logo
      primaryContact {
        name
        lastname
        phone
      }
      secondaryContact {
        name
        lastname
        phone
      }
      iban
    }
  }
`

const GET_OPERATORS_LIST_SIMPLE = gql`
  query getOperatorsListSimple {
    getOperators(where: { includeSuspended: true }) {
      id
      name
      lastname
    }
  }
`

// const SET_CAR_OWNER = gql`
//   query setCarOwner($carId: ID!, $operatorId: ID) {
//     setCarOwner() {
//       id
//       name
//       lastname
//     }
//   }
// `

const GET_FILE_URL_FILEOFFER = gql`
  query getSignedUrlForOfferFile(
    $dealerId: ID!
    $carId: ID!
    $filename: String!
  ) {
    getSignedUrlForOfferFile(
      dealerId: $dealerId
      filename: $filename
      carId: $carId
    )
  }
`

const GET_FILE_URL_FILECONTRACT = gql`
  query getSignedUrlForContractFile(
    $dealerId: ID!
    $contractId: ID!
    $filename: String!
  ) {
    getSignedUrlForContractFile(
      dealerId: $dealerId
      filename: $filename
      contractId: $contractId
    )
  }
`

const GET_FILE_URL_DOCUMENT = gql`
  query getSignedUrlForDocumentFile(
    $dealerId: ID!
    $r2gClientId: ID!
    $r2gContractId: ID!
    $filename: String!
  ) {
    getSignedUrlForDocumentFile(
      dealerId: $dealerId
      filename: $filename
      r2gClientId: $r2gClientId
      r2gContractId: $r2gContractId
    )
  }
`

const GET_FILE_URL_DEPOSIT = gql`
  query getSignedUrlForDepositFile(
    $dealerId: ID!
    $r2gClientId: ID!
    $r2gContractId: ID!
    $filename: String!
  ) {
    getSignedUrlForDepositFile(
      dealerId: $dealerId
      filename: $filename
      r2gClientId: $r2gClientId
      r2gContractId: $r2gContractId
    )
  }
`

const GET_FILE_URL_CAR = gql`
  query getSignedUrlForCar($dealerId: ID!, $carId: ID!, $filename: String!) {
    getSignedUrlForCar(dealerId: $dealerId, filename: $filename, carId: $carId)
  }
`

const GET_FILE_URL_LOGO = gql`
  query getSignedUrlForLogo($id: ID!, $filename: String!) {
    getSignedUrlForLogo(id: $id, filename: $filename)
  }
`

const GET_SPOT_MODULE = gql`
  query getSpotModule($contractId: ID!) {
    getSpotModule(contractId: $contractId)
  }
`

const GET_CARS = gql`
  query getCars(
    $where: CarWhereInput
    $orderBy: CarOrderByInput
    $cursor: DateTime
  ) {
    getCars(where: $where, orderBy: $orderBy, cursor: $cursor) {
      cars {
        id
        brand
        model
        image
        trimLevel
        displacement
        fuelSystem
        power
        plate
        km
        registration
        insertDate
        listPrice
        dealerPrice
        totalPrice
        proposalsCount
        # optionals
        # optionalsAfterMarket
        offer
        sold
        dealer {
          id
          displayName
        }
        operator {
          id
          name
          lastname
        }
        proposals {
          id
          rent
          months
          km
          deposit
        }
      }
      cursor
    }
  }
`

const GET_CAR_BASE_INFO = gql`
  query getCarBaseInfo($id: ID!) {
    getCar(id: $id) {
      id
      brand
      model
      image
      trimLevel
      displacement
      fuelSystem
      power
      manufacturerCode
      plate
      km
      proposalsCount
      registration
      listPrice
      dealerPrice
      totalPrice
      optionals {
        price
        code
        description
      }
      optionalsAfterMarket {
        price
        description
      }
    }
  }
`

const GET_CAR = gql`
  query getCar($id: ID!) {
    getCar(id: $id) {
      id
      brand
      model
      image
      dealer {
        displayName
        id
      }
      sold
      insertDate
      trimLevel
      displacement
      fuelSystem
      power
      plate
      km
      registration
      manufacturerCode
      listPrice
      dealerPrice
      totalPrice
      offer
      optionals {
        price
        code
        description
      }
      optionalsAfterMarket {
        price
        description
      }
      proposalsCount
      proposals {
        id
        rent
        months
        km
        mss
        infoCar
        winterTires
        summerTires
        allSeasonTires
        deposit
        replacementCar
        windows
        pai
        kasko
        ard
      }
      operator {
        id
        name
        lastname
      }
    }
  }
`

const GET_PROPOSAL = gql`
  query getProposal($id: ID!) {
    getProposal(id: $id) {
      id
      months
      km
      mss
      rent
      infoCar
      winterTires
      summerTires
      allSeasonTires
      deposit
      replacementCar
      windows
      kasko
      ard
      pai
    }
  }
`

const GET_DOCUMENTS = gql`
  query getDocuments {
    getDocuments {
      id
      label
      description
    }
  }
`

const GET_LISTS = gql`
  query getLists {
    getLists {
      id
      label
      description
      documents {
        id
        label
        description
      }
    }
  }
`

const GET_DOCUMENT = gql`
  query getDocument($id: ID!) {
    getDocument(id: $id) {
      id
      label
      description
    }
  }
`

const GET_LIST = gql`
  query getList($id: ID!) {
    getList(id: $id) {
      id
      label
      description
      documents {
        id
        label
        description
      }
    }
  }
`

const GET_CLIENTS = gql`
  query getClients(
    $where: ClientWhereInput
    $orderBy: ClientOrderByInput
    $cursor: Int
  ) {
    getClients(where: $where, orderBy: $orderBy, cursor: $cursor) {
      clients {
        __typename
        ... on ClientPrivate {
          id
          name
          lastname
          cf
          mobile
          email
          phone
          fax
          iban
          privacy
          address {
            addressLine
            city
            zip
            hamlet
            province
          }
          dealer {
            id
          }
          contactType
          contractsCount
        }
        ... on ClientCompany {
          id
          name
          cf
          companyType
          vat
          mobile
          email
          phone
          fax
          iban
          privacy
          address {
            addressLine
            city
            zip
            hamlet
            province
          }
          legalRepresentativeName
          legalRepresentativeCF
          contactType
          pec
          dealer {
            id
          }
          contractsCount
        }
      }
      cursor
    }
  }
`

const GET_CLIENTS_SIMPLE = gql`
  query getClientsSimple($where: ClientWhereInput) {
    getClients(where: $where, noLimit: true) {
      clients {
        __typename
        ... on ClientPrivate {
          id
          name
          lastname
          dealer {
            id
          }
        }
        ... on ClientCompany {
          id
          name
          dealer {
            id
          }
          contractsCount
        }
      }
      cursor
    }
  }
`

const GET_CLIENT = gql`
  query getClient($id: ID!) {
    getClient(id: $id) {
      __typename
      ... on ClientPrivate {
        id
        name
        lastname
        cf
        mobile
        email
        phone
        fax
        iban
        privacy
        address {
          addressLine
          city
          zip
          hamlet
          province
        }
        dealer {
          id
        }
        contactType
        contractsCount
      }
      ... on ClientCompany {
        id
        name
        cf
        companyType
        vat
        mobile
        email
        phone
        fax
        iban
        privacy
        address {
          addressLine
          city
          zip
          hamlet
          province
        }
        legalRepresentativeName
        legalRepresentativeCF
        contactType
        pec
        dealer {
          id
        }
        contractsCount
      }
    }
  }
`

const GET_CONTRACTS = gql`
  query getContracts(
    $where: ContractWhereInput
    $orderBy: ContractOrderByInput
    $cursor: DateTime
  ) {
    getContracts(where: $where, orderBy: $orderBy, cursor: $cursor) {
      contracts {
        id
        status
        outcome
        insertDate
        r2gClientId
        r2gContractId
        dealer {
          id
          displayName
        }
        operator {
          id
        }
        car {
          id
          model
          brand
          trimLevel
          registration
          insertDate
          km
        }
        proposal {
          id
          rent
          months
          km
        }
        client {
          __typename
          ... on ClientPrivate {
            id
            name
            lastname
            cf
            mobile
            email
            iban
            address {
              addressLine
              city
              zip
              hamlet
              province
            }
            dealer {
              id
            }
          }
          ... on ClientCompany {
            id
            name
            cf
            companyType
            vat
            mobile
            email
            iban
            address {
              addressLine
              city
              zip
              hamlet
              province
            }
          }
        }
      }
      cursor
    }
  }
`

const GET_CONTRACT = gql`
  query getContract($contractId: ID!) {
    getContract(contractId: $contractId) {
      id
      status
      outcome
      insertDate
      fileContract
      documentList {
        id
        label
      }
      deposit {
        url
        status
        notes
      }
      rentDeposit {
        url
        status
        notes
      }
      documents {
        documentId
        url
        status
        notes
      }
      r2gClientId
      r2gContractId
      notes
      dealer {
        id
        displayName
      }
      operator {
        id
      }
      car {
        id
        model
        brand
        trimLevel
        registration
        insertDate
        km
        image
        displacement
        fuelSystem
        power
        totalPrice
      }
      proposal {
        id
        months
        km
        mss
        rent
        infoCar
        winterTires
        summerTires
        allSeasonTires
        deposit
        replacementCar
        windows
        kasko
        ard
        pai
      }
      client {
        __typename
        ... on ClientPrivate {
          id
          name
          lastname
          cf
          mobile
          email
          phone
          fax
          iban
          privacy
          address {
            addressLine
            city
            zip
            hamlet
            province
          }
          dealer {
            id
          }
          contactType
        }
        ... on ClientCompany {
          id
          name
          cf
          companyType
          vat
          mobile
          email
          phone
          fax
          iban
          privacy
          address {
            addressLine
            city
            zip
            hamlet
            province
          }
          legalRepresentativeName
          legalRepresentativeCF
          contactType
          pec
        }
      }
    }
  }
`

const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      emailScoringR2G
      emailDigitalSignR2G
      vat
    }
  }
`

export {
  GET_CAR,
  GET_CAR_BASE_INFO,
  GET_CARS,
  GET_CLIENT,
  GET_CLIENTS,
  GET_CLIENTS_SIMPLE,
  GET_CONTRACT,
  GET_CONTRACTS,
  GET_DEALER,
  GET_DEALERS,
  GET_DEALERS_LIST_SIMPLE,
  GET_DOCUMENT,
  GET_DOCUMENTS,
  GET_LIST,
  GET_LISTS,
  GET_OPERATOR,
  GET_OPERATORS,
  GET_OPERATORS_LIST_SIMPLE,
  GET_PROPOSAL,
  // FILTERS,
  GET_FILE_URL_CAR,
  GET_FILE_URL_LOGO,
  GET_FILE_URL_FILEOFFER,
  GET_FILE_URL_FILECONTRACT,
  GET_FILE_URL_DOCUMENT,
  GET_FILE_URL_DEPOSIT,
  GET_SETTINGS,
  GET_SPOT_MODULE,
  // IS_LOGGED_IN,
  CURRENT_USER
  // USER_ID,
  // USER_ROLE
}
