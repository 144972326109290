import React from 'react'

import { Avatar, Typography, makeStyles } from '@material-ui/core'
import { color } from 'theme'

interface StyleProps {
  isChecked: boolean
}

const useStyles = makeStyles(theme => ({
  root: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {},
    '& h6': {
      marginLeft: 19,
      color: props.isChecked ? color.DarkGrey : color.MediumLightGrey
    },
    // background: props.isChecked ? 'red' : 'yellow',
    '& .MuiAvatar-root': {
      background: props.isChecked ? color.DesaturatedBlue : color.LightGrey,
      color: props.isChecked ? 'white' : color.MediumLightGrey,
      width: theme.spacing(7),
      height: theme.spacing(7)
    }
  })
}))

const DealerCheckbox: React.FC<{
  checked: boolean
  onClick: () => void
  label: string
}> = ({ checked, onClick, label }) => {
  const classes = useStyles({ isChecked: checked })
  return (
    <div className={classes.root} onClick={onClick}>
      <Avatar>{label[0]}</Avatar>
      <Typography variant="h6">{label}</Typography>
    </div>
  )
}

export default DealerCheckbox
