import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppBar, Menu, MenuItem, Toolbar, makeStyles } from '@material-ui/core'
import { useQuery, useReactiveVar } from '@apollo/client'
import { DealersFilter, Avatar } from 'components'
import { CURRENT_USER } from 'query'
import {
  me as UserType,
  me_me_Operator as OperatorType,
  me_me_Dealer as DealerType
} from 'generated/schemaTypes'
import { isLoggedInVar, roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  root: {
    background: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  title: {
    flexGrow: 1
  }
}))

const ApplicationBar: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const roleCache = useReactiveVar(roleVar)
  const { data: userData, loading: loadingUserData } = useQuery<UserType>(
    CURRENT_USER,
    { fetchPolicy: 'network-only' }
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    // client.cache.evict({ fieldName: 'me' })
    // client.cache.gc()

    // localStorage.removeItem('dealersFilter')
    // localStorage.removeItem('filterOwner')
    localStorage.removeItem('currentDealer')
    localStorage.removeItem('role')
    localStorage.removeItem('userId')
    localStorage.removeItem('token')
    isLoggedInVar(false)
    // client.writeData({
    // data: {
    // isLoggedIn: false
    // currentDealer: '',
    // filterProvince: [],
    // filterOwner: '',
    // orderCar: 'DESC',
    // role: null,
    // userId: null
    // }
    // })
    // history.push('/login')
    // window.open('/login', '_self')
  }

  if (loadingUserData && !userData?.me) return null

  return (
    <AppBar elevation={1} position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="Noloplan 2X" src="/noloplan.png" width={189} height={55} />
          {roleCache !== 'DEALER' && (
            <div style={{ marginLeft: 24 }}>
              <DealersFilter />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {roleCache === 'DEALER' && (userData?.me as DealerType).logo && (
            <img
              alt={(userData?.me as DealerType).displayName}
              src={(userData?.me as DealerType).logo as string}
              width={200}
              height={55}
            />
          )}
          <div
            onClick={handleClick}
            style={{
              display: 'inline-block',
              cursor: 'pointer',
              marginLeft: 16
            }}
          >
            <Avatar
              color={1}
              label={
                roleCache === 'DEALER'
                  ? (userData?.me as DealerType).displayName[0]
                  : `${(userData?.me as OperatorType).name[0]}${
                      (userData?.me as OperatorType).lastname[0]
                    }`
              }
              size="normal"
            />
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {roleCache === 'ADMIN' && (
            <MenuItem onClick={() => history.push('/documents')}>
              Gestione documenti
            </MenuItem>
          )}
          {roleCache === 'ADMIN' && (
            <MenuItem onClick={() => history.push('/lists')}>
              Gestione liste
            </MenuItem>
          )}
          {roleCache === 'ADMIN' && (
            <MenuItem onClick={() => history.push('/operators')}>
              Gestione operatori
            </MenuItem>
          )}
          {roleCache === 'ADMIN' && (
            <MenuItem onClick={() => history.push('/settings')}>
              Settings
            </MenuItem>
          )}
          <MenuItem onClick={() => history.push('/profile')}>Profilo</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default ApplicationBar
