import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError, useMutation } from '@apollo/client'
import { Button, Paper, makeStyles, Typography } from '@material-ui/core'
import { REQUEST_RESET_PASSWORD } from 'mutation'
import { CustomInputComponent } from 'components'

const useStyles = makeStyles(theme => ({
  paper: {
    margin: '0 auto',
    width: 560,
    padding: '50px 76px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  successText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },
  text: {
    color: 'black',
    textAlign: 'left',
    width: '100%',
    margin: 0
  },
  invalidEmail: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textAlign: 'left',
    padding: theme.spacing(1)
  }
}))

interface RequestTokenFormValues {
  email: string
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message
}

const RequestTokenForm: React.FC = () => {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const formik = useFormik<RequestTokenFormValues>({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Indirizzo email non valido')
        .required('Campo obbligatorio')
    }),
    onSubmit: async values => {
      await requestTokenPasswordMutation({
        variables: { email: values.email }
      })
    }
  })
  const [
    requestTokenPasswordMutation,
    { error: loginError, loading: isLoading }
  ] = useMutation(REQUEST_RESET_PASSWORD, {
    onCompleted: data => {
      setSuccess(true)
    },
    onError: err => {
      formik.resetForm()
    }
  })

  return (
    <Paper className={classes.paper}>
      <>
        <Typography variant="h1" style={{ marginBottom: 24 }}>
          Recupero password
        </Typography>
        {!success && (
          <>
            <Typography variant="h3" style={{ marginBottom: 32 }}>
              Inserisci il tuo indirizzo email per recuperare la passowrd
            </Typography>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.form}
              noValidate
            >
              <CustomInputComponent
                name="email"
                error={formik.touched.email && !!formik.errors.email}
                id="email"
                label="Email"
                value={formik.values.email}
                errorLabel={formik.errors.email}
                onChange={formik.handleChange}
                disabled={isLoading}
              />

              {loginError && (
                <p className={classes.invalidEmail}>
                  {formatError(loginError)}
                </p>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                disabled={isLoading}
                className={classes.submit}
              >
                Entra
              </Button>
            </form>
          </>
        )}
        {success && (
          <p>Controlla la mail, troverai un link per resettare la password!</p>
        )}
      </>
    </Paper>
  )
}

export default RequestTokenForm
