import React from 'react'

import { Fab, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  createCar: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

const AddFab: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Fab
      className={classes.createCar}
      color="primary"
      aria-label="add new item"
      onClick={onClick}
    >
      <AddIcon style={{ fill: 'white' }} />
    </Fab>
  )
}

export default AddFab
