import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { provinces } from 'data/province'

import { CREATE_CLIENT_COMPANY, UPDATE_CLIENT_COMPANY } from 'mutation'
import { CheckboxControl, CustomInputComponent } from 'components'
import { color } from 'theme'

import {
  getClients_getClients_clients_ClientCompany as ClientType,
  createClientCompanyVariables,
  updateClientCompanyVariables,
  createClientCompany
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  baseInfoWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    gridTemplateRows: 'auto auto auto',
    position: 'relative',
    marginBottom: 30
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  grid: {
    display: 'grid',
    margin: 'auto',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    columnGap: 50
  },
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignContent: 'space-between',
    '& > *': {
      width: '100%',
      '& > *': {
        width: '100%'
      }
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 360,
    height: 180,
    backgroundPosition: 'center'
  }
}))

export interface ClientFormValues {
  name: string
  // lastname: string
  cf: string
  mobile: string
  email: string
  phone: string
  fax: string
  iban: string
  privacy: boolean
  addressLine: string
  zip: string
  city: string
  province: string
  hamlet: string
  contactType: string
  companyType: string
  vat: string
  legalRepresentativeName: string
  legalRepresentativeCF: string
  pec: string
}

const defaultInitialValues = {
  name: '',
  lastname: '',
  cf: '',
  mobile: '',
  email: '',
  phone: '',
  fax: '',
  iban: '',
  privacy: false,
  addressLine: '',
  zip: '',
  city: '',
  province: '',
  hamlet: '',
  contactType: '',
  companyType: '',
  vat: '',
  legalRepresentativeName: '',
  legalRepresentativeCF: '',
  pec: ''
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obbligatorio'),
  cf: Yup.string().required('Campo obbligatorio'),
  mobile: Yup.string().required('Campo obbligatorio'),
  email: Yup.string()
    .email('Indirizzo email non valido')
    .required('Campo obbligatorio'),
  privacy: Yup.boolean().oneOf([true], 'Campo obbligatorio'),
  addressLine: Yup.string().required('Campo obbligatorio'),
  zip: Yup.string().required('Campo obbligatorio'),
  city: Yup.string().required('Campo obbligatorio'),
  iban: Yup.string().required('Campo obbligatorio'),
  province: Yup.string().required('Campo obbligatorio'),
  hamlet: Yup.string(),
  contactType: Yup.string(),
  companyType: Yup.string().required('Campo obbligatorio'),
  vat: Yup.string().required('Campo obbligatorio'),
  legalRepresentativeName: Yup.string(),
  legalRepresentativeCF: Yup.string(),
  pec: Yup.string()
})

const parseInitialValue = (input: ClientType): ClientFormValues => {
  return {
    name: input.name,
    cf: input.cf,
    mobile: input.mobile,
    email: input.email,
    phone: input.phone ? input.phone : '',
    fax: input.fax ? input.fax : '',
    privacy: input.privacy,
    addressLine: input.address.addressLine,
    zip: input.address.zip,
    city: input.address.city,
    province: input.address.province,
    hamlet: input.address.hamlet ? input.address.hamlet : '',
    iban: input.iban,
    contactType: input.contactType ? input.contactType : '',
    companyType: input.companyType,
    vat: input.vat,
    legalRepresentativeCF: input.legalRepresentativeCF
      ? input.legalRepresentativeCF
      : '',
    legalRepresentativeName: input.legalRepresentativeName
      ? input.legalRepresentativeName
      : '',
    pec: input.pec ? input.pec : ''
  }
}

interface ClientFormProps {
  open: boolean
  handleClose: () => void
  client: ClientType | null
  dealerId: string
  redirect?: boolean
}

const ClientForm: React.FC<ClientFormProps> = ({
  open,
  client,
  handleClose,
  dealerId,
  redirect = false
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const updateMode = !!client

  const classes = useStyles()

  const initialValues = client ? parseInitialValue(client) : null

  const [createClient] = useMutation<
    createClientCompany,
    createClientCompanyVariables
  >(CREATE_CLIENT_COMPANY, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Cliente creato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
      if (redirect) history.push(`/clients/${data.createClientCompany.id}`)
    },
    onError: err => {
      console.log('Errore!', err)
    }
  })

  const [updateClient] = useMutation(UPDATE_CLIENT_COMPANY, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Cliente modificato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore', err)
    }
  })

  type clientData = updateClientCompanyVariables | createClientCompanyVariables
  // type clientData = updateClientCompanyVariables

  const formik = useFormik<ClientFormValues>({
    initialValues: initialValues || defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      let variables: any = {
        data: {
          name: values.name,
          cf: values.cf,
          mobile: values.mobile,
          email: values.email,
          phone: values.phone || null,
          fax: values.fax || null,
          privacy: values.privacy,
          iban: values.iban,
          address: {
            addressLine: values.addressLine,
            zip: values.zip,
            city: values.city,
            province: values.province,
            hamlet: values.hamlet || null
          },
          contactType: values.contactType || null,
          vat: values.vat,
          companyType: values.companyType,
          legalRepresentativeCF: values.legalRepresentativeCF || null,
          legalRepresentativeName: values.legalRepresentativeName || null,
          pec: values.pec || null
        },
        clientId: ''
      }
      console.log('is update', updateMode)
      if (updateMode) {
        variables.clientId = (client as ClientType).id
        await updateClient({ variables })
      } else {
        variables.data.dealer = dealerId
        await createClient({ variables })
      }
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '1264px', padding: '50px 80px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Informazioni di base
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* NOME */}
            <CustomInputComponent
              name="name"
              error={formik.touched.name && !!formik.errors.name}
              id="name"
              label="Denominazione*"
              value={formik.values.name}
              errorLabel={formik.errors.name}
              onChange={formik.handleChange}
            />
            {/* CODICE FISCALE */}
            <CustomInputComponent
              name="cf"
              error={formik.touched.cf && !!formik.errors.cf}
              id="cf"
              label="Codice fiscale*"
              value={formik.values.cf}
              errorLabel={formik.errors.cf}
              onChange={formik.handleChange}
            />
            {/* PARTITA IVA */}
            <CustomInputComponent
              name="vat"
              error={formik.touched.vat && !!formik.errors.vat}
              id="vat"
              label="Partita iva*"
              value={formik.values.vat}
              errorLabel={formik.errors.vat}
              onChange={formik.handleChange}
            />
            {/* IBAN */}
            <CustomInputComponent
              name="iban"
              error={formik.touched.iban && !!formik.errors.iban}
              id="iban"
              label="IBAN*"
              value={formik.values.iban}
              errorLabel={formik.errors.iban}
              onChange={formik.handleChange}
            />
            {/* TIPOLOGIA AZIENDA */}
            <CustomInputComponent
              name="companyType"
              error={formik.touched.companyType && !!formik.errors.companyType}
              id="companyType"
              label="Tipologia azienda"
              value={formik.values.companyType}
              errorLabel={formik.errors.companyType}
              onChange={formik.handleChange}
              type="select"
            >
              <MenuItem value="PERSONA_GIURIDICA">Persona giuridica</MenuItem>
              <MenuItem value="PUBBLICA_AMMINISTRAZIONE">
                Pubblica amministrazione
              </MenuItem>
              <MenuItem value="DITTA_INDIVIDUALE">Ditta individuale</MenuItem>
            </CustomInputComponent>

            {/* EMAIL */}
            <CustomInputComponent
              name="email"
              error={formik.touched.email && !!formik.errors.email}
              id="email"
              label="Email*"
              value={formik.values.email}
              errorLabel={formik.errors.email}
              onChange={formik.handleChange}
            />
            {/* PEC */}
            <CustomInputComponent
              name="pec"
              error={formik.touched.pec && !!formik.errors.pec}
              id="pec"
              label="Pec"
              value={formik.values.pec}
              errorLabel={formik.errors.pec}
              onChange={formik.handleChange}
            />
            {/* CELLULARE */}
            <CustomInputComponent
              name="mobile"
              error={formik.touched.mobile && !!formik.errors.mobile}
              id="mobile"
              label="Cellulare*"
              value={formik.values.mobile}
              errorLabel={formik.errors.mobile}
              onChange={formik.handleChange}
            />
            {/* TELEFONO FISSO */}
            <CustomInputComponent
              name="phone"
              error={formik.touched.phone && !!formik.errors.phone}
              id="phone"
              label="Telefono fisso"
              value={formik.values.phone}
              errorLabel={formik.errors.phone}
              onChange={formik.handleChange}
            />
            {/* FAX */}
            <CustomInputComponent
              name="fax"
              error={formik.touched.fax && !!formik.errors.fax}
              id="fax"
              label="Fax"
              value={formik.values.fax}
              errorLabel={formik.errors.fax}
              onChange={formik.handleChange}
            />

            {/* INDIRIZZO */}
            <CustomInputComponent
              name="addressLine"
              error={formik.touched.addressLine && !!formik.errors.addressLine}
              id="addressLine"
              label="Indirizzo*"
              value={formik.values.addressLine}
              errorLabel={formik.errors.addressLine}
              onChange={formik.handleChange}
            />
            {/* CITTÀ */}
            <CustomInputComponent
              name="city"
              error={formik.touched.city && !!formik.errors.city}
              id="city"
              label="Città*"
              value={formik.values.city}
              errorLabel={formik.errors.city}
              onChange={formik.handleChange}
            />
            {/* PROVINCIA */}
            <CustomInputComponent
              name="province"
              error={formik.touched.province && !!formik.errors.province}
              id="province"
              label="Provincia"
              value={formik.values.province}
              errorLabel={formik.errors.province}
              onChange={formik.handleChange}
              type="select"
            >
              {provinces.map(province => (
                <MenuItem key={province.value} value={province.value}>
                  {province.label}
                </MenuItem>
              ))}
            </CustomInputComponent>
            {/* CAP */}
            <CustomInputComponent
              name="zip"
              error={formik.touched.zip && !!formik.errors.zip}
              id="zip"
              label="CAP*"
              value={formik.values.zip}
              errorLabel={formik.errors.zip}
              onChange={formik.handleChange}
            />

            {/* LOCALITÀ */}
            <CustomInputComponent
              name="hamlet"
              error={formik.touched.hamlet && !!formik.errors.hamlet}
              id="hamlet"
              label="Località"
              value={formik.values.hamlet}
              errorLabel={formik.errors.hamlet}
              onChange={formik.handleChange}
            />

            {/* TIPO CONTATTO */}
            <CustomInputComponent
              name="contactType"
              error={formik.touched.contactType && !!formik.errors.contactType}
              id="contactType"
              label="Tipologia contatto"
              value={formik.values.contactType}
              errorLabel={formik.errors.contactType}
              onChange={formik.handleChange}
            />

            {/* LEGALE RAPPRESENTANTE */}
            <CustomInputComponent
              name="legalRepresentativeName"
              error={
                formik.touched.legalRepresentativeName &&
                !!formik.errors.legalRepresentativeName
              }
              id="legalRepresentativeName"
              label="Legale rappresentante"
              value={formik.values.legalRepresentativeName}
              errorLabel={formik.errors.legalRepresentativeName}
              onChange={formik.handleChange}
            />

            {/* CODICE FISCALE LEGALE RAPPRESENTANTE */}
            <CustomInputComponent
              name="legalRepresentativeCF"
              error={
                formik.touched.legalRepresentativeCF &&
                !!formik.errors.legalRepresentativeCF
              }
              id="legalRepresentativeCF"
              label="Legale Rappresentante - C.F."
              value={formik.values.legalRepresentativeCF}
              errorLabel={formik.errors.legalRepresentativeCF}
              onChange={formik.handleChange}
            />

            {/* PRIVACY */}
            <CheckboxControl
              label="Privacy"
              position="left"
              checked={formik.values.privacy}
              onChange={formik.handleChange}
              name="privacy"
              errorLabel={formik.errors.privacy}
              error={formik.touched.privacy && !!formik.errors.privacy}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          {updateMode ? 'Modifica cliente' : 'Aggiungi cliente'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientForm
