import React from 'react'

import { Avatar, makeStyles } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface StyleProps {
  size: 'small' | 'normal'
  index: number
}

const colors = [
  '#F0B782',
  '#F0829C',
  '#8294F0',
  '#63DF94',
  '#FFE100',
  '#0033cc',
  '#cc99cc',
  '#cc3333',
  '#008080',
  '#FFA500',
  '#1E90FF',
  '#FFD700',
  '#00CED1',
  '#FF00FF',
  '#DC143C',
  '#FF4500'
]

const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    fontSize: props.size === 'small' ? 10 : 14,
    width: props.size === 'small' ? 24 : 40,
    height: props.size === 'small' ? 24 : 40,
    backgroundColor: colors[props.index]
  })
}))

const CustomAvatar: React.FC<{
  style?: CSSProperties
  color: number
  label: string
  size?: 'normal' | 'small'
}> = ({ style = {}, color, label, size = 'normal' }) => {
  const index = 15 < color || color < 0 ? 0 : color
  const classes = useStyles({ size, index })
  return (
    <Avatar style={style} className={classes.root}>
      {label}
    </Avatar>
  )
}

export default CustomAvatar
