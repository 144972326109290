import React from 'react'
import { useMutation } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { SET_CONTRACT_DOCUMENT_STATUS } from 'mutation'
import {
  setContractDocumentStatus,
  setContractDocumentStatusVariables,
  DocumentStatus
} from 'generated/schemaTypes'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CustomInputComponent } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  }
}))

export interface RefuseDocumentFormValues {
  notes: string
}

const defaultInitialValues = {
  notes: ''
}

const validationSchema = Yup.object({
  notes: Yup.string().required('Campo obbligatorio')
})

interface RefuseDocumentFormProps {
  open: boolean
  contractId: string
  documentId: string
  onClose: () => void
}

const RefuseDocumentForm: React.FC<RefuseDocumentFormProps> = ({
  open,
  contractId,
  documentId,
  onClose
}) => {
  const classes = useStyles()

  const [
    setContractDocumentStatusMutation,
    { loading: isChangingContractDocumentStatus }
  ] = useMutation<
    setContractDocumentStatus,
    setContractDocumentStatusVariables
  >(SET_CONTRACT_DOCUMENT_STATUS)

  const formik = useFormik<RefuseDocumentFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      await setContractDocumentStatusMutation({
        variables: {
          contractId,
          documentId,
          status: DocumentStatus.REFUSED,
          notes: values.notes
        }
      })
      onClose()
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      PaperProps={{
        style: { maxWidth: '560px', padding: '45px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h1" style={{ marginBottom: '1em' }}>
          Attenzione!
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '1em' }}>
          Attento! Stai per rifiutare questo file! L’operazione non è
          reversibile, confermi l’eliminazione?
        </Typography>

        <div>
          {/* Notes */}
          <CustomInputComponent
            name="notes"
            type="textarea"
            error={formik.touched.notes && !!formik.errors.notes}
            id="notes"
            label="Descrizione"
            placeholder="Scrivi qui perché il documento caricato non va bene e fallo sapere al concessionario (obbligatorio)"
            value={formik.values.notes}
            errorLabel={formik.errors.notes}
            onChange={formik.handleChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isChangingContractDocumentStatus}
          variant="text"
          // size="small"
          onClick={onClose}
        >
          Indietro
        </Button>
        <Button
          disabled={isChangingContractDocumentStatus}
          variant="text"
          color="secondary"
          // size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          Rifiuta file
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefuseDocumentForm
