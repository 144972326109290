import React from 'react'

import { Paper, makeStyles, Typography } from '@material-ui/core'

import { getSettings_getSettings as SettingsType } from 'generated/schemaTypes'
import { ButtonModify } from 'components'

interface SettingsDetailProps {
  settings: SettingsType
  onUpdateSettings: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4),
    position: 'relative'
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const SettingsDetail: React.FC<SettingsDetailProps> = ({
  settings,
  // operator,
  onUpdateSettings
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h1" style={{ marginBottom: '1em' }}>
        Settings
      </Typography>

      <div className={classes.ctaWrapper}>
        <ButtonModify onClick={onUpdateSettings} />
      </div>

      <div className={classes.data}>
        <Typography variant="h4">Email scoring Rent2Go</Typography>
        <Typography variant="h5">
          {settings.emailScoringR2G || 'non definita'}
        </Typography>
        <Typography variant="h4">Email firma digitale Rent2Go</Typography>
        <Typography variant="h5">
          {settings.emailDigitalSignR2G || 'non definita'}
        </Typography>
        <Typography variant="h4">IVA</Typography>
        <Typography variant="h5">
          {settings.vat ? `${settings.vat}%` : '22% (default)'}
        </Typography>
      </div>
    </Paper>
  )
}

export default SettingsDetail
