import { InMemoryCache, makeVar } from '@apollo/client'
import { ClientOrderByInput } from 'generated/schemaTypes'
export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem('token'))
export const currentDealerVar = makeVar<string>(
  localStorage.getItem('currentDealer') || ''
)
export const filterProvinceVar = makeVar<string[]>([])
export const filterOwnerVar = makeVar<string>('')
export const filterContractStatusVar = makeVar<string>('ALL')
export const filterCarWithProposalsVar = makeVar<boolean>(true)
export const filterCarWithoutProposalsVar = makeVar<boolean>(true)
export const filterCarIncludeSoldVar = makeVar<boolean>(false)
export const filterCarIncludeExpiredVar = makeVar<boolean>(false)
export const orderCarVar = makeVar<string>('CREATION_DESC')
export const orderContractVar = makeVar<string>('CREATION_DESC')
export const orderDealerVar = makeVar<string>('DISPLAYNAME_ASC')
export const orderOperatorVar = makeVar<string>('LASTNAME_ASC')
export const orderClientVar = makeVar<ClientOrderByInput>(
  ClientOrderByInput.NAME_ASC
)
export const searchCarVar = makeVar<string>('')
export const searchContractVar = makeVar<string>('')
export const searchDealerVar = makeVar<string>('')
export const searchOperatorVar = makeVar<string>('')
export const searchClientVar = makeVar<string>('')
export const roleVar = makeVar<string>(localStorage.getItem('role') || 'DEALER')
export const userIdVar = makeVar<string | null>(
  localStorage.getItem('userId') || null
)

export const cache = new InMemoryCache({
  possibleTypes: {
    Profile: ['Dealer', 'Operator'],
    User: ['Dealer', 'Operator'],
    Client: ['ClientPrivate', 'ClientCompany'],
    ClientCommon: ['ClientPrivate', 'ClientCompany']
  },
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar()
        },
        currentDealerVar() {
          return currentDealerVar()
        },
        filterProvinceVar() {
          return filterProvinceVar()
        },
        filterOwnerVar() {
          return filterOwnerVar()
        },
        filterContractStatusVar() {
          return filterContractStatusVar()
        },
        filterCarWithProposalsVar() {
          return filterCarWithProposalsVar()
        },
        filterCarWithoutProposalsVar() {
          return filterCarWithoutProposalsVar()
        },
        filterCarIncludeSoldVar() {
          return filterCarIncludeSoldVar()
        },
        filterCarIncludeExpiredVar() {
          return filterCarIncludeExpiredVar()
        },
        orderCarVar() {
          return orderCarVar()
        },
        orderContractVar() {
          return orderContractVar()
        },
        orderDealerVar() {
          return orderDealerVar()
        },
        orderOperatorVar() {
          return orderOperatorVar()
        },
        orderClientVar() {
          return orderClientVar()
        },
        searchCarVar() {
          return searchCarVar()
        },
        searchContractVar() {
          return searchContractVar()
        },
        searchDealerVar() {
          return searchDealerVar()
        },
        searchOperatorVar() {
          return searchOperatorVar()
        },
        searchClientVar() {
          return searchClientVar()
        },
        roleVar() {
          return roleVar()
        },
        userIdVar() {
          return userIdVar()
        }
      }
      // getCars: {
      //   keyArgs: false,
      //   merge(existing = { cars: [] }, incoming) {
      //     const { cars: newCars } = incoming
      //     const { cars: oldCars } = existing
      //     return {
      //       ...incoming,
      //       cars: [...oldCars, ...newCars]
      //     }
      //   }
      // }
    }
  }
})
