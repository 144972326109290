import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_CONTRACT, GET_LISTS } from 'query'
import { Typography, makeStyles } from '@material-ui/core'
import { Back, ContractDetail } from 'components'

import {
  getContract,
  getContractVariables,
  getContract_getContract as ContractData,
  getLists,
  getList_getList as ListsData
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  }
}))

export default function Client() {
  const classes = useStyles()
  const { id } = useParams()

  const {
    data: contractData,
    loading: contractLoading,
    error: contractError,
    startPolling,
    stopPolling
  } = useQuery<getContract, getContractVariables>(GET_CONTRACT, {
    // fetchPolicy: 'cache-and-network',
    // pollInterval: 60000,
    variables: {
      contractId: id
    }
  })

  useEffect(() => {
    startPolling(60000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  const {
    data: dataLists,
    loading: loadingLists
    // error: errorLists
  } = useQuery<getLists>(GET_LISTS)

  if (contractError) return <div>Error!</div>

  const contract = contractData?.getContract
  const lists = dataLists?.getLists

  return (
    <>
      {contractLoading || loadingLists ? (
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: '100px', height: 50 }}
        >
          Caricamento della pratica in corso...
        </Typography>
      ) : (
        <>
          <div className={classes.root}>
            <Back url="/contracts">Torna all'elenco delle pratiche</Back>
            <ContractDetail
              contract={contract as ContractData}
              lists={lists as ListsData[]}
              startPolling={() => startPolling(5000)}
              stopPolling={stopPolling}
            />
          </div>
        </>
      )}
    </>
  )
}
