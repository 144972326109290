// import { createMuiTheme } from '@material-ui/core/styles'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

// enum Color {
//   DarkGrey = '#505050',
//   MediumDarkGrey = '#828282',
//   LightGrey = '#E6E6E6',
//   MediumLightGrey = '#AAAAAA',
//   ExtraLightGrey = '#F0F0F0',
//   Background = '#F8F8FA',
//   BlueBright = '#23A7E0',
//   RedAlert = '#F03838',
//   BlueBright = '#004c97',
//   BlueLight = 'rgba(0,77,153,0.05)',
//   // TextDark=  '#464646',
//   TextDark = '#161616',
//   TextLight = '#8a8a8a',
//   Border = '#e5e5e5'
// }

declare module '@material-ui/core/styles/createMuiTheme' {
  interface ThemeOptions {
    toolbar?: {
      height?: React.CSSProperties['height']
    }
    appDrawer?: {
      width?: React.CSSProperties['width']
    }
    border: {
      color?: React.CSSProperties['color']
    }
  }
  interface Theme {
    toolbar: {
      height: React.CSSProperties['height']
    }
    appDrawer: {
      width: React.CSSProperties['width']
    }
    border: {
      color: React.CSSProperties['color']
    }
  }
}

export const color = {
  DarkGrey: '#505050',
  MediumDarkGrey: '#828282',
  LightGrey: '#E6E6E6',
  GreenGood: '#12AC87',
  MediumLightGrey: '#AAAAAA',
  ExtraLightGrey: '#F0F0F0',
  Background: '#F8F8FA',
  BlueBright: '#23A7E0',
  RedAlert: '#F03838',
  DesaturatedBlue: '#98BBC6',
  BlueLight: 'rgba(0,77,153,0.05)',
  // TextDark: '#464646',
  TextDark: '#161616',
  TextLight: '#8a8a8a',
  Border: '#e5e5e5'
}

export const TOOLBAR_H = 80

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: color['BlueBright'],
      light: color['BlueLight']
    },
    secondary: {
      main: color['RedAlert']
    },
    error: {
      main: color['RedAlert']
    },
    background: {
      default: color['Background']
    },
    text: {
      primary: color['DarkGrey']
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1280,
      lg: 1366,
      xl: 1400
    }
  },
  toolbar: {
    height: TOOLBAR_H
  },
  appDrawer: {
    width: 200
  },
  mixins: {
    toolbar: {
      minHeight: TOOLBAR_H
    }
  },
  border: {
    color: color['Border']
  },
  typography: {
    fontFamily: ['"Open Sans"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ','
    ),
    h1: {
      fontWeight: 800,
      fontSize: 27,
      letterSpacing: '-0.065em',
      '@media (min-width:1400px)': {
        fontSize: 32
      }
    },
    h2: {
      fontSize: 18,
      fontWeight: 700,
      // marginBottom: 16,
      '@media (min-width:1400px)': {
        fontSize: 24
      }
    },
    h3: {
      fontSize: 18,
      fontWeight: 400,
      '@media (min-width:1400px)': {
        fontSize: 24
      }
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      '@media (min-width:1400px)': {
        fontSize: 18
      }
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      // marginBottom: '0.5em',
      '@media (min-width:1400px)': {
        fontSize: 18
      }
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
      '@media (min-width:1400px)': {
        fontSize: 15
      }
    },
    body1: {
      fontSize: 13,
      color: color['DarkGrey'],
      fontWeight: 400,
      '@media (min-width:1400px)': {
        fontSize: 14
      }
    },
    subtitle1: {
      fontSize: 10,
      color: color.MediumDarkGrey,
      fontWeight: 400
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        // backgroundColor: 'white',
        fontSize: 15,
        borderRadius: 16,
        '@media (min-width:1400px)': {
          fontSize: 18
        }
      },
      marginDense: {
        fontSize: 14,
        minHeight: 27,
        input: {
          minWidth: 20
        }
      },
      input: {
        // height: '1.5886em',
        minWidth: 220,
        // lineHeight: '1.5886rem',
        color: color['DarkGrey'],
        '&::placeholder': {
          color: color['MediumLightGrey'],
          // color: 'yellow',
          opacity: 1
          // fontSize: 18
        }
        // '@media (min-width:1400px)': {
        //   minWidth: 262
        // }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        '@media (min-width:1400px)': {
          fontSize: 18
        }
      },
      outlined: {
        color: color['MediumLightGrey']
        // fontSize: 18
      },
      marginDense: {
        fontSize: 14
      }
    },

    MuiPaper: {
      rounded: {
        borderRadius: 16
      }
    },
    MuiIconButton: {
      sizeSmall: {
        padding: 0,
        height: 40,
        width: 40
      }
      // colorPrimary: {
      //   background: color.BlueBright,
      //   color: '#fff'
      // }
      // colorPrimary: {
      //   background: color.BlueBright,
      //   color: '#fff'
      // }
    },
    MuiButton: {
      label: {
        // fontSize: 14,
        // '@media (min-width:1400px)': {
        // fontSize: 18
        // }
      },
      text: {
        fontSize: 14,
        fontWeight: 700,
        color: color.DarkGrey,
        '@media (min-width:1400px)': {
          fontSize: 18
        }
      },
      textSizeLarge: {},
      textSizeSmall: {
        fontWeight: 700,
        fontSize: 14
      },
      textPrimary: {
        color: color.BlueBright
      },
      textSecondary: {
        color: color.RedAlert,
        textDecoration: 'none'
      },
      root: {
        borderRadius: 8,
        textTransform: 'none',
        // minWidth: 115,
        fontWeight: 700,
        padding: '5px 16px',
        fontSize: 15,
        '@media (min-width:1400px)': {
          fontSize: 18
        }
      },
      sizeLarge: {
        fontSize: 20,
        fontWeight: 700,
        padding: '7px 22px',
        '@media (min-width:1400px)': {
          fontSize: 24
        }
      },
      containedPrimary: {
        backgroundColor: color['BlueBright'],
        color: 'white',
        // border: 'solid 1px white',
        '&:hover': {
          //backgroundColor: 'white',
          //color: color['BlueBright'],
          //borderColor: color['BlueBright']
        },
        '&:disabled': {
          backgroundColor: color.LightGrey,
          color: color.MediumLightGrey,
          fontWeight: 400
        }
      },
      containedSecondary: {
        backgroundColor: color.RedAlert,
        color: 'white'
        // border: 'solid 1px white',
        // '&:hover': {
        //   backgroundColor: color['BlueLight'],
        //   color: 'white'
        // }
      },
      contained: {
        fontWeight: 700
      },
      containedSizeLarge: {
        fontSize: 14,
        fontWeight: 400
      },
      containedSizeSmall: {
        fontSize: 10,
        fontWeight: 400,
        padding: '0px 8px',
        borderRadius: 4
      },
      outlinedSizeSmall: {
        fontSize: 14,
        padding: '1px 8px',
        minWidth: 90,
        color: color['BlueBright'],
        height: 26,
        lineHeight: '1em'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8
      },
      notchedOutline: {
        borderColor: color['MediumDarkGrey']
      },
      input: {
        padding: 17
      },
      colorSecondary: {
        background: color['LightGrey'],
        '& fieldset': {
          borderColor: color['LightGrey']
          // borderColor: 'yellow'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: color['MediumDarkGrey']
        },
        '& input::placeholder': {
          color: color['MediumDarkGrey']
        }
      },
      adornedStart: {
        paddingLeft: 0
      }
    },

    MuiInputAdornment: {
      positionStart: {
        '& .MuiSvgIcon-root': {
          color: color['DarkGrey'],
          marginLeft: 10
        }
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'none'
      },
      deleteIcon: {
        backgroundColor: '#FFE4E4',
        fill: color['RedAlert'],
        width: 16,
        height: 16,
        borderRadius: '50%',
        position: 'relative',
        top: -10,
        left: -5
      },
      label: {
        display: 'none'
      }
    },
    // MuiSelect: {
    // selectMenu: {
    // height: '1.5886em',
    // lineHeight: '1.5886em'
    // },
    // select: {},
    // icon: {
    // top: 'calc(50% - 9px)'
    // }
    // },
    // MuiBadge: {
    //   badge: {
    //     width: 14,
    //     height: 14,
    //     fontSize: '0.65rem',
    //     padding: 0,
    //     minWidth: 0
    //   }
    // },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.75em'
      }
    },
    MuiSnackbarContent: {
      root: {
        padding: '3px 16px',
        borderRadius: 8
      }
    },
    MuiFormLabel: {
      colorSecondary: {
        color: `${color.MediumDarkGrey} !important`,
        '&.Mui-focused': {
          color: color.MediumDarkGrey
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      },
      body: {
        fontSize: 14,
        fontWeight: 400,
        // marginBottom: '0.5em',
        '@media (min-width:1400px)': {
          fontSize: 18
        }
      },
      head: {
        fontSize: 14,
        fontWeight: 700,
        // marginBottom: '0.5em',
        '@media (min-width:1400px)': {
          fontSize: 18
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          marginBottom: 0,
          color: color['DarkGrey'],
          letterSpacing: '-0.05em'
        },
        li: {
          listStyle: 'none'
        },
        ul: {
          padding: 0
        },
        '.placeholder': {
          color: color['TextLight']
        },
        '.lightgray': {
          color: color['TextLight']
        },
        // '.MuiAutocomplete-popper': {
        //   borderRadius: 0,
        //   backgroundColor: 'red'
        // },
        // '.MuiAutocomplete-listbox': {
        //   borderRadius: 0,
        //   backgroundColor: 'yellow'
        // },
        // '.MuiAutocomplete-paper': {
        //   borderRadius: 0,
        //   backgroundColor: 'yellow'
        // },
        // '.MuiAutocomplete-groupUl': {
        //   borderRadius: 0,
        //   backgroundColor: 'green'
        // },
        // '.MuiAutocomplete': {
        //   backgroundColor: 'red'
        // },
        '.MuiAutocomplete-option': {
          //padding: 0,
          //position: 'relative'
          // '&::after': {
          //   content: '""',
          //   display: 'block',
          //   height: 1,
          //   position: 'absolute',
          //   bottom: 0,
          //   lwft: 16,
          //   width: `calc(100% - 32px)`,
          //   backgroundColor: color['TextLight']
          // }
        },
        '.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
          padding: '8px 5px !important'
        },
        '.Mui-error input::placeholder': {
          color: color['RedAlert']
        },
        '.MuiSnackbarContent-root.alert--success': {
          backgroundColor: color['DarkGrey']
        },
        '.MuiSnackbarContent-root.alert--warning': {
          backgroundColor: color['DarkGrey']
        },
        '.MuiSnackbarContent-root.alert--error': {
          backgroundColor: color['DarkGrey']
        },
        '.MuiSnackbarContent-root.alert--info': {
          backgroundColor: color['DarkGrey']
        },
        '.MuiInputLabel-marginDense ~ div > input': {
          minHeight: 28
        }
      }
    },
    // MuiTextField: {},
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        fontSize: 10,
        color: color['TextDark'],
        boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)'
      },
      arrow: {
        color: 'white'
      }
    }
  }
})

export default theme
