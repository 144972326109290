import React, { useRef, useState } from 'react'

import {
  Button,
  CircularProgress,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useApolloClient, useMutation } from '@apollo/client'
import { GET_FILE_URL_CAR, GET_CAR } from 'query'
import { UPDATE_CAR } from 'mutation'
import { getCar as getCarType } from 'generated/schemaTypes'
import { MessageDialog } from 'components'

const useStyles = makeStyles(theme => ({
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '50%',
    backgroundPosition: 'center'
  },
  button: {
    color: '#ffffff',
    borderColor: '#ffffff'
  },
  goToAutoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'rgba(80,80,80,0.8)',
    opacity: 0,
    transition: 'opacity 0.25s ease',
    '&:hover': {
      opacity: 1
    }
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'rgba(80,80,80,0.8)'
  },
  goToAuto: {
    position: 'absolute',
    padding: '10px 15px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    minWidth: 220,
    borderRadius: theme.spacing(1),
    border: 'solid 2px white'
  },
  sold: {
    background: theme.palette.primary.main,
    padding: '0.5em',
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top right',
    transform: 'translate(-25%,0%) rotate(-45deg)',
    '& h3': {
      color: 'white'
    }
  }
}))

const CarThumbnail: React.FC<{
  src?: string | null
  carId: string
  dealerId: string
  sold?: boolean
}> = ({ src, carId, dealerId, sold = false }) => {
  const [showError, setShowError] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(
    'La dimensione del file non può superare i 4MB'
  )
  const inputEl = useRef<HTMLInputElement>(null)
  const classes = useStyles()
  const client = useApolloClient()
  const [updateImage] = useMutation(UPDATE_CAR, {
    update(
      cache,
      {
        data: {
          updateCar: { image, id }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CAR,
        variables: { id }
      }) as getCarType
      cache.writeQuery({
        query: GET_CAR,
        variables: { id },
        data: { getCar: { ...data.getCar, image } }
      })
    }
  })
  const onFileChange = () => {
    if (inputEl && inputEl.current) inputEl.current.click()
  }

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0 && files[0].size < 4 * 1024 * 1024) {
      if (files[0]) {
        try {
          const urlRequest = await client.query({
            query: GET_FILE_URL_CAR,
            variables: { dealerId, carId, filename: `${files[0].name}` }
          })
          const signedUrl = urlRequest.data.getSignedUrlForCar
          const imageUrl = signedUrl.split('?')[0]
          setIsUploading(true)
          const response = await fetch(signedUrl, {
            method: 'PUT',
            body: files[0]
          })
          setIsUploading(false)
          if (response.ok) {
            updateImage({ variables: { id: carId, data: { image: imageUrl } } })
          }
        } catch (e) {
          console.log('Errore', e)
          setError('Si è verificato un errore')
          setIsUploading(false)
          setShowError(true)
        }
      }
    } else {
      setError('La dimensione del file non può superare i 2MB')
      setShowError(true)
    }
  }

  return (
    <div
      className={classes.imageWrapper}
      style={{
        backgroundImage: `url(${src || '/car.png'})`
      }}
    >
      {sold && (
        <div className={classes.sold}>
          <Typography color="inherit" variant="h3">
            Venduta
          </Typography>
        </div>
      )}

      {isUploading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        !sold && (
          <div className={classes.goToAutoWrapper}>
            <Button
              onClick={onFileChange}
              variant="outlined"
              className={classes.button}
            >
              {src ? 'Modifica immagine' : 'Carica immagine'}
            </Button>
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              ref={inputEl}
              onChange={uploadImage}
            />
          </div>
        )
      )}
      <MessageDialog
        open={showError}
        title="Attenzione"
        onClose={() => setShowError(false)}
      >
        {error}
      </MessageDialog>
    </div>
  )
}

export default CarThumbnail
