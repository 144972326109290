import React from 'react'

import { Button, Paper, makeStyles, Typography } from '@material-ui/core'

import { getOperator_getOperator as OperatorData } from 'generated/schemaTypes'
import { ButtonModify } from 'components'

interface OperatorProfileProps {
  operator: OperatorData
  onUpdateOperator: () => void
  onChangePassword: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4),
    position: 'relative'
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const OperatorDetail: React.FC<OperatorProfileProps> = ({
  operator,
  onUpdateOperator,
  onChangePassword
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h1" style={{ marginBottom: '1em' }}>
        I miei dati
      </Typography>

      <div className={classes.ctaWrapper}>
        <ButtonModify onClick={onUpdateOperator} />
      </div>

      <div className={classes.data}>
        <Typography variant="h4">Nome</Typography>
        <Typography variant="h5">{operator.name}</Typography>
        <Typography variant="h4">Cognome</Typography>
        <Typography variant="h5">{operator.lastname}</Typography>
        <Typography variant="h4">Email</Typography>
        <Typography variant="h5">{operator.email}</Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ width: 300 }}
          onClick={onChangePassword}
        >
          Modifica password
        </Button>
      </div>
    </Paper>
  )
}

export default OperatorDetail
