import React from 'react'
import { LoginForm } from '../components'

export default function Login() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        backgroundImage: 'url("login-bg.png")',
        backgroundPosition: 'cover'
      }}
    >
      <LoginForm />
    </div>
  )
}
