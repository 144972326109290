import React from 'react'

import {
  Backdrop,
  CircularProgress,
  Theme,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const FullscreenLoader: React.FC = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default FullscreenLoader
