import React from 'react'

import { makeStyles, Typography, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  patch: {
    width: 40,
    height: 40,
    display: 'inline-block'
  }
}))

export default function Library() {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h1">Typography h1</Typography>
      <Typography variant="h2">Typography h2</Typography>
      <Typography variant="h3">Typography h3</Typography>
      <Typography variant="h4">Typography h4</Typography>
      <Typography variant="h5">Typography h5</Typography>
      <Typography variant="h6">Typography h6</Typography>

      <Typography variant="h1">Text Buttons</Typography>
      <Button size="small">Default small</Button>
      <Button size="small" color="primary">
        Primary small
      </Button>
      <Button size="small" color="secondary">
        Secondary small
      </Button>
      <Button>Default medium (14/18)</Button>
      <Button color="primary">Primary medium (14/18)</Button>
      <Button color="secondary">Secondary medium (14/18)</Button>
      <Button size="large">Default large</Button>
      <Button size="large" color="primary">
        Primary large
      </Button>
      <Button size="large" color="secondary">
        Secondary large
      </Button>
      <Typography variant="h1">Contained</Typography>
      <Button variant="contained" size="small">
        Default small
      </Button>
      <Button variant="contained" size="small" color="primary">
        Primary small
      </Button>
      <Button variant="contained" size="small" color="secondary">
        Secondary small
      </Button>
      <Button variant="contained">Default medium</Button>
      <Button variant="contained" color="primary">
        Primary medium
      </Button>
      <Button variant="contained" color="secondary">
        Secondary medium
      </Button>
      <Button variant="contained" size="large">
        Default large
      </Button>
      <Button variant="contained" size="large" color="primary">
        Primary large
      </Button>
      <Button variant="contained" size="large" color="secondary">
        Secondary large
      </Button>
      <div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#F0B782' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#F0829C' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#8294F0' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#63DF94' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#FFE100' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#0033cc' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#cc99cc' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#cc3333' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#CCFCCB' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#FFA500' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#1E90FF' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#FFD700' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#00CED1' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#FF00FF' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#DC143C' }}
        ></div>
        <div
          className={classes.patch}
          style={{ backgroundColor: '#FF4500' }}
        ></div>
      </div>
    </>
  )
}
