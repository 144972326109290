import React from 'react'

import { differenceInDays } from 'date-fns'
import { color } from 'theme'

const Deadline: React.FC<{ registrationDate: Date }> = ({
  registrationDate
}) => {
  const delta = 365 - differenceInDays(new Date(), registrationDate)
  return (
    <div style={{ color: delta < 30 ? color.RedAlert : color.GreenGood }}>
      {delta > 0
        ? `L'offerta scade tra ${delta} giorni`
        : delta === 0
        ? "L'offerta scade oggi"
        : "L'offerta è scaduta"}
    </div>
  )
}

export default Deadline
