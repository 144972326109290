import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { ButtonModify } from 'components'
// interface StyleProps {
//   isSidebarOpen: boolean
// }

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover > div': {
      display: 'block'
    },
    marginBottom: 30
  },
  buttonWrapper: {
    position: 'absolute',
    display: 'none',
    transform: 'translate(-100%,-50%)',
    top: '50%'
  }
}))

interface ListItemProps {
  onUpdate: () => void
  label: string
  description?: string
}

const ListItem: React.FC<ListItemProps> = ({
  onUpdate,
  label,
  description = ''
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.buttonWrapper}>
        <ButtonModify onClick={onUpdate} />
      </div>
      <Typography variant="h5" display="block">
        {label}
      </Typography>
      <Typography variant="subtitle1" display="block">
        {description}
      </Typography>
    </div>
  )
}

export default ListItem
