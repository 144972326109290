import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Button, Link, Paper, makeStyles, Typography } from '@material-ui/core'
import { LOGIN } from 'mutation'
import { decode } from 'jsonwebtoken'
import { CustomInputComponent } from 'components'
import {
  currentDealerVar,
  filterCarIncludeExpiredVar,
  filterCarIncludeSoldVar,
  filterCarWithoutProposalsVar,
  filterCarWithProposalsVar,
  filterContractStatusVar,
  filterOwnerVar,
  isLoggedInVar,
  orderCarVar,
  orderClientVar,
  orderContractVar,
  orderDealerVar,
  orderOperatorVar,
  roleVar,
  searchCarVar,
  searchClientVar,
  searchContractVar,
  searchDealerVar,
  searchOperatorVar,
  userIdVar
} from 'cache'
import { ClientOrderByInput } from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  paper: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // backgroundColor: 'rgba(255,255,255,0.1)',
    // borderRadius: 15,
    margin: '0 auto',
    width: 560,
    padding: '50px 76px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  successText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center'
  },
  submit: {
    margin: theme.spacing(3, 0)
  },
  text: {
    color: 'black',
    textAlign: 'left',
    width: '100%',
    margin: 0
  },
  invalidEmail: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textAlign: 'left',
    padding: theme.spacing(1)
  }
  // input: {
  //   '& input': {
  //     fontSize: 17,
  //     padding: 19
  //   }
  // }
}))

interface LoginFormValues {
  email: string
  password: string
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message
}

const LoginForm: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Indirizzo email non valido')
        .required('Campo obbligatorio'),
      password: Yup.string().required('Campo obbligatorio')
    }),
    onSubmit: async values => {
      await login({
        variables: { email: values.email, password: values.password }
      })
    }
  })
  const [login, { error: loginError, loading: isLoggingIn }] = useMutation(
    LOGIN,
    {
      onCompleted: data => {
        const token = data.login.token
        localStorage.setItem('token', token)
        const { userId, role } = decode(token) as {
          userId: string
          role: string
        }
        localStorage.setItem('role', role)
        localStorage.setItem('userId', userId)
        const dealersFilter = role === 'DEALER' ? [userId] : []
        localStorage.setItem('dealersFilter', JSON.stringify(dealersFilter))
        if (role === 'DEALER') {
          localStorage.setItem('currentDealer', userId)
        }
        roleVar(role)
        currentDealerVar(role === 'DEALER' ? userId : '')
        isLoggedInVar(true)
        filterOwnerVar('')
        filterContractStatusVar('ALL')
        filterCarWithProposalsVar(true)
        filterCarWithoutProposalsVar(true)
        filterCarIncludeExpiredVar(false)
        filterCarIncludeSoldVar(false)
        orderCarVar('CREATION_DESC')
        orderContractVar('CREATION_DESC')
        orderDealerVar('DISPLAYNAME_ASC')
        orderOperatorVar('LASTNAME_ASC')
        orderClientVar(ClientOrderByInput.NAME_ASC)
        searchCarVar('')
        searchClientVar('')
        searchDealerVar('')
        searchOperatorVar('')
        searchContractVar('')
        userIdVar(userId)
        history.push(`/cars`)
      },
      onError: err => {
        formik.resetForm()
      }
    }
  )

  return (
    <Paper className={classes.paper}>
      <>
        <Typography variant="h1" style={{ marginBottom: 24 }}>
          Ciao!
        </Typography>
        <Typography variant="h3" style={{ marginBottom: 32 }}>
          Benvenuto nella piattaforma Noloplan2X. Ti auguro buona giornata e
          buon lavoro :)
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          <CustomInputComponent
            name="email"
            error={formik.touched.email && !!formik.errors.email}
            id="email"
            label="Email"
            value={formik.values.email}
            errorLabel={formik.errors.email}
            onChange={formik.handleChange}
            disabled={isLoggingIn}
          />

          <CustomInputComponent
            name="password"
            error={formik.touched.password && !!formik.errors.password}
            id="password"
            label="Password"
            type="password"
            value={formik.values.password}
            errorLabel={formik.errors.password}
            onChange={formik.handleChange}
            disabled={isLoggingIn}
          />

          {loginError && (
            <p className={classes.invalidEmail}>{formatError(loginError)}</p>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            disabled={isLoggingIn}
            className={classes.submit}
          >
            Entra
          </Button>
          <Typography variant="h5" style={{ marginBottom: 32 }}>
            Hai dimenticato la password?
          </Typography>
          <Link href="/requestToken">Recupera passowrd</Link>
        </form>
      </>
    </Paper>
  )
}

export default LoginForm
