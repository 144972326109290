import React, { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { GET_CLIENTS } from 'query'
import {
  Button,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'

import {
  getClients,
  getClients_getClients_clients,
  getClientsVariables,
  ClientOrderByInput
} from 'generated/schemaTypes'

import {
  Avatar,
  ClientPrivateForm,
  ClientCompanyForm,
  NoElements,
  OrderSelect,
  SearchFilter
} from 'components'
import { currentDealerVar, orderClientVar, searchClientVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  row1: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%'
  },
  row2: {
    paddingTop: 100,
    overflow: 'auto',
    height: `calc(100% + ${theme.spacing(4)}px)`
  },
  createClient: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '& .MuiSpeedDial-fab': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

export default function Clients() {
  const classes = useStyles()
  const history = useHistory()
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [addClientOpen, setAddClientOpen] = useState(false)
  const [showClientForm, setShowClientForm] = useState(false)
  const [formType, setFormType] = useState<'private' | 'company'>('private')
  const currentDealerCache = useReactiveVar(currentDealerVar)
  const orderClientCache = useReactiveVar(orderClientVar)
  const searchClientCache = useReactiveVar(searchClientVar)
  const where: any = {}
  if (currentDealerCache) where.dealerIds = [currentDealerCache]
  if (searchClientCache.length > 3) {
    where.searchPattern = searchClientCache.toLowerCase()
  }
  const filters = searchClientCache.length > 3

  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError,
    fetchMore,
    startPolling,
    stopPolling
  } = useQuery<getClients, getClientsVariables>(GET_CLIENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderBy: orderClientCache
    },
    // pollInterval: 30000,
    onCompleted: ({ getClients }) => {
      setShowLoadMore(getClients.clients.length === 10)
    }
  })

  useEffect(() => {
    startPolling(60000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  const handleCloseAdd = () => {
    setAddClientOpen(false)
  }

  const handleOpenAdd = () => {
    setAddClientOpen(true)
  }

  const clients = clientsData ? clientsData.getClients.clients : []
  const cursor = clientsData ? clientsData.getClients.cursor : null

  if (clientsError) return <div>Error!</div>

  if (clients.length === 0 && !filters && !clientsLoading)
    return (
      <>
        <NoElements>
          <Typography color="primary" variant="h1" align="center">
            Non ci sono clienti qui,
            <br />
            inserisci il primo cliente
          </Typography>
        </NoElements>
        <div>
          <SpeedDial
            ariaLabel="Add client"
            color="primary"
            style={{ fill: 'white' }}
            className={classes.createClient}
            hidden={!currentDealerCache}
            icon={<SpeedDialIcon style={{ color: 'white' }} />}
            onClose={handleCloseAdd}
            onOpen={handleOpenAdd}
            open={addClientOpen}
            direction="up"
          >
            <SpeedDialAction
              icon={<BusinessRoundedIcon />}
              tooltipTitle="Azienda"
              tooltipOpen
              onClick={() => {
                setFormType('company')
                setShowClientForm(true)
              }}
            />
            <SpeedDialAction
              icon={<PersonRoundedIcon />}
              tooltipTitle="Privato"
              tooltipOpen
              onClick={() => {
                setFormType('private')
                setShowClientForm(true)
              }}
            />
          </SpeedDial>
        </div>
        <ClientPrivateForm
          open={showClientForm && formType === 'private'}
          client={null}
          dealerId={currentDealerCache}
          handleClose={() => {
            setShowClientForm(false)
          }}
        />
        <ClientCompanyForm
          open={showClientForm && formType === 'company'}
          client={null}
          dealerId={currentDealerCache}
          handleClose={() => {
            setShowClientForm(false)
          }}
        />
      </>
    )

  return (
    <>
      <div className={classes.root}>
        <div className={classes.row1}>
          <SearchFilter
            value={searchClientCache}
            label="Cerca un cliente"
            onChange={
              (val: string) => searchClientVar(val)
              // client.writeData({ data: { searchClient: val } })
            }
          />

          <OrderSelect
            name="orderBy"
            type="select"
            label="Ordina per"
            id="orderBy"
            value={orderClientCache}
            onChange={e => {
              orderClientVar(e.target.value as ClientOrderByInput | undefined)
              // client.writeData({ data: { orderClient: e.target.value } })
            }}
          >
            <MenuItem value="NAME_ASC">Nome ↑</MenuItem>
            <MenuItem value="NAME_DESC">Nome ↓</MenuItem>
          </OrderSelect>
        </div>
        <div className={classes.row2}>
          {clientsLoading ? (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Caricamento dei clienti in corso...
            </Typography>
          ) : clients.length > 0 ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Nome / Denominazione</TableCell>
                      <TableCell align="left">Tipo</TableCell>
                      <TableCell align="left">C.F. / P.IVA</TableCell>
                      <TableCell align="left">Cellulare</TableCell>
                      <TableCell align="left">Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clients.map((client: getClients_getClients_clients) => {
                      return (
                        <TableRow
                          style={{ cursor: 'pointer' }}
                          key={client.id}
                          hover
                          onClick={event => {
                            history.push(`/clients/${client.id}`)
                          }}
                        >
                          <TableCell align="left">
                            <IconButton
                            // onClick={event => {
                            //   history.push(`/clients/${client.id}`)
                            // }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="left">
                            <Avatar
                              label={
                                client.__typename === 'ClientCompany'
                                  ? `${client.name[0]}`
                                  : `${client.name[0]}${client.lastname[0]}`
                              }
                              color={parseInt(client.id[0], 16)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {client.__typename === 'ClientCompany'
                              ? `${client.name}`
                              : `${client.name} ${client.lastname}`}
                          </TableCell>
                          <TableCell align="left">
                            {client.__typename === 'ClientCompany'
                              ? 'Azienda'
                              : 'Privato'}
                          </TableCell>
                          <TableCell align="left">
                            {client.cf} -{' '}
                            {client.__typename === 'ClientCompany'
                              ? client.vat
                              : ''}
                          </TableCell>
                          <TableCell align="left">{client.mobile}</TableCell>
                          <TableCell align="left">{client.email}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {showLoadMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '30px 0',
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      fetchMore({
                        query: GET_CLIENTS,
                        variables: {
                          cursor,
                          where,
                          orderBy: orderClientCache
                        },
                        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                          const newResults = fetchMoreResult as getClients
                          const prevResults = prev as getClients
                          if (
                            !newResults ||
                            (newResults &&
                              newResults.getClients.clients.length === 0)
                          ) {
                            setShowLoadMore(false)
                            return prev
                          }
                          if (newResults.getClients.clients.length < 10)
                            setShowLoadMore(false)
                          return {
                            ...newResults,
                            getClients: {
                              ...newResults.getClients,
                              clients: [
                                ...prevResults.getClients.clients,
                                ...newResults.getClients.clients
                              ]
                            }
                          }
                        }
                      })
                    }}
                  >
                    Carica altri clienti
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Non ci sono clienti che soddisfano i criteri di ricerca.
            </Typography>
          )}
        </div>
      </div>
      <div>
        <SpeedDial
          ariaLabel="Add client"
          color="primary"
          style={{ fill: 'white' }}
          className={classes.createClient}
          hidden={!currentDealerCache}
          icon={<SpeedDialIcon style={{ color: 'white' }} />}
          onClose={handleCloseAdd}
          onOpen={handleOpenAdd}
          open={addClientOpen}
          direction="up"
        >
          <SpeedDialAction
            icon={<BusinessRoundedIcon />}
            tooltipTitle="Azienda"
            tooltipOpen
            onClick={() => {
              setFormType('company')
              setShowClientForm(true)
            }}
          />
          <SpeedDialAction
            icon={<PersonRoundedIcon />}
            tooltipTitle="Privato"
            tooltipOpen
            onClick={() => {
              setFormType('private')
              setShowClientForm(true)
            }}
          />
        </SpeedDial>
      </div>
      <ClientPrivateForm
        open={showClientForm && formType === 'private'}
        client={null}
        dealerId={currentDealerCache}
        handleClose={() => {
          setShowClientForm(false)
        }}
      />
      <ClientCompanyForm
        open={showClientForm && formType === 'company'}
        client={null}
        dealerId={currentDealerCache}
        handleClose={() => {
          setShowClientForm(false)
        }}
      />
    </>
  )
}
