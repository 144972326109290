import React from 'react'

import NumberFormat from 'react-number-format'

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const PowerInputField: React.FC<NumberFormatCustomProps> = props => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      suffix="cv"
      isAllowed={values => {
        if (!values.floatValue) return true
        return values.floatValue < 1000 && values.floatValue > 0
      }}
    />
  )
}

export default PowerInputField
