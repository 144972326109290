import React, { useState, useEffect } from 'react'
import { useReactiveVar, useQuery } from '@apollo/client'
import { GET_CARS } from 'query'
import { Button, MenuItem, Typography, makeStyles } from '@material-ui/core'
import {
  getCars_getCars_cars,
  getCars,
  getCarsVariables,
  CarOrderByInput
} from 'generated/schemaTypes'

import {
  AddFab,
  CarForm,
  CarSummaryCard,
  ConfirmDialog,
  FilterButton,
  GlobalOwnershipFilter,
  OrderSelect,
  NoElements,
  ProposalForm,
  ProvinceFilter,
  SearchFilter
} from 'components'
import {
  currentDealerVar,
  filterCarIncludeExpiredVar,
  filterCarIncludeSoldVar,
  filterCarWithoutProposalsVar,
  filterCarWithProposalsVar,
  filterOwnerVar,
  filterProvinceVar,
  orderCarVar,
  roleVar,
  searchCarVar
} from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  row1: {
    position: 'absolute',
    width: '100%',
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between'
    },
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      marginBottom: 20
    }
  },
  row2: {
    paddingTop: 120,
    overflow: 'auto',
    height: `calc(100% + ${theme.spacing(4)}px)`
  },
  createCar: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(4)
  }
}))

export default function Cars() {
  const classes = useStyles()
  const currentDealerCache = useReactiveVar(currentDealerVar)
  const searchCarCache = useReactiveVar(searchCarVar)
  const filterProvinceCache = useReactiveVar(filterProvinceVar)
  const filterCarWithProposalsCache = useReactiveVar(filterCarWithProposalsVar)
  const filterCarWithoutProposalsCache = useReactiveVar(
    filterCarWithoutProposalsVar
  )
  const filterCarIncludeSoldCache = useReactiveVar(filterCarIncludeSoldVar)
  const filterCarIncludeExpiredCache = useReactiveVar(
    filterCarIncludeExpiredVar
  )
  const filterOwnerCache = useReactiveVar(filterOwnerVar)
  const orderCarCache = useReactiveVar(orderCarVar)
  const [useLocalStorage, setUseLocalStorage] = useState(false)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [showCarForm, setShowCarForm] = useState(false)
  const [showCarDraftMessage, setShowCarDraftMessage] = useState(false)
  const [currentCar, setCurrentCar] = useState<string | null>(null)
  const [showProposalForm, setShowProposalForm] = useState(false)
  const roleCache = useReactiveVar(roleVar)
  const where: any = {}
  if (currentDealerCache) where.dealerIds = [currentDealerCache]
  if (searchCarCache.length > 3) {
    where.searchPattern = searchCarCache.toLowerCase()
  }
  if (filterProvinceCache.length > 0)
    where.dealerProvinces = filterProvinceCache
  if (filterOwnerCache) where.owner = filterOwnerCache
  if (filterCarIncludeSoldCache) where.includeSold = filterCarIncludeSoldCache
  if (filterCarIncludeExpiredCache)
    where.includeExpired = filterCarIncludeExpiredCache
  if (filterCarWithoutProposalsCache && !filterCarWithProposalsCache) {
    where.withoutProposals = true
  }
  if (filterCarWithProposalsCache && !filterCarWithoutProposalsCache) {
    where.withProposals = true
  }

  const filters =
    searchCarCache.length > 3 ||
    filterProvinceCache.length > 0 ||
    filterOwnerCache ||
    filterCarIncludeSoldCache ||
    filterCarIncludeExpiredCache ||
    !filterCarWithProposalsCache ||
    !filterCarWithoutProposalsCache

  const {
    data: carsData,
    loading: carsLoading,
    error: carsError,
    fetchMore,
    stopPolling,
    startPolling
  } = useQuery<getCars, getCarsVariables>(GET_CARS, {
    fetchPolicy: 'network-only',
    // pollInterval: 10000,
    variables: {
      where,
      orderBy: orderCarCache as CarOrderByInput
    },
    onCompleted: ({ getCars }) => {
      setShowLoadMore(getCars.cars.length === 10)
    }
  })

  useEffect(() => {
    startPolling(60000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  const cars = carsData ? carsData.getCars.cars : []
  const cursor = carsData ? carsData.getCars.cursor : null

  if (carsError) return <div>Error!</div>

  if (cars.length === 0 && !filters && !carsLoading)
    return (
      <>
        <NoElements>
          <Typography color="primary" variant="h1" align="center">
            Non ci sono auto qui,
            <br />
            inserisci la prima automobile
          </Typography>
        </NoElements>
        {currentDealerCache && (
          <AddFab
            onClick={() => {
              if (localStorage.getItem('carDraft')) {
                setShowCarDraftMessage(true)
              } else {
                setShowCarForm(true)
              }
            }}
          />
        )}
        <CarForm
          open={showCarForm}
          dealerId={currentDealerCache}
          handleClose={() => {
            setShowCarForm(false)
          }}
          useLocalStorage={useLocalStorage}
        />
        <ConfirmDialog
          title="Attenzione"
          open={showCarDraftMessage}
          onConfirm={() => {
            setShowCarDraftMessage(false)
            setUseLocalStorage(true)
            setShowCarForm(true)
          }}
          onClose={() => {
            localStorage.removeItem('carDraft')
            setUseLocalStorage(false)
            setShowCarDraftMessage(false)
            setShowCarForm(true)
          }}
          ctaCancel="No"
          ctaConfirm="Sì"
        >
          È stata trovata un'auto in bozza, vuoi riprendere l'inserimento?
        </ConfirmDialog>
      </>
    )

  return (
    <>
      {/* <Button
        onClick={() => {
          console.log('STOP')
          stopPolling()
        }}
      >
        STOP
      </Button>
      <Button
        onClick={() => {
          console.log('START')
          startPolling(5000)
        }}
      >
        START
      </Button> */}

      <div className={classes.root}>
        <div className={classes.row1}>
          <div>
            <SearchFilter
              value={searchCarCache}
              label="Cerca un'automobile"
              onChange={
                (val: string) => searchCarVar(val)
                // client.writeData({ data: { searchCar: val } })
              }
            />
            {roleCache !== 'DEALER' && (
              <>
                <ProvinceFilter />
                <GlobalOwnershipFilter />
              </>
            )}
            <OrderSelect
              name="orderBy"
              type="select"
              label="Ordina per"
              id="orderBy"
              value={orderCarCache}
              onChange={e => {
                orderCarVar(e.target.value as string | undefined)
                // client.writeData({
                //   data: { orderCar: e.target.value }
                // })
              }}
            >
              <MenuItem value="CREATION_ASC">Data ↑</MenuItem>
              <MenuItem value="CREATION_DESC">Data ↓</MenuItem>
            </OrderSelect>
          </div>
          {/* </div> */}

          <div style={{ marginTop: 20 }}>
            <div style={{ display: 'flex' }}>
              <FilterButton
                active={filterCarWithProposalsCache}
                label="Con proposte"
                onClick={() => {
                  if (filterCarWithoutProposalsCache)
                    filterCarWithProposalsVar(!filterCarWithProposalsCache)
                  // client.writeData({
                  //   data: {
                  //     filterCarWithProposals: !dataFilters.filterCarWithProposals
                  //   }
                  // })
                }}
              />
              <FilterButton
                active={filterCarWithoutProposalsCache}
                label="Senza proposte"
                onClick={() => {
                  if (filterCarWithProposalsCache)
                    filterCarWithoutProposalsVar(
                      !filterCarWithoutProposalsCache
                    )
                  // client.writeData({
                  //   data: {
                  //     filterCarWithoutProposals: !dataFilters.filterCarWithoutProposals
                  //   }
                  // })
                }}
              />
              <div style={{ margin: '0 15px' }}></div>
              <FilterButton
                active={filterCarIncludeSoldCache}
                label="Includi vendute"
                onClick={() => {
                  filterCarIncludeSoldVar(!filterCarIncludeSoldCache)
                  // client.writeData({
                  //   data: {
                  //     filterCarIncludeSold: !dataFilters.filterCarIncludeSold
                  //   }
                  // })
                }}
              />
              <FilterButton
                active={filterCarIncludeExpiredCache}
                label="Includi scadute"
                onClick={() => {
                  filterCarIncludeExpiredVar(!filterCarIncludeExpiredCache)
                  // client.writeData({
                  //   data: {
                  //     filterCarIncludeExpired: !dataFilters.filterCarIncludeExpired
                  //   }
                  // })
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.row2}>
          {carsLoading ? (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Caricamento delle auto in corso...
            </Typography>
          ) : (
            <>
              <div>
                {cars.length === 0 && filters && (
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ marginTop: '100px', height: 50 }}
                  >
                    Non ci sono auto che soddisfano i criteri di ricerca.
                  </Typography>
                )}

                {cars.map((car: getCars_getCars_cars) => (
                  <CarSummaryCard
                    car={car}
                    key={car.id}
                    onCreateProposal={() => {
                      setCurrentCar(car.id)
                      setShowProposalForm(true)
                    }}
                  />
                ))}
              </div>
              {showLoadMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '30px 0',
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      fetchMore({
                        query: GET_CARS,
                        variables: {
                          cursor,
                          where,
                          orderBy: orderCarCache
                        },
                        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                          const newResults = fetchMoreResult as getCars
                          const prevResults = prev as getCars
                          if (
                            !newResults ||
                            (newResults && newResults.getCars.cars.length === 0)
                          ) {
                            setShowLoadMore(false)
                            return prev
                          }
                          if (newResults.getCars.cars.length < 10)
                            setShowLoadMore(false)
                          return {
                            ...newResults,
                            getCars: {
                              ...newResults.getCars,
                              cars: [
                                ...prevResults.getCars.cars,
                                ...newResults.getCars.cars
                              ]
                            }
                          }
                        }
                      })
                    }}
                  >
                    Carica altre auto
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CarForm
        open={showCarForm}
        dealerId={currentDealerCache}
        handleClose={() => {
          setShowCarForm(false)
        }}
        useLocalStorage={useLocalStorage}
      />
      <ProposalForm
        open={showProposalForm}
        carId={currentCar as string}
        handleClose={() => {
          setShowProposalForm(false)
        }}
      />
      {currentDealerCache && (
        <AddFab
          onClick={() => {
            if (localStorage.getItem('carDraft')) {
              setShowCarDraftMessage(true)
            } else {
              setShowCarForm(true)
            }
          }}
        />
      )}
      <ConfirmDialog
        title="Attenzione"
        open={showCarDraftMessage}
        onConfirm={() => {
          setShowCarDraftMessage(false)
          setUseLocalStorage(true)
          setShowCarForm(true)
        }}
        onClose={() => {
          localStorage.removeItem('carDraft')
          setUseLocalStorage(false)
          setShowCarDraftMessage(false)
          setShowCarForm(true)
        }}
        ctaCancel="No"
        ctaConfirm="Sì"
      >
        È stata trovata un'auto in bozza, vuoi riprendere l'inserimento?
      </ConfirmDialog>
    </>
  )
}
