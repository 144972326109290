import React, { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_DOCUMENTS } from 'query'
import { makeStyles, Typography } from '@material-ui/core'
import {
  getDocuments,
  getDocuments_getDocuments as DocumentType
} from 'generated/schemaTypes'
import { Redirect } from 'react-router-dom'
import { AddFab, ListItem, DocumentForm } from 'components'
import { roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  createCar: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(4)
  }
}))

export default function Cars() {
  const classes = useStyles()
  const roleCache = useReactiveVar(roleVar)
  const [showDocumentForm, setShowDocumentForm] = useState(false)
  const [currentDocument, setCurrentDocument] = useState<DocumentType | null>(
    null
  )

  const {
    data: dataDocuments,
    loading: loadingDocuments,
    error: errorDocuments
  } = useQuery<getDocuments>(GET_DOCUMENTS, {
    fetchPolicy: 'network-only'
  })

  const documents = dataDocuments ? dataDocuments.getDocuments : []

  if (errorDocuments) return <div>Error!</div>

  if (roleCache !== 'ADMIN') return <Redirect to={{ pathname: '/cars' }} />

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h2">Elenco documenti</Typography>
        {loadingDocuments && (
          <Typography
            variant="h4"
            align="center"
            style={{ marginTop: '100px', height: 50 }}
          >
            Caricamento dei documenti in corso...
          </Typography>
        )}
        {!loadingDocuments && !errorDocuments && (
          <>
            {documents.length === 0 && (
              <Typography
                variant="h4"
                align="center"
                style={{ marginTop: '100px', height: 50 }}
              >
                Non è ancora stato creato nessun documento.
              </Typography>
            )}
            <ul>
              {documents.map(doc => (
                <li key={doc.id}>
                  <ListItem
                    label={doc.label}
                    description={doc.description || ''}
                    onUpdate={() => {
                      const currentDocument = documents.find(
                        _doc => _doc.id === doc.id
                      )
                      setCurrentDocument(
                        currentDocument ? currentDocument : null
                      )
                      setShowDocumentForm(true)
                    }}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <AddFab
        onClick={() => {
          setShowDocumentForm(true)
        }}
      />
      <DocumentForm
        open={showDocumentForm}
        document={currentDocument}
        handleClose={() => {
          setShowDocumentForm(false)
          setCurrentDocument(null)
        }}
      />
    </>
  )
}
