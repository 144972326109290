import React, { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Redirect, useHistory } from 'react-router-dom'
import { GET_DEALERS } from 'query'
import {
  Button,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  getDealers,
  getDealers_getDealers_dealers,
  getDealersVariables,
  UserStatus,
  DealerOrderByInput
} from 'generated/schemaTypes'

import {
  AddFab,
  Avatar,
  DealerForm,
  NoElements,
  OrderSelect,
  SearchFilter
} from 'components'
import { orderDealerVar, roleVar, searchDealerVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  row1: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%'
  },
  row2: {
    paddingTop: 100,
    overflow: 'auto',
    height: `calc(100% + ${theme.spacing(4)}px)`
  }
}))

export default function Clients() {
  const classes = useStyles()
  const history = useHistory()
  const orderDealerCache = useReactiveVar(orderDealerVar)
  const roleCache = useReactiveVar(roleVar)
  const searchDealerCache = useReactiveVar(searchDealerVar)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [showDealerForm, setShowDealerForm] = useState(false)
  const where: any = {}

  if (searchDealerCache.length > 3) {
    where.displayNameContains = searchDealerCache.toLowerCase()
  }
  where.includeSuspended = true

  const filters = searchDealerCache.length > 3

  const {
    data: dealersData,
    loading: dealersLoading,
    error: dealersError,
    fetchMore
  } = useQuery<getDealers, getDealersVariables>(GET_DEALERS, {
    fetchPolicy: 'network-only',
    variables: {
      where,
      orderBy: orderDealerCache as DealerOrderByInput
    },
    onCompleted: ({ getDealers }) => {
      setShowLoadMore(getDealers.dealers.length === 10)
    }
  })

  if (roleCache === 'DEALER') return <Redirect to={{ pathname: '/cars' }} />

  const dealers = dealersData ? dealersData.getDealers.dealers : []
  const cursor = dealersData ? dealersData.getDealers.cursor : null

  if (dealersError) return <div>Error!</div>

  if (dealers.length === 0 && !filters && !dealersLoading)
    return (
      <>
        <NoElements>
          <Typography color="primary" variant="h1" align="center">
            Non ci sono concessionari qui,
            <br />
            inserisci il primo concessionari
          </Typography>
        </NoElements>
        <AddFab
          onClick={() => {
            setShowDealerForm(true)
          }}
        />
        <DealerForm
          open={showDealerForm}
          dealer={null}
          handleClose={() => {
            setShowDealerForm(false)
          }}
        />
      </>
    )

  return (
    <>
      <div className={classes.root}>
        <div className={classes.row1}>
          <SearchFilter
            value={searchDealerCache}
            label="Cerca un concessionario"
            onChange={
              (val: string) => searchDealerVar(val)
              // client.writeData({ data: { searchDealer: val } })
            }
          />

          <OrderSelect
            name="orderBy"
            type="select"
            label="Ordina per"
            id="orderBy"
            value={orderDealerCache}
            onChange={e => {
              orderDealerVar(e.target.value as string | undefined)
              // client.writeData({ data: { orderDealer: e.target.value } })
            }}
          >
            <MenuItem value="DISPLAYNAME_ASC">Nome ↑</MenuItem>
            <MenuItem value="DISPLAYNAME_DESC">Nome ↓</MenuItem>
          </OrderSelect>
        </div>
        <div className={classes.row2}>
          {dealersLoading ? (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Caricamento dei concessionari in corso...
            </Typography>
          ) : dealers.length > 0 ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Nome</TableCell>
                      <TableCell align="left">Stato</TableCell>
                      <TableCell align="left">C.F. / P.IVA</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Telefono</TableCell>
                      <TableCell align="left">Indirizzo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dealers.map((dealer: getDealers_getDealers_dealers) => {
                      return (
                        <TableRow
                          key={dealer.id}
                          hover
                          style={{ cursor: 'pointer' }}
                          onClick={event => {
                            history.push(`/dealers/${dealer.id}`)
                          }}
                        >
                          <TableCell align="left">
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="left">
                            <Avatar
                              label={dealer.displayName[0]}
                              color={parseInt(dealer.id[0], 16)}
                            />
                          </TableCell>
                          <TableCell align="left">{dealer.company}</TableCell>
                          <TableCell align="left">
                            {dealer.status === UserStatus.SUSPENDED
                              ? 'SOSPESO'
                              : 'ATTIVO'}
                          </TableCell>
                          <TableCell align="left">
                            {dealer.cf} / {dealer.pi}
                          </TableCell>
                          <TableCell align="left">{dealer.email}</TableCell>
                          <TableCell align="left">{dealer.phone}</TableCell>
                          <TableCell align="left">
                            {dealer.address.addressLine}, {dealer.address.zip},{' '}
                            {dealer.address.city} ({dealer.address.province})
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {showLoadMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '30px 0',
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      fetchMore({
                        query: GET_DEALERS,
                        variables: {
                          cursor,
                          where,
                          orderBy: orderDealerCache
                        },
                        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
                          const newResults = fetchMoreResult as getDealers
                          const prevResults = prev as getDealers
                          if (
                            !newResults ||
                            (newResults &&
                              newResults.getDealers.dealers.length === 0)
                          ) {
                            setShowLoadMore(false)
                            return prev
                          }
                          if (newResults.getDealers.dealers.length < 10)
                            setShowLoadMore(false)
                          return {
                            ...newResults,
                            getDealers: {
                              ...newResults.getDealers,
                              dealers: [
                                ...prevResults.getDealers.dealers,
                                ...newResults.getDealers.dealers
                              ]
                            }
                          }
                        }
                      })
                    }}
                  >
                    Carica altri concessionari
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '100px', height: 50 }}
            >
              Non ci sono concessionari che soddisfano i criteri di ricerca.
            </Typography>
          )}
        </div>
      </div>

      <AddFab
        onClick={() => {
          setShowDealerForm(true)
        }}
      />

      <DealerForm
        open={showDealerForm}
        dealer={null}
        handleClose={() => {
          setShowDealerForm(false)
        }}
      />
    </>
  )
}
