import React from 'react'
import { useLocation } from 'react-router-dom'
import { ResetPasswordForm } from '../components'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function ResetPassword() {
  const query = useQuery()
  const token = query.get('token')
  if (!token) return <div>Invalid token!</div>
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        backgroundImage: 'url("login-bg.png")',
        backgroundPosition: 'cover'
      }}
    >
      <ResetPasswordForm token={token} />
    </div>
  )
}
