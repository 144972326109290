import React from 'react'

import { Paper, makeStyles, Typography } from '@material-ui/core'
import {
  getOperator_getOperator as OperatorData,
  UserStatus
} from 'generated/schemaTypes'
import {
  ButtonModify,
  ButtonDelete,
  ButtonActivate,
  ButtonSuspend
} from 'components'

interface OperatorDetailProps {
  operator: OperatorData
  onUpdateOperator: () => void
  onDeleteOperator: () => void
  onActivateOperator?: () => void
  onSuspendOperator?: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4)
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    margin: '20px 0 40px'
  }
}))

const OperatorDetail: React.FC<OperatorDetailProps> = ({
  operator,
  onUpdateOperator,
  onDeleteOperator,
  onActivateOperator = () => {},
  onSuspendOperator = () => {}
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h1">
        {operator.name} {operator.lastname}
      </Typography>

      <div className={classes.ctaWrapper}>
        <ButtonModify label="Modifica operatore" onClick={onUpdateOperator} />
        <ButtonDelete label="Elimina operatore" onClick={onDeleteOperator} />
        {operator.status === UserStatus.ACTIVE ? (
          <>
            <ButtonSuspend
              label="Sospendi operatore"
              onClick={onSuspendOperator}
            />
          </>
        ) : (
          <ButtonActivate
            label="Attiva operatore"
            onClick={onActivateOperator}
          />
        )}
      </div>

      <div className={classes.data}>
        <Typography variant="h4">Nome</Typography>
        <Typography variant="h5">{operator.name}</Typography>
        <Typography variant="h4">Cognome</Typography>
        <Typography variant="h5">{operator.lastname}</Typography>
        <Typography variant="h4">Email</Typography>
        <Typography variant="h5">{operator.email}</Typography>
      </div>
    </Paper>
  )
}

export default OperatorDetail
