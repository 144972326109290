import React from 'react'

import { makeStyles } from '@material-ui/core'

import { Avatar } from 'components'
import { uuidTo16Index } from 'utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  }
}))

const AvatarWithLabel: React.FC<{
  id: string
  fullLabel: string
  smallLabel: string
}> = ({ id, fullLabel, smallLabel }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Avatar
        color={uuidTo16Index(id[0])}
        label={smallLabel}
        style={{ marginRight: 10 }}
      />
      &nbsp;
      <span>{fullLabel}</span>
    </div>
  )
}

export default AvatarWithLabel
