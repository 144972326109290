import React from 'react'

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import numeral from 'numeral'
import { getProposal_getProposal as ProposalType } from 'generated/schemaTypes'
import { PencilIcon, TrashIcon } from 'components'
import { ContentDuplicate } from 'mdi-material-ui'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  col: {
    alignContent: 'space-between',
    flexWrap: 'wrap',
    display: 'flex',
    width: '33.33%',
    '& > div': {
      width: '100%'
    }
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  col1: {},
  col2: {
    '& > div': {
      marginBottom: '1.25em'
    },
    alignContent: 'flex-start'
  },
  col3: {
    '& > div': {
      marginBottom: '1.25em'
    },
    alignContent: 'flex-start'
    // //width: 250
    // maxWidth: 250,
    // '& > div': {
    //   width: '100%'
    // }
  },
  infoItem: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-betwwen'
  }
}))

const ProposalCard: React.FC<{
  proposal: ProposalType
  onDelete: () => void
  onUpdate: () => void
  onCreatePrivateClient: () => void
  onCreateCompanyClient: () => void
  onCombine: () => void
  onDuplicate: () => void
  canDuplicate: boolean
  disableActions: boolean
  isOperator: boolean
  disableContract: boolean
}> = ({
  proposal,
  onCombine,
  onDuplicate,
  canDuplicate,
  onDelete,
  onUpdate,
  onCreatePrivateClient,
  onCreateCompanyClient,
  disableActions,
  disableContract,
  isOperator
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Paper className={classes.root}>
      <div className={clsx(classes.col1, classes.col)}>
        <div>
          <Typography variant="h1">
            {numeral(proposal.rent / 100).format('0,0')} €/m
          </Typography>
          <Typography variant="h5">{proposal.months} mesi</Typography>
          <Typography variant="h5">
            {numeral(proposal.km).format()} km annuali
          </Typography>
          <Typography variant="h5">
            ({numeral((proposal.km * proposal.months) / 12).format()} km totali)
          </Typography>
          <Typography variant="h5">
            Anticipo {numeral(proposal.deposit / 100).format('0,0.00')} €
          </Typography>
        </div>
        <div>
          <Button
            color="primary"
            size="medium"
            onClick={openMenu}
            disabled={disableContract}
          >
            Nuovo cliente
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem
              onClick={() => {
                closeMenu()
                onCreateCompanyClient()
              }}
            >
              Azienda
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu()
                onCreatePrivateClient()
              }}
            >
              Privato
            </MenuItem>
          </Menu>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            disabled={disableContract}
            onClick={onCombine}
          >
            Abbina
          </Button>
        </div>
      </div>
      <div className={clsx(classes.col2, classes.col)}>
        <div>
          <Typography variant="h5" display="inline">
            Auto sostitutiva:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.replacementCar ? 'SI' : 'NO'}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            Cristalli:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.windows ? 'SI' : 'NO'}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            Pneumatici invernali:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.winterTires * 4}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            Pneumatici estivi:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.summerTires * 4}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            Pneumatici all seasons:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.allSeasonTires * 4}
          </Typography>
        </div>
      </div>
      <div className={clsx(classes.col3, classes.col)}>
        <div>
          <Typography variant="h5" display="inline">
            RCA:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            SI
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            Kasko:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.kasko}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            PAI:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.pai ? 'SI' : 'NO'}
          </Typography>
        </div>
        <div>
          <Typography variant="h5" display="inline">
            ARD:{' '}
          </Typography>
          <Typography variant="h4" display="inline">
            {proposal.ard}
          </Typography>
        </div>
      </div>
      {isOperator && (
        <div className={classes.actions}>
          <IconButton
            onClick={onUpdate}
            color="primary"
            disabled={disableActions}
          >
            <PencilIcon />
          </IconButton>
          <IconButton
            onClick={onDuplicate}
            color="primary"
            disabled={disableActions || !canDuplicate}
          >
            <ContentDuplicate />
          </IconButton>
          <IconButton
            onClick={onDelete}
            color="secondary"
            disabled={disableActions}
          >
            <TrashIcon />
          </IconButton>
        </div>
      )}
    </Paper>
  )
}

export default ProposalCard
