import React from 'react'

import { Button, Paper, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  col: {
    margin: '0 auto',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    display: 'flex',
    width: '25%',
    '& > div': {
      width: '100%'
    }
  }
}))

const AddProposalCard: React.FC<{
  onCreate: () => void
  name: string
  count: number
  disabled?: boolean
}> = ({ onCreate, name, count, disabled = false }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <div className={classes.col}>
        <Typography variant="h1">Ehi {name}</Typography>
        <Typography
          variant="h3"
          style={{ marginBottom: '1em', marginTop: '0.75em' }}
        >
          Puoi aggiungere ancora {count} propost{count > 1 ? 'e' : 'a'} per
          quest'auto. Aggiungine una.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={onCreate}
          disabled={disabled}
        >
          Aggiungi una proposta
        </Button>
      </div>
    </Paper>
  )
}

export default AddProposalCard
