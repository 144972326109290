import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { GET_CAR } from 'query'
import { DELETE_CAR } from 'mutation'
import { Typography } from '@material-ui/core'
import {
  Back,
  CarDetail,
  CarForm,
  ConfirmDialog,
  MessageDialog
} from 'components'
import { CarFormValues } from 'components/carForm'
import { priceNumberToString } from 'utils'
import {
  deleteCar,
  deleteCarVariables,
  getCar,
  getCarVariables,
  getCar_getCar as CarData
} from 'generated/schemaTypes'

const parseCar = (car: CarData): CarFormValues => {
  return {
    brand: car.brand.toString(),
    fuelSystem: car.fuelSystem.toString(),
    km: car.km.toString(),
    model: car.model.toString(),
    manufacturerCode: car.manufacturerCode.toString(),
    plate: car.plate.toString(),
    trimLevel: car.trimLevel.toString(),
    displacement: car.displacement.toString(),
    listPrice: priceNumberToString(car.listPrice),
    registration: new Date(car.registration),
    power: car.power.toString(),
    dealerPrice: priceNumberToString(car.dealerPrice),
    optionals: car.optionals.map(({ price, description, code }: IOptional) => {
      return {
        description,
        code,
        price: priceNumberToString(price)
      }
    }),
    offer: car.offer || '',
    optionalsAfterMarket: car.optionalsAfterMarket.map(
      ({ price, description }: IOptionalAfterMarket) => {
        return {
          description,
          price: priceNumberToString(price)
        }
      }
    )
  }
}

export default function Car() {
  const history = useHistory()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [showUpdateCarForm, setShowUpdateCarForm] = useState(false)
  const [showDeleteMessage, setShowDeleteMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const {
    data: carData,
    loading: carLoading,
    error: carsError,
    startPolling,
    stopPolling
  } = useQuery<getCar, getCarVariables>(GET_CAR, {
    fetchPolicy: 'network-only',
    // pollInterval: 60000,
    variables: {
      id
    }
  })

  useEffect(() => {
    startPolling(60000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  const [deleteCarMutation] = useMutation<deleteCar, deleteCarVariables>(
    DELETE_CAR,
    {
      onError: () => {
        setShowDeleteMessage(false)
        setShowErrorMessage(true)
      },
      onCompleted: () => {
        setShowDeleteMessage(false)
        enqueueSnackbar('Auto eliminata con successo', {
          variant: 'success'
        })
        history.push('/cars')
      }
    }
  )
  if (carsError) return <div>Error!</div>

  const car = carData?.getCar
  // getCars()

  return (
    <>
      {carLoading ? (
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: '100px', height: 50 }}
        >
          Caricamento dell'auto in corso...
        </Typography>
      ) : (
        <>
          <div style={{ maxWidth: 1200, margin: '0 auto' }}>
            <Back url="/cars">Torna all'elenco delle auto</Back>
          </div>
          <CarDetail
            car={car as CarData}
            onDeleteCar={() => {
              setShowDeleteMessage(true)
            }}
            onUpdateCar={() => {
              setShowUpdateCarForm(true)
            }}
          />
          <CarForm
            useLocalStorage={false}
            open={showUpdateCarForm}
            handleClose={() => {
              setShowUpdateCarForm(false)
            }}
            carId={(car as ICar).id}
            car={parseCar(car as CarData)}
          />
          <ConfirmDialog
            open={showDeleteMessage}
            onClose={() => setShowDeleteMessage(false)}
            onConfirm={async () =>
              await deleteCarMutation({ variables: { id } })
            }
            title="Attenzione"
          >
            Sei sicuro di voler eliminare questo veicolo? Questa operazione è
            irreversibile!
          </ConfirmDialog>
          <MessageDialog
            open={showErrorMessage}
            title="Attenzione"
            onClose={() => setShowErrorMessage(false)}
          >
            Impossibile cancellare l'auto! Verifica che non ci siano pratiche
            associate!
          </MessageDialog>
        </>
      )}
    </>
  )
}
