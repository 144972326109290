/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login {
  __typename: 'AuthPayload'
  token: string
}

export interface login {
  login: login_login
}

export interface loginVariables {
  email: string
  password: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCar
// ====================================================

export interface createCar_createCar {
  __typename: 'Car'
  id: string
}

export interface createCar {
  createCar: createCar_createCar
}

export interface createCarVariables {
  data: CarCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteCar
// ====================================================

export interface deleteCar_deleteCar {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface deleteCar {
  deleteCar: deleteCar_deleteCar
}

export interface deleteCarVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCar
// ====================================================

export interface updateCar_updateCar_optionals {
  __typename: 'Optional'
  price: number
  code: string
  description: string
}

export interface updateCar_updateCar_optionalsAfterMarket {
  __typename: 'OptionalAfterMarket'
  price: number
  description: string
}

export interface updateCar_updateCar {
  __typename: 'Car'
  id: string
  brand: string
  model: string
  image: string | null
  trimLevel: string
  displacement: number
  fuelSystem: FuelSystem
  manufacturerCode: string
  power: number
  plate: string
  km: number
  registration: any
  listPrice: number
  dealerPrice: number
  offer: string | null
  optionals: updateCar_updateCar_optionals[]
  optionalsAfterMarket: updateCar_updateCar_optionalsAfterMarket[]
}

export interface updateCar {
  updateCar: updateCar_updateCar
}

export interface updateCarVariables {
  data: CarUpdateInput
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setCarOwner
// ====================================================

export interface setCarOwner {
  setCarOwner: string | null
}

export interface setCarOwnerVariables {
  carId: string
  operatorId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createProposal
// ====================================================

export interface createProposal_createProposal {
  __typename: 'Proposal'
  id: string
}

export interface createProposal {
  createProposal: createProposal_createProposal
}

export interface createProposalVariables {
  data: ProposalCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProposal
// ====================================================

export interface updateProposal_updateProposal {
  __typename: 'Proposal'
  id: string
  months: number
  km: number
  mss: number
  rent: number
  infoCar: string
  winterTires: number
  summerTires: number
  allSeasonTires: number
  deposit: number
  replacementCar: boolean
  windows: boolean
  kasko: string
  ard: string
  pai: boolean
}

export interface updateProposal {
  updateProposal: updateProposal_updateProposal
}

export interface updateProposalVariables {
  data: ProposalUpdateInput
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProposal
// ====================================================

export interface deleteProposal_deleteProposal {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface deleteProposal {
  deleteProposal: deleteProposal_deleteProposal | null
}

export interface deleteProposalVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDocument
// ====================================================

export interface createDocument_createDocument {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface createDocument {
  createDocument: createDocument_createDocument
}

export interface createDocumentVariables {
  data: DocumentCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDocument
// ====================================================

export interface updateDocument_updateDocument {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface updateDocument {
  updateDocument: updateDocument_updateDocument
}

export interface updateDocumentVariables {
  data: DocumentUpdateInput
  documentId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createList
// ====================================================

export interface createList_createList_documents {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface createList_createList {
  __typename: 'DocumentList'
  id: string
  label: string
  description: string
  documents: createList_createList_documents[]
}

export interface createList {
  createList: createList_createList
}

export interface createListVariables {
  data: ListCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateList
// ====================================================

export interface updateList_updateList_documents {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface updateList_updateList {
  __typename: 'DocumentList'
  id: string
  label: string
  description: string
  documents: updateList_updateList_documents[]
}

export interface updateList {
  updateList: updateList_updateList
}

export interface updateListVariables {
  data: ListUpdateInput
  listId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClientPrivate
// ====================================================

export interface createClientPrivate_createClientPrivate_address {
  __typename: 'Address'
  addressLine: string
  zip: string
  city: string
  province: string
  hamlet: string | null
}

export interface createClientPrivate_createClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: createClientPrivate_createClientPrivate_address
}

export interface createClientPrivate {
  createClientPrivate: createClientPrivate_createClientPrivate
}

export interface createClientPrivateVariables {
  data: ClientPrivateCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientPrivate
// ====================================================

export interface updateClientPrivate_updateClientPrivate_address {
  __typename: 'Address'
  addressLine: string
  zip: string
  city: string
  province: string
  hamlet: string | null
}

export interface updateClientPrivate_updateClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: updateClientPrivate_updateClientPrivate_address
}

export interface updateClientPrivate {
  updateClientPrivate: updateClientPrivate_updateClientPrivate
}

export interface updateClientPrivateVariables {
  data: ClientPrivateUpdateInput
  clientId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClientCompany
// ====================================================

export interface createClientCompany_createClientCompany_address {
  __typename: 'Address'
  addressLine: string
  zip: string
  city: string
  province: string
  hamlet: string | null
}

export interface createClientCompany_createClientCompany {
  __typename: 'ClientCompany'
  id: string
  companyType: CompanyType
  vat: string
  name: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  legalRepresentativeName: string | null
  legalRepresentativeCF: string | null
  contactType: string | null
  pec: string | null
  address: createClientCompany_createClientCompany_address
}

export interface createClientCompany {
  createClientCompany: createClientCompany_createClientCompany
}

export interface createClientCompanyVariables {
  data: ClientCompanyCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientCompany
// ====================================================

export interface updateClientCompany_updateClientCompany_address {
  __typename: 'Address'
  addressLine: string
  zip: string
  city: string
  province: string
  hamlet: string | null
}

export interface updateClientCompany_updateClientCompany {
  __typename: 'ClientCompany'
  id: string
  companyType: CompanyType
  vat: string
  name: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  legalRepresentativeName: string | null
  legalRepresentativeCF: string | null
  contactType: string | null
  pec: string | null
  address: updateClientCompany_updateClientCompany_address
}

export interface updateClientCompany {
  updateClientCompany: updateClientCompany_updateClientCompany
}

export interface updateClientCompanyVariables {
  data: ClientCompanyUpdateInput
  clientId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createDealer
// ====================================================

export interface createDealer_createDealer_address {
  __typename: 'Address'
  addressLine: string
  city: string
  province: string
  zip: string
}

export interface createDealer_createDealer_primaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface createDealer_createDealer_secondaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface createDealer_createDealer {
  __typename: 'Dealer'
  id: string
  displayName: string
  address: createDealer_createDealer_address
  email: string
  company: string
  pi: string
  cf: string
  sdi: string
  phone: string
  logo: string | null
  primaryContact: createDealer_createDealer_primaryContact
  secondaryContact: createDealer_createDealer_secondaryContact | null
  iban: string
}

export interface createDealer {
  createDealer: createDealer_createDealer
}

export interface createDealerVariables {
  data: DealerCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClient
// ====================================================

export interface deleteClient_deleteClient {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface deleteClient {
  deleteClient: deleteClient_deleteClient | null
}

export interface deleteClientVariables {
  clientId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDealer
// ====================================================

export interface updateDealer_updateDealer_address {
  __typename: 'Address'
  addressLine: string
  city: string
  province: string
  zip: string
}

export interface updateDealer_updateDealer_primaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface updateDealer_updateDealer_secondaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface updateDealer_updateDealer {
  __typename: 'Dealer'
  id: string
  displayName: string
  address: updateDealer_updateDealer_address
  email: string
  company: string
  pi: string
  cf: string
  sdi: string
  phone: string
  logo: string | null
  primaryContact: updateDealer_updateDealer_primaryContact
  secondaryContact: updateDealer_updateDealer_secondaryContact | null
  iban: string
}

export interface updateDealer {
  updateDealer: updateDealer_updateDealer
}

export interface updateDealerVariables {
  data: DealerUpdateInput
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setDealerStatus
// ====================================================

export interface setDealerStatus_setDealerStatus {
  __typename: 'Dealer'
  id: string
  status: UserStatus
}

export interface setDealerStatus {
  setDealerStatus: setDealerStatus_setDealerStatus
}

export interface setDealerStatusVariables {
  userId: string
  status: UserStatus
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setOperatorStatus
// ====================================================

export interface setOperatorStatus_setOperatorStatus {
  __typename: 'Operator'
  id: string
  status: UserStatus
}

export interface setOperatorStatus {
  setOperatorStatus: setOperatorStatus_setOperatorStatus
}

export interface setOperatorStatusVariables {
  userId: string
  status: UserStatus
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOperator
// ====================================================

export interface createOperator_createOperator {
  __typename: 'Operator'
  id: string
  name: string
  lastname: string
  email: string
}

export interface createOperator {
  createOperator: createOperator_createOperator
}

export interface createOperatorVariables {
  data: OperatorCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOperator
// ====================================================

export interface deleteOperator_deleteOperator {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface deleteOperator {
  deleteOperator: deleteOperator_deleteOperator
}

export interface deleteOperatorVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAdmin
// ====================================================

export interface createAdmin_createAdmin {
  __typename: 'Operator'
  id: string
  name: string
  lastname: string
  email: string
}

export interface createAdmin {
  createAdmin: createAdmin_createAdmin
}

export interface createAdminVariables {
  data: OperatorCreateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateOperator
// ====================================================

export interface updateOperator_updateOperator {
  __typename: 'Operator'
  id: string
  name: string
  lastname: string
  email: string
}

export interface updateOperator {
  updateOperator: updateOperator_updateOperator
}

export interface updateOperatorVariables {
  data: OperatorUpdateInput
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: requestResetPassword
// ====================================================

export interface requestResetPassword_requestResetPassword {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface requestResetPassword {
  requestResetPassword: requestResetPassword_requestResetPassword | null
}

export interface requestResetPasswordVariables {
  email: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword_resetPassword {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface resetPassword {
  resetPassword: resetPassword_resetPassword | null
}

export interface resetPasswordVariables {
  resetToken: string
  password: string
  confirmPassword: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createContract
// ====================================================

export interface createContract_createContract {
  __typename: 'Contract'
  id: string
}

export interface createContract {
  createContract: createContract_createContract
}

export interface createContractVariables {
  data: ContractCreatInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteContract
// ====================================================

export interface deleteContract_deleteContract {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface deleteContract {
  deleteContract: deleteContract_deleteContract
}

export interface deleteContractVariables {
  contractId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractOwner
// ====================================================

export interface setContractOwner {
  setContractOwner: string | null
}

export interface setContractOwnerVariables {
  contractId: string
  operatorId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractStatus
// ====================================================

export interface setContractStatus_setContractStatus {
  __typename: 'Contract'
  id: string
  status: ContractStatus
}

export interface setContractStatus {
  setContractStatus: setContractStatus_setContractStatus
}

export interface setContractStatusVariables {
  contractId: string
  status: ContractStatus
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractOutcome
// ====================================================

export interface setContractOutcome_setContractOutcome {
  __typename: 'Contract'
  id: string
  outcome: ContractOutcome
}

export interface setContractOutcome {
  setContractOutcome: setContractOutcome_setContractOutcome
}

export interface setContractOutcomeVariables {
  contractId: string
  outcome: ContractOutcome
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractDetail
// ====================================================

export interface setContractDetail_setContractDetail_documentList {
  __typename: 'DocumentList'
  id: string
  label: string
}

export interface setContractDetail_setContractDetail {
  __typename: 'Contract'
  id: string
  r2gContractId: string | null
  r2gClientId: string | null
  fileContract: string | null
  documentList: setContractDetail_setContractDetail_documentList | null
}

export interface setContractDetail {
  setContractDetail: setContractDetail_setContractDetail
}

export interface setContractDetailVariables {
  contractId: string
  r2gContractId: string
  r2gClientId: string
  documentListId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractFile
// ====================================================

export interface setContractFile_setContractFile {
  __typename: 'Contract'
  id: string
  fileContract: string | null
}

export interface setContractFile {
  setContractFile: setContractFile_setContractFile
}

export interface setContractFileVariables {
  contractId: string
  file?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractDocumentStatus
// ====================================================

export interface setContractDocumentStatus_setContractDocumentStatus_documents {
  __typename: 'DocumentFile'
  documentId: string
  status: DocumentStatus
  notes: string | null
  url: string | null
}

export interface setContractDocumentStatus_setContractDocumentStatus {
  __typename: 'Contract'
  id: string
  documents: (setContractDocumentStatus_setContractDocumentStatus_documents | null)[]
}

export interface setContractDocumentStatus {
  setContractDocumentStatus: setContractDocumentStatus_setContractDocumentStatus
}

export interface setContractDocumentStatusVariables {
  contractId: string
  documentId: string
  status: DocumentStatus
  notes?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractDocumentFile
// ====================================================

export interface setContractDocumentFile_setContractDocumentFile_documents {
  __typename: 'DocumentFile'
  documentId: string
  status: DocumentStatus
  notes: string | null
  url: string | null
}

export interface setContractDocumentFile_setContractDocumentFile {
  __typename: 'Contract'
  id: string
  documents: (setContractDocumentFile_setContractDocumentFile_documents | null)[]
}

export interface setContractDocumentFile {
  setContractDocumentFile: setContractDocumentFile_setContractDocumentFile
}

export interface setContractDocumentFileVariables {
  contractId: string
  documentId: string
  url?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractDepositStatus
// ====================================================

export interface setContractDepositStatus_setContractDepositStatus_deposit {
  __typename: 'DepositFile'
  status: DocumentStatus
  notes: string | null
  url: string | null
}

export interface setContractDepositStatus_setContractDepositStatus {
  __typename: 'Contract'
  id: string
  deposit: setContractDepositStatus_setContractDepositStatus_deposit | null
}

export interface setContractDepositStatus {
  setContractDepositStatus: setContractDepositStatus_setContractDepositStatus
}

export interface setContractDepositStatusVariables {
  contractId: string
  status: DocumentStatus
  notes?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractRentDepositFile
// ====================================================

export interface setContractRentDepositFile_setContractRentDepositFile_rentDeposit {
  __typename: 'DepositFile'
  status: DocumentStatus
  notes: string | null
  url: string | null
}

export interface setContractRentDepositFile_setContractRentDepositFile {
  __typename: 'Contract'
  id: string
  rentDeposit: setContractRentDepositFile_setContractRentDepositFile_rentDeposit | null
}

export interface setContractRentDepositFile {
  setContractRentDepositFile: setContractRentDepositFile_setContractRentDepositFile
}

export interface setContractRentDepositFileVariables {
  contractId: string
  url?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractRentDepositStatus
// ====================================================

export interface setContractRentDepositStatus_setContractRentDepositStatus_rentDeposit {
  __typename: 'DepositFile'
  status: DocumentStatus
  notes: string | null
  url: string | null
}

export interface setContractRentDepositStatus_setContractRentDepositStatus {
  __typename: 'Contract'
  id: string
  rentDeposit: setContractRentDepositStatus_setContractRentDepositStatus_rentDeposit | null
}

export interface setContractRentDepositStatus {
  setContractRentDepositStatus: setContractRentDepositStatus_setContractRentDepositStatus
}

export interface setContractRentDepositStatusVariables {
  contractId: string
  status: DocumentStatus
  notes?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractDepositFile
// ====================================================

export interface setContractDepositFile_setContractDepositFile_deposit {
  __typename: 'DepositFile'
  status: DocumentStatus
  notes: string | null
  url: string | null
}

export interface setContractDepositFile_setContractDepositFile {
  __typename: 'Contract'
  id: string
  deposit: setContractDepositFile_setContractDepositFile_deposit | null
}

export interface setContractDepositFile {
  setContractDepositFile: setContractDepositFile_setContractDepositFile
}

export interface setContractDepositFileVariables {
  contractId: string
  url?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setContractProposal
// ====================================================

export interface setContractProposal_setContractProposal_proposal {
  __typename: 'FinalProposal'
  id: string
  months: number
  km: number
  mss: number
  rent: number
  infoCar: string
  winterTires: number
  summerTires: number
  allSeasonTires: number
  deposit: number
  replacementCar: boolean
  windows: boolean
  kasko: string
  ard: string
  pai: boolean
}

export interface setContractProposal_setContractProposal {
  __typename: 'Contract'
  id: string
  proposal: setContractProposal_setContractProposal_proposal
}

export interface setContractProposal {
  setContractProposal: setContractProposal_setContractProposal
}

export interface setContractProposalVariables {
  proposalId: string
  contractId: string
  data: ProposalUpdateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setSettings
// ====================================================

export interface setSettings_setSettings {
  __typename: 'Settings'
  emailScoringR2G: string | null
  emailDigitalSignR2G: string | null
  vat: number | null
}

export interface setSettings {
  setSettings: setSettings_setSettings | null
}

export interface setSettingsVariables {
  emailScoringR2G?: string | null
  vat?: number | null
  emailDigitalSignR2G?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePassword
// ====================================================

export interface updatePassword_updatePassword {
  __typename: 'SuccessMessage'
  message: string | null
}

export interface updatePassword {
  updatePassword: updatePassword_updatePassword
}

export interface updatePasswordVariables {
  userId: string
  password: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: isLoggedIn
// ====================================================

export interface isLoggedIn {
  isLoggedIn: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userRole
// ====================================================

export interface userRole {
  role: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userId
// ====================================================

export interface userId {
  userId: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: filters
// ====================================================

export interface filters {
  currentDealer: string
  filterProvince: string[]
  filterOwner: string | null
  filterContractStatus: string | null
  filterCarWithProposals: boolean | null
  filterCarWithoutProposals: boolean | null
  filterCarIncludeSold: boolean | null
  filterCarIncludeExpired: boolean | null
  orderCar: string | null
  orderContract: string | null
  orderDealer: string | null
  orderOperator: string | null
  orderClient: string | null
  searchCar: string | null
  searchContract: string | null
  searchDealer: string | null
  searchOperator: string | null
  searchClient: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me_Dealer_address {
  __typename: 'Address'
  addressLine: string
  city: string
  province: string
  zip: string
  hamlet: string | null
}

export interface me_me_Dealer_primaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface me_me_Dealer_secondaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface me_me_Dealer {
  __typename: 'Dealer'
  displayName: string
  status: UserStatus
  id: string
  role: UserRole
  address: me_me_Dealer_address
  email: string
  company: string
  pi: string
  cf: string
  sdi: string
  phone: string
  logo: string | null
  primaryContact: me_me_Dealer_primaryContact
  secondaryContact: me_me_Dealer_secondaryContact | null
  iban: string
}

export interface me_me_Operator {
  __typename: 'Operator'
  status: UserStatus
  name: string
  lastname: string
  email: string
  id: string
  role: UserRole
}

export type me_me = me_me_Dealer | me_me_Operator

export interface me {
  me: me_me | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDealersListSimple
// ====================================================

export interface getDealersListSimple_getDealers_dealers_address {
  __typename: 'Address'
  province: string
}

export interface getDealersListSimple_getDealers_dealers {
  __typename: 'Dealer'
  id: string
  displayName: string
  address: getDealersListSimple_getDealers_dealers_address
}

export interface getDealersListSimple_getDealers {
  __typename: 'DealersResult'
  dealers: getDealersListSimple_getDealers_dealers[]
}

export interface getDealersListSimple {
  getDealers: getDealersListSimple_getDealers
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDealers
// ====================================================

export interface getDealers_getDealers_dealers_address {
  __typename: 'Address'
  addressLine: string
  city: string
  province: string
  zip: string
  hamlet: string | null
}

export interface getDealers_getDealers_dealers_primaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface getDealers_getDealers_dealers_secondaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface getDealers_getDealers_dealers {
  __typename: 'Dealer'
  id: string
  displayName: string
  status: UserStatus
  address: getDealers_getDealers_dealers_address
  email: string
  company: string
  pi: string
  cf: string
  sdi: string
  phone: string
  logo: string | null
  primaryContact: getDealers_getDealers_dealers_primaryContact
  secondaryContact: getDealers_getDealers_dealers_secondaryContact | null
  iban: string
}

export interface getDealers_getDealers {
  __typename: 'DealersResult'
  dealers: getDealers_getDealers_dealers[]
  cursor: number
}

export interface getDealers {
  getDealers: getDealers_getDealers
}

export interface getDealersVariables {
  where?: DealerWhereInput | null
  orderBy?: DealerOrderByInput | null
  cursor?: number | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOperators
// ====================================================

export interface getOperators_getOperators {
  __typename: 'Operator'
  id: string
  email: string
  name: string
  lastname: string
  status: UserStatus
}

export interface getOperators {
  getOperators: getOperators_getOperators[]
}

export interface getOperatorsVariables {
  where?: OperatorWhereInput | null
  orderBy?: OperatorOrderByInput | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOperator
// ====================================================

export interface getOperator_getOperator {
  __typename: 'Operator'
  id: string
  email: string
  name: string
  lastname: string
  status: UserStatus
}

export interface getOperator {
  getOperator: getOperator_getOperator | null
}

export interface getOperatorVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDealer
// ====================================================

export interface getDealer_getDealer_address {
  __typename: 'Address'
  addressLine: string
  city: string
  hamlet: string | null
  province: string
  zip: string
}

export interface getDealer_getDealer_primaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface getDealer_getDealer_secondaryContact {
  __typename: 'Contact'
  name: string
  lastname: string
  phone: string
}

export interface getDealer_getDealer {
  __typename: 'Dealer'
  id: string
  displayName: string
  status: UserStatus
  address: getDealer_getDealer_address
  email: string
  company: string
  pi: string
  cf: string
  sdi: string
  phone: string
  logo: string | null
  primaryContact: getDealer_getDealer_primaryContact
  secondaryContact: getDealer_getDealer_secondaryContact | null
  iban: string
}

export interface getDealer {
  getDealer: getDealer_getDealer | null
}

export interface getDealerVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getOperatorsListSimple
// ====================================================

export interface getOperatorsListSimple_getOperators {
  __typename: 'Operator'
  id: string
  name: string
  lastname: string
}

export interface getOperatorsListSimple {
  getOperators: getOperatorsListSimple_getOperators[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrlForOfferFile
// ====================================================

export interface getSignedUrlForOfferFile {
  getSignedUrlForOfferFile: string | null
}

export interface getSignedUrlForOfferFileVariables {
  dealerId: string
  carId: string
  filename: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrlForContractFile
// ====================================================

export interface getSignedUrlForContractFile {
  getSignedUrlForContractFile: string | null
}

export interface getSignedUrlForContractFileVariables {
  dealerId: string
  contractId: string
  filename: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrlForDocumentFile
// ====================================================

export interface getSignedUrlForDocumentFile {
  getSignedUrlForDocumentFile: string | null
}

export interface getSignedUrlForDocumentFileVariables {
  dealerId: string
  r2gClientId: string
  r2gContractId: string
  filename: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrlForDepositFile
// ====================================================

export interface getSignedUrlForDepositFile {
  getSignedUrlForDepositFile: string | null
}

export interface getSignedUrlForDepositFileVariables {
  dealerId: string
  r2gClientId: string
  r2gContractId: string
  filename: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrlForCar
// ====================================================

export interface getSignedUrlForCar {
  getSignedUrlForCar: string | null
}

export interface getSignedUrlForCarVariables {
  dealerId: string
  carId: string
  filename: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSignedUrlForLogo
// ====================================================

export interface getSignedUrlForLogo {
  getSignedUrlForLogo: string | null
}

export interface getSignedUrlForLogoVariables {
  id: string
  filename: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSpotModule
// ====================================================

export interface getSpotModule {
  getSpotModule: string | null
}

export interface getSpotModuleVariables {
  contractId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCars
// ====================================================

export interface getCars_getCars_cars_dealer {
  __typename: 'Dealer'
  id: string
  displayName: string
}

export interface getCars_getCars_cars_operator {
  __typename: 'Operator'
  id: string
  name: string
  lastname: string
}

export interface getCars_getCars_cars_proposals {
  __typename: 'Proposal'
  id: string
  rent: number
  months: number
  km: number
  deposit: number
}

export interface getCars_getCars_cars {
  __typename: 'Car'
  id: string
  brand: string
  model: string
  image: string | null
  trimLevel: string
  displacement: number
  fuelSystem: FuelSystem
  power: number
  plate: string
  km: number
  registration: any
  insertDate: any
  listPrice: number
  dealerPrice: number
  totalPrice: number
  proposalsCount: number
  offer: string | null
  sold: boolean
  dealer: getCars_getCars_cars_dealer
  operator: getCars_getCars_cars_operator | null
  proposals: getCars_getCars_cars_proposals[]
}

export interface getCars_getCars {
  __typename: 'CarsResult'
  cars: getCars_getCars_cars[]
  cursor: any
}

export interface getCars {
  getCars: getCars_getCars
}

export interface getCarsVariables {
  where?: CarWhereInput | null
  orderBy?: CarOrderByInput | null
  cursor?: any | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCarBaseInfo
// ====================================================

export interface getCarBaseInfo_getCar_optionals {
  __typename: 'Optional'
  price: number
  code: string
  description: string
}

export interface getCarBaseInfo_getCar_optionalsAfterMarket {
  __typename: 'OptionalAfterMarket'
  price: number
  description: string
}

export interface getCarBaseInfo_getCar {
  __typename: 'Car'
  id: string
  brand: string
  model: string
  image: string | null
  trimLevel: string
  displacement: number
  fuelSystem: FuelSystem
  power: number
  manufacturerCode: string
  plate: string
  km: number
  proposalsCount: number
  registration: any
  listPrice: number
  dealerPrice: number
  totalPrice: number
  optionals: getCarBaseInfo_getCar_optionals[]
  optionalsAfterMarket: getCarBaseInfo_getCar_optionalsAfterMarket[]
}

export interface getCarBaseInfo {
  getCar: getCarBaseInfo_getCar | null
}

export interface getCarBaseInfoVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCar
// ====================================================

export interface getCar_getCar_dealer {
  __typename: 'Dealer'
  displayName: string
  id: string
}

export interface getCar_getCar_optionals {
  __typename: 'Optional'
  price: number
  code: string
  description: string
}

export interface getCar_getCar_optionalsAfterMarket {
  __typename: 'OptionalAfterMarket'
  price: number
  description: string
}

export interface getCar_getCar_proposals {
  __typename: 'Proposal'
  id: string
  rent: number
  months: number
  km: number
  mss: number
  infoCar: string
  winterTires: number
  summerTires: number
  allSeasonTires: number
  deposit: number
  replacementCar: boolean
  windows: boolean
  pai: boolean
  kasko: string
  ard: string
}

export interface getCar_getCar_operator {
  __typename: 'Operator'
  id: string
  name: string
  lastname: string
}

export interface getCar_getCar {
  __typename: 'Car'
  id: string
  brand: string
  model: string
  image: string | null
  dealer: getCar_getCar_dealer
  sold: boolean
  insertDate: any
  trimLevel: string
  displacement: number
  fuelSystem: FuelSystem
  power: number
  plate: string
  km: number
  registration: any
  manufacturerCode: string
  listPrice: number
  dealerPrice: number
  totalPrice: number
  offer: string | null
  optionals: getCar_getCar_optionals[]
  optionalsAfterMarket: getCar_getCar_optionalsAfterMarket[]
  proposalsCount: number
  proposals: getCar_getCar_proposals[]
  operator: getCar_getCar_operator | null
}

export interface getCar {
  getCar: getCar_getCar | null
}

export interface getCarVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getProposal
// ====================================================

export interface getProposal_getProposal {
  __typename: 'Proposal'
  id: string
  months: number
  km: number
  mss: number
  rent: number
  infoCar: string
  winterTires: number
  summerTires: number
  allSeasonTires: number
  deposit: number
  replacementCar: boolean
  windows: boolean
  kasko: string
  ard: string
  pai: boolean
}

export interface getProposal {
  getProposal: getProposal_getProposal | null
}

export interface getProposalVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocuments
// ====================================================

export interface getDocuments_getDocuments {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface getDocuments {
  getDocuments: getDocuments_getDocuments[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLists
// ====================================================

export interface getLists_getLists_documents {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface getLists_getLists {
  __typename: 'DocumentList'
  id: string
  label: string
  description: string
  documents: getLists_getLists_documents[]
}

export interface getLists {
  getLists: getLists_getLists[]
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDocument
// ====================================================

export interface getDocument_getDocument {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface getDocument {
  getDocument: getDocument_getDocument | null
}

export interface getDocumentVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getList
// ====================================================

export interface getList_getList_documents {
  __typename: 'Document'
  id: string
  label: string
  description: string
}

export interface getList_getList {
  __typename: 'DocumentList'
  id: string
  label: string
  description: string
  documents: getList_getList_documents[]
}

export interface getList {
  getList: getList_getList | null
}

export interface getListVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClients
// ====================================================

export interface getClients_getClients_clients_ClientPrivate_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getClients_getClients_clients_ClientPrivate_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getClients_getClients_clients_ClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: getClients_getClients_clients_ClientPrivate_address
  dealer: getClients_getClients_clients_ClientPrivate_dealer
  contactType: string | null
  contractsCount: number
}

export interface getClients_getClients_clients_ClientCompany_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getClients_getClients_clients_ClientCompany_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getClients_getClients_clients_ClientCompany {
  __typename: 'ClientCompany'
  id: string
  name: string
  cf: string
  companyType: CompanyType
  vat: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: getClients_getClients_clients_ClientCompany_address
  legalRepresentativeName: string | null
  legalRepresentativeCF: string | null
  contactType: string | null
  pec: string | null
  dealer: getClients_getClients_clients_ClientCompany_dealer
  contractsCount: number
}

export type getClients_getClients_clients =
  | getClients_getClients_clients_ClientPrivate
  | getClients_getClients_clients_ClientCompany

export interface getClients_getClients {
  __typename: 'ClientsResult'
  clients: getClients_getClients_clients[]
  cursor: number
}

export interface getClients {
  getClients: getClients_getClients
}

export interface getClientsVariables {
  where?: ClientWhereInput | null
  orderBy?: ClientOrderByInput | null
  cursor?: number | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientsSimple
// ====================================================

export interface getClientsSimple_getClients_clients_ClientPrivate_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getClientsSimple_getClients_clients_ClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  dealer: getClientsSimple_getClients_clients_ClientPrivate_dealer
}

export interface getClientsSimple_getClients_clients_ClientCompany_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getClientsSimple_getClients_clients_ClientCompany {
  __typename: 'ClientCompany'
  id: string
  name: string
  dealer: getClientsSimple_getClients_clients_ClientCompany_dealer
  contractsCount: number
}

export type getClientsSimple_getClients_clients =
  | getClientsSimple_getClients_clients_ClientPrivate
  | getClientsSimple_getClients_clients_ClientCompany

export interface getClientsSimple_getClients {
  __typename: 'ClientsResult'
  clients: getClientsSimple_getClients_clients[]
  cursor: number
}

export interface getClientsSimple {
  getClients: getClientsSimple_getClients
}

export interface getClientsSimpleVariables {
  where?: ClientWhereInput | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClient
// ====================================================

export interface getClient_getClient_ClientPrivate_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getClient_getClient_ClientPrivate_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getClient_getClient_ClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: getClient_getClient_ClientPrivate_address
  dealer: getClient_getClient_ClientPrivate_dealer
  contactType: string | null
  contractsCount: number
}

export interface getClient_getClient_ClientCompany_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getClient_getClient_ClientCompany_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getClient_getClient_ClientCompany {
  __typename: 'ClientCompany'
  id: string
  name: string
  cf: string
  companyType: CompanyType
  vat: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: getClient_getClient_ClientCompany_address
  legalRepresentativeName: string | null
  legalRepresentativeCF: string | null
  contactType: string | null
  pec: string | null
  dealer: getClient_getClient_ClientCompany_dealer
  contractsCount: number
}

export type getClient_getClient =
  | getClient_getClient_ClientPrivate
  | getClient_getClient_ClientCompany

export interface getClient {
  getClient: getClient_getClient | null
}

export interface getClientVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContracts
// ====================================================

export interface getContracts_getContracts_contracts_dealer {
  __typename: 'Dealer'
  id: string
  displayName: string
}

export interface getContracts_getContracts_contracts_operator {
  __typename: 'Operator'
  id: string
}

export interface getContracts_getContracts_contracts_car {
  __typename: 'Car'
  id: string
  model: string
  brand: string
  trimLevel: string
  registration: any
  insertDate: any
  km: number
}

export interface getContracts_getContracts_contracts_proposal {
  __typename: 'FinalProposal'
  id: string
  rent: number
  months: number
  km: number
}

export interface getContracts_getContracts_contracts_client_ClientPrivate_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getContracts_getContracts_contracts_client_ClientPrivate_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getContracts_getContracts_contracts_client_ClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  iban: string
  address: getContracts_getContracts_contracts_client_ClientPrivate_address
  dealer: getContracts_getContracts_contracts_client_ClientPrivate_dealer
}

export interface getContracts_getContracts_contracts_client_ClientCompany_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getContracts_getContracts_contracts_client_ClientCompany {
  __typename: 'ClientCompany'
  id: string
  name: string
  cf: string
  companyType: CompanyType
  vat: string
  mobile: string
  email: string
  iban: string
  address: getContracts_getContracts_contracts_client_ClientCompany_address
}

export type getContracts_getContracts_contracts_client =
  | getContracts_getContracts_contracts_client_ClientPrivate
  | getContracts_getContracts_contracts_client_ClientCompany

export interface getContracts_getContracts_contracts {
  __typename: 'Contract'
  id: string
  status: ContractStatus
  outcome: ContractOutcome
  insertDate: any
  r2gClientId: string | null
  r2gContractId: string | null
  dealer: getContracts_getContracts_contracts_dealer
  operator: getContracts_getContracts_contracts_operator | null
  car: getContracts_getContracts_contracts_car
  proposal: getContracts_getContracts_contracts_proposal
  client: getContracts_getContracts_contracts_client
}

export interface getContracts_getContracts {
  __typename: 'ContractsResult'
  contracts: getContracts_getContracts_contracts[]
  cursor: any
}

export interface getContracts {
  getContracts: getContracts_getContracts
}

export interface getContractsVariables {
  where?: ContractWhereInput | null
  orderBy?: ContractOrderByInput | null
  cursor?: any | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContract
// ====================================================

export interface getContract_getContract_documentList {
  __typename: 'DocumentList'
  id: string
  label: string
}

export interface getContract_getContract_deposit {
  __typename: 'DepositFile'
  url: string | null
  status: DocumentStatus
  notes: string | null
}

export interface getContract_getContract_rentDeposit {
  __typename: 'DepositFile'
  url: string | null
  status: DocumentStatus
  notes: string | null
}

export interface getContract_getContract_documents {
  __typename: 'DocumentFile'
  documentId: string
  url: string | null
  status: DocumentStatus
  notes: string | null
}

export interface getContract_getContract_dealer {
  __typename: 'Dealer'
  id: string
  displayName: string
}

export interface getContract_getContract_operator {
  __typename: 'Operator'
  id: string
}

export interface getContract_getContract_car {
  __typename: 'Car'
  id: string
  model: string
  brand: string
  trimLevel: string
  registration: any
  insertDate: any
  km: number
  image: string | null
  displacement: number
  fuelSystem: FuelSystem
  power: number
  totalPrice: number
}

export interface getContract_getContract_proposal {
  __typename: 'FinalProposal'
  id: string
  months: number
  km: number
  mss: number
  rent: number
  infoCar: string
  winterTires: number
  summerTires: number
  allSeasonTires: number
  deposit: number
  replacementCar: boolean
  windows: boolean
  kasko: string
  ard: string
  pai: boolean
}

export interface getContract_getContract_client_ClientPrivate_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getContract_getContract_client_ClientPrivate_dealer {
  __typename: 'Dealer'
  id: string
}

export interface getContract_getContract_client_ClientPrivate {
  __typename: 'ClientPrivate'
  id: string
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: getContract_getContract_client_ClientPrivate_address
  dealer: getContract_getContract_client_ClientPrivate_dealer
  contactType: string | null
}

export interface getContract_getContract_client_ClientCompany_address {
  __typename: 'Address'
  addressLine: string
  city: string
  zip: string
  hamlet: string | null
  province: string
}

export interface getContract_getContract_client_ClientCompany {
  __typename: 'ClientCompany'
  id: string
  name: string
  cf: string
  companyType: CompanyType
  vat: string
  mobile: string
  email: string
  phone: string | null
  fax: string | null
  iban: string
  privacy: boolean
  address: getContract_getContract_client_ClientCompany_address
  legalRepresentativeName: string | null
  legalRepresentativeCF: string | null
  contactType: string | null
  pec: string | null
}

export type getContract_getContract_client =
  | getContract_getContract_client_ClientPrivate
  | getContract_getContract_client_ClientCompany

export interface getContract_getContract {
  __typename: 'Contract'
  id: string
  status: ContractStatus
  outcome: ContractOutcome
  insertDate: any
  fileContract: string | null
  documentList: getContract_getContract_documentList | null
  deposit: getContract_getContract_deposit | null
  rentDeposit: getContract_getContract_rentDeposit | null
  documents: (getContract_getContract_documents | null)[]
  r2gClientId: string | null
  r2gContractId: string | null
  notes: string | null
  dealer: getContract_getContract_dealer
  operator: getContract_getContract_operator | null
  car: getContract_getContract_car
  proposal: getContract_getContract_proposal
  client: getContract_getContract_client
}

export interface getContract {
  getContract: getContract_getContract | null
}

export interface getContractVariables {
  contractId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSettings
// ====================================================

export interface getSettings_getSettings {
  __typename: 'Settings'
  emailScoringR2G: string | null
  emailDigitalSignR2G: string | null
  vat: number | null
}

export interface getSettings {
  getSettings: getSettings_getSettings | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: operator
// ====================================================

export interface operator_operator {
  __typename: 'Operator'
  id: string
}

export interface operator {
  __typename: 'Contract'
  operator: operator_operator | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CarOrderByInput {
  CREATION_ASC = 'CREATION_ASC',
  CREATION_DESC = 'CREATION_DESC'
}

export enum ClientOrderByInput {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export enum CompanyType {
  DITTA_INDIVIDUALE = 'DITTA_INDIVIDUALE',
  PERSONA_GIURIDICA = 'PERSONA_GIURIDICA',
  PUBBLICA_AMMINISTRAZIONE = 'PUBBLICA_AMMINISTRAZIONE'
}

export enum ContractOrderByInput {
  CREATION_ASC = 'CREATION_ASC',
  CREATION_DESC = 'CREATION_DESC'
}

export enum ContractOutcome {
  APPROVED_RENT0 = 'APPROVED_RENT0',
  APPROVED_RENT12 = 'APPROVED_RENT12',
  APPROVED_RENT3 = 'APPROVED_RENT3',
  APPROVED_RENT6 = 'APPROVED_RENT6',
  APPROVED_RENT9 = 'APPROVED_RENT9',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED'
}

export enum ContractStatus {
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  WAITING_DEPOSIT = 'WAITING_DEPOSIT',
  WAITING_DIGITAL_SIGN = 'WAITING_DIGITAL_SIGN',
  WAITING_DOCS = 'WAITING_DOCS',
  WAITING_SCORING = 'WAITING_SCORING'
}

export enum DealerOrderByInput {
  DISPLAYNAME_ASC = 'DISPLAYNAME_ASC',
  DISPLAYNAME_DESC = 'DISPLAYNAME_DESC'
}

export enum DocumentStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
  UPLOADED = 'UPLOADED'
}

export enum FuelSystem {
  BENZINA = 'BENZINA',
  DIESEL = 'DIESEL',
  ELETTRICA = 'ELETTRICA',
  GPL = 'GPL',
  IBRIDO = 'IBRIDO',
  METANO = 'METANO',
  BIFUEL = 'BIFUEL'
}

export enum OperatorOrderByInput {
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export enum UserRole {
  ADMIN = 'ADMIN',
  DEALER = 'DEALER',
  OPERATOR = 'OPERATOR'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}

export interface AddressInput {
  addressLine: string
  zip: string
  city: string
  province: string
  hamlet?: string | null
}

export interface CarCreateInput {
  brand: string
  model: string
  image?: string | null
  trimLevel: string
  displacement: number
  fuelSystem: FuelSystem
  power: number
  plate: string
  manufacturerCode: string
  km: number
  registration: any
  listPrice: number
  dealerPrice: number
  optionals: OptionalInput[]
  optionalsAfterMarket: OptionalAfterMarketInput[]
  offer?: string | null
  dealerId: string
}

export interface CarUpdateInput {
  brand?: string | null
  model?: string | null
  image?: string | null
  trimLevel?: string | null
  displacement?: number | null
  fuelSystem?: FuelSystem | null
  manufacturerCode?: string | null
  power?: number | null
  plate?: string | null
  km?: number | null
  registration?: any | null
  listPrice?: number | null
  dealerPrice?: number | null
  optionals?: OptionalInput[] | null
  optionalsAfterMarket?: OptionalAfterMarketInput[] | null
  offer?: string | null
}

export interface CarWhereInput {
  dealerIds?: string[] | null
  dealerProvinces?: (string | null)[] | null
  searchPattern?: string | null
  withProposals?: boolean | null
  withoutProposals?: boolean | null
  owner?: string | null
  includeSuspended?: boolean | null
  includeExpired?: boolean | null
  includeSold?: boolean | null
}

export interface ClientCompanyCreateInput {
  companyType: CompanyType
  name: string
  cf: string
  vat: string
  mobile: string
  email: string
  phone?: string | null
  fax?: string | null
  iban: string
  privacy: boolean
  address: AddressInput
  legalRepresentativeName?: string | null
  legalRepresentativeCF?: string | null
  contactType?: string | null
  pec?: string | null
  dealer: string
}

export interface ClientCompanyUpdateInput {
  companyType?: CompanyType | null
  name?: string | null
  cf?: string | null
  vat?: string | null
  mobile?: string | null
  email?: string | null
  phone?: string | null
  iban?: string | null
  fax?: string | null
  privacy?: boolean | null
  address?: AddressInput | null
  legalRepresentativeName?: string | null
  legalRepresentativeCF?: string | null
  contactType?: string | null
  pec?: string | null
}

export interface ClientPrivateCreateInput {
  name: string
  lastname: string
  cf: string
  mobile: string
  email: string
  phone?: string | null
  fax?: string | null
  iban: string
  privacy: boolean
  address: AddressInput
  dealer: string
  contactType?: string | null
}

export interface ClientPrivateUpdateInput {
  name?: string | null
  lastname?: string | null
  cf?: string | null
  mobile?: string | null
  email?: string | null
  phone?: string | null
  fax?: string | null
  iban?: string | null
  privacy?: boolean | null
  address?: AddressInput | null
  contactType?: string | null
}

export interface ClientWhereInput {
  searchPattern?: string | null
  dealerIds?: string[] | null
}

export interface ContactInput {
  name: string
  lastname: string
  phone: string
}

export interface ContractCreatInput {
  clientId: string
  proposalId: string
  notes?: string | null
}

export interface ContractWhereInput {
  dealerIds?: string[] | null
  owner?: string | null
  searchPattern?: string | null
  status?: ContractStatus | null
}

export interface DealerCreateInput {
  email: string
  password: string
  company: string
  displayName: string
  pi: string
  cf: string
  sdi: string
  phone: string
  logo?: string | null
  primaryContact: ContactInput
  secondaryContact?: ContactInput | null
  address: AddressInput
  iban?: string | null
}

export interface DealerUpdateInput {
  email?: string | null
  company?: string | null
  pi?: string | null
  cf?: string | null
  displayName?: string | null
  sdi?: string | null
  phone?: string | null
  logo?: string | null
  primaryContact?: ContactInput | null
  secondaryContact?: ContactInput | null
  address?: AddressInput | null
  iban?: string | null
}

export interface DealerWhereInput {
  ids?: string[] | null
  province?: string | null
  displayNameContains?: string | null
  includeSuspended?: boolean | null
}

export interface DocumentCreateInput {
  label: string
  description?: string | null
}

export interface DocumentUpdateInput {
  label?: string | null
  description?: string | null
}

export interface ListCreateInput {
  label: string
  description: string
  documents: string[]
}

export interface ListUpdateInput {
  label?: string | null
  description?: string | null
  documents?: string[] | null
}

export interface OperatorCreateInput {
  email: string
  password: string
  name: string
  lastname: string
}

export interface OperatorUpdateInput {
  email?: string | null
  name?: string | null
  lastname?: string | null
}

export interface OperatorWhereInput {
  searchPattern?: string | null
  includeSuspended?: boolean | null
}

export interface OptionalAfterMarketInput {
  description: string
  price: number
}

export interface OptionalInput {
  code: string
  description: string
  price: number
}

export interface ProposalCreateInput {
  months: number
  km: number
  mss: number
  carId: string
  rent: number
  infoCar: string
  winterTires: number
  summerTires: number
  allSeasonTires: number
  deposit: number
  replacementCar: boolean
  windows: boolean
  kasko: string
  ard: string
  pai: boolean
}

export interface ProposalUpdateInput {
  months?: number | null
  km?: number | null
  mss?: number | null
  rent?: number | null
  infoCar?: string | null
  winterTires?: number | null
  summerTires?: number | null
  allSeasonTires?: number | null
  deposit?: number | null
  replacementCar?: boolean | null
  windows?: boolean | null
  kasko?: string | null
  ard?: string | null
  pai?: boolean | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
