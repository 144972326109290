import React, { ReactNode } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core'

interface ConfirmDialogProps {
  onConfirm: () => void
  onClose: () => void
  title: string
  children: ReactNode
  open: boolean
  loading?: boolean
  ctaConfirm?: string
  ctaCancel?: string
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  onConfirm,
  onClose,
  title,
  children,
  open,
  ctaConfirm = 'Continua',
  ctaCancel = 'Annulla',
  loading = false
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          {ctaCancel}
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          color="secondary"
          autoFocus
        >
          {ctaConfirm}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
