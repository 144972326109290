import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  MenuItem,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SET_CONTRACT_PROPOSAL } from 'mutation'

import { CheckboxControl, CustomInputComponent } from 'components'
import { color } from 'theme'

import { priceStringToNumber, priceNumberToString } from 'utils'
import { getContract_getContract_proposal as ProposalType } from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  grid: {
    display: 'grid',
    margin: 'auto',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    columnGap: 50
  },
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignContent: 'space-between',
    '& > *': {
      width: '100%',
      '& > *': {
        width: '100%'
      }
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 360,
    height: 180,
    backgroundPosition: 'center'
  }
}))

export interface FinalProposalFormValues {
  months: string
  km: string
  mss: string
  rent: string
  infoCar: string
  winterTires: string
  summerTires: string
  allSeasonTires: string
  deposit: string
  replacementCar: boolean
  windows: boolean
  pai: boolean
  kasko: string
  ard: string
}

const defaultInitialValues = {
  months: '',
  winterTires: '',
  summerTires: '',
  allSeasonTires: '',
  km: '',
  mss: '',
  rent: '',
  infoCar: '',
  deposit: '',
  replacementCar: false,
  windows: false,
  pai: false,
  kasko: '',
  ard: ''
}

const parseInitialValue = (proposal: ProposalType): FinalProposalFormValues => {
  return {
    months: proposal.months.toString(),
    km: proposal.km.toString(),
    mss: priceNumberToString(proposal.mss),
    rent: priceNumberToString(proposal.rent),
    infoCar: proposal.infoCar,
    winterTires: proposal.winterTires.toString(),
    summerTires: proposal.summerTires.toString(),
    allSeasonTires: proposal.allSeasonTires.toString(),
    deposit: priceNumberToString(proposal.deposit),
    replacementCar: proposal.replacementCar,
    windows: proposal.windows,
    pai: proposal.pai,
    kasko: proposal.kasko,
    ard: proposal.ard
  }
}

const validationSchema = Yup.object({
  months: Yup.mixed().required('Campo obbligatorio'),
  km: Yup.mixed().required('Campo obbligatorio'),
  mss: Yup.mixed().required('Campo obbligatorio'),
  rent: Yup.mixed().required('Campo obbligatorio'),
  infoCar: Yup.mixed().required('Campo obbligatorio'),
  winterTires: Yup.mixed().required('Campo obbligatorio'),
  summerTires: Yup.mixed().required('Campo obbligatorio'),
  allSeasonTires: Yup.mixed().required('Campo obbligatorio'),
  deposit: Yup.mixed().required('Campo obbligatorio'),
  replacementCar: Yup.mixed().required('Campo obbligatorio'),
  windows: Yup.mixed().required('Campo obbligatorio'),
  pai: Yup.mixed().required('Campo obbligatorio'),
  kasko: Yup.mixed().required('Campo obbligatorio'),
  ard: Yup.mixed().required('Campo obbligatorio')
})

interface FinalProposalFormProps {
  open: boolean
  handleClose: () => void
  proposal: ProposalType
  contractId: string
  dealerNotes: string | null
}

const FinalProposalForm: React.FC<FinalProposalFormProps> = ({
  open,
  proposal,
  handleClose,
  contractId,
  dealerNotes
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const initialValues = proposal ? parseInitialValue(proposal) : null

  const [setContractProposalMutation] = useMutation(SET_CONTRACT_PROPOSAL, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Proposta modificata', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore', err)
    }
  })

  const formik = useFormik<FinalProposalFormValues>({
    initialValues: initialValues || defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const variables: any = {
        data: {
          ...values,
          months: parseInt(values.months),
          km: parseInt(values.km),
          mss: priceStringToNumber(values.mss),
          rent: priceStringToNumber(values.rent),
          deposit: priceStringToNumber(values.deposit),
          winterTires: parseInt(values.winterTires),
          summerTires: parseInt(values.summerTires),
          allSeasonTires: parseInt(values.allSeasonTires)
        }
      }
      delete variables.data.id
      variables.proposalId = (proposal as ProposalType).id
      variables.contractId = contractId
      console.log(variables)
      await setContractProposalMutation({ variables })
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '1264px', padding: '50px 80px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.grid}>
          <div className={classes.column}>
            <div>
              <Typography variant="h1" style={{ marginBottom: '1em' }}>
                Modifica la proposta associata a questo contratto
              </Typography>
              {dealerNotes ? (
                <>
                  <Typography variant="h2" style={{ marginBottom: '1em' }}>
                    Il concessionario ha richiesto le seguenti modifiche:
                  </Typography>
                  <Typography variant="h5" style={{ marginBottom: '1em' }}>
                    {dealerNotes}
                  </Typography>
                </>
              ) : (
                <>
                  {' '}
                  <Typography variant="h2" style={{ marginBottom: '1em' }}>
                    Il concessionario non ha richiesto nessuna modifica
                  </Typography>
                </>
              )}
            </div>
          </div>
          <div className={classes.column}>
            <div>
              <Typography variant="h2" style={{ marginBottom: '1em' }}>
                Informazioni base
              </Typography>
              {/* MONTHS */}
              <CustomInputComponent
                name="months"
                type="select"
                error={formik.touched.months && !!formik.errors.months}
                id="months"
                label="Durata del noleggio (mesi)"
                value={formik.values.months}
                errorLabel={formik.errors.months}
                onChange={formik.handleChange}
              >
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="24">24</MenuItem>
                <MenuItem value="36">36</MenuItem>
                <MenuItem value="48">48</MenuItem>
                <MenuItem value="60">60</MenuItem>
                {/* <MenuItem value="72">72</MenuItem> */}
              </CustomInputComponent>
              {/* KM */}
              <CustomInputComponent
                type="number"
                name="km"
                error={formik.touched.km && !!formik.errors.km}
                id="km"
                label="Chilometri"
                value={formik.values.km}
                errorLabel={formik.errors.km}
                onChange={formik.handleChange}
              />
              {/* RATA */}
              <CustomInputComponent
                name="rent"
                error={formik.touched.rent && !!formik.errors.rent}
                id="rent"
                label="Rata mensile (IVA esclusa)"
                value={formik.values.rent}
                errorLabel={formik.errors.rent}
                onChange={formik.handleChange}
                type="price"
              />
              {/* ANTICIPO */}
              <CustomInputComponent
                name="deposit"
                error={formik.touched.deposit && !!formik.errors.deposit}
                id="deposit"
                label="Anticipo (IVA esclusa)"
                value={formik.values.deposit}
                errorLabel={formik.errors.deposit}
                onChange={formik.handleChange}
                type="price"
              />

              <Typography variant="h4">
                Km totali:{' '}
                {formik.values.km && formik.values.months
                  ? (parseInt(formik.values.km, 10) *
                      parseInt(formik.values.months)) /
                    12
                  : '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="h2" style={{ marginBottom: '1em' }}>
                Altre informazioni
              </Typography>
              {/* MESSA SU STRADA */}
              <CustomInputComponent
                name="mss"
                error={formik.touched.mss && !!formik.errors.mss}
                id="mss"
                label="Messa su strada (IVA esclusa)"
                value={formik.values.mss}
                errorLabel={formik.errors.mss}
                onChange={formik.handleChange}
                type="price"
              />
              {/* INFOCAR */}
              <CustomInputComponent
                name="infoCar"
                error={formik.touched.infoCar && !!formik.errors.infoCar}
                id="infoCar"
                label="Codice InfoCar"
                value={formik.values.infoCar}
                errorLabel={formik.errors.infoCar}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={classes.column}>
            <div>
              <Typography variant="h2" style={{ marginBottom: '1em' }}>
                Pneumatici
              </Typography>
              {/* GOMME INVERNALI */}
              <CustomInputComponent
                name="winterTires"
                type="select"
                error={
                  formik.touched.winterTires && !!formik.errors.winterTires
                }
                id="winterTires"
                label="Treni gomme invernali"
                value={formik.values.winterTires}
                errorLabel={formik.errors.winterTires}
                onChange={formik.handleChange}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </CustomInputComponent>
              {/* GOMME ESTIVE */}
              <CustomInputComponent
                name="summerTires"
                type="select"
                error={
                  formik.touched.summerTires && !!formik.errors.summerTires
                }
                id="summerTires"
                label="Treni gomme estive"
                value={formik.values.summerTires}
                errorLabel={formik.errors.summerTires}
                onChange={formik.handleChange}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </CustomInputComponent>
              {/* GOMME ALL SEASON */}
              <CustomInputComponent
                name="allSeasonTires"
                type="select"
                error={
                  formik.touched.allSeasonTires &&
                  !!formik.errors.allSeasonTires
                }
                id="allSeasonTires"
                label="Treni gomme all season"
                value={formik.values.allSeasonTires}
                errorLabel={formik.errors.allSeasonTires}
                onChange={formik.handleChange}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </CustomInputComponent>
            </div>
            <div>
              <Typography variant="h2" style={{ marginBottom: '1em' }}>
                Opzioni
              </Typography>
              {/* KASKO */}
              <CustomInputComponent
                name="kasko"
                type="select"
                error={formik.touched.kasko && !!formik.errors.kasko}
                id="kasko"
                label="Kasko"
                value={formik.values.kasko}
                errorLabel={formik.errors.kasko}
                onChange={formik.handleChange}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="250">250</MenuItem>
                <MenuItem value="500">500</MenuItem>
              </CustomInputComponent>
              {/* ARD */}
              <CustomInputComponent
                name="ard"
                type="select"
                error={formik.touched.ard && !!formik.errors.ard}
                id="ard"
                label="ARD"
                value={formik.values.ard}
                errorLabel={formik.errors.ard}
                onChange={formik.handleChange}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="250">250</MenuItem>
                <MenuItem value="500">500</MenuItem>
              </CustomInputComponent>
              <CheckboxControl
                label="Auto sostitutiva"
                checked={formik.values.replacementCar}
                onChange={formik.handleChange}
                name="replacementCar"
              />
              <CheckboxControl
                label="Cristalli"
                checked={formik.values.windows}
                onChange={formik.handleChange}
                name="windows"
              />
              <CheckboxControl
                label="PAI"
                checked={formik.values.pai}
                onChange={formik.handleChange}
                name="pai"
              />
              <CheckboxControl
                label="RCA"
                checked={true}
                disabled
                onChange={() => {}}
                name="rca"
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          Modifica la proposta
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FinalProposalForm
