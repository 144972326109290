import React, { useRef, useState } from 'react'
// import html2pdf from 'html2pdf.js'
import {
  Button,
  IconButton,
  Paper,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  deleteContract,
  deleteContractVariables,
  getContract_getContract as ContractData,
  getContract_getContract_documents as DocumentData,
  ContractStatus,
  getLists_getLists as ListsData,
  getSettings,
  DocumentStatus,
  setContractStatus,
  setContractStatusVariables,
  setContractDocumentFile,
  setContractDocumentFileVariables,
  setContractDocumentStatus,
  setContractDocumentStatusVariables,
  setContractDepositFile,
  setContractDepositFileVariables,
  setContractDepositStatus,
  setContractDepositStatusVariables,
  setContractRentDepositFile,
  setContractRentDepositFileVariables,
  setContractRentDepositStatus,
  setContractRentDepositStatusVariables,
  getContract_getContract_deposit as DepositType,
  ContractOutcome
} from 'generated/schemaTypes'
import {
  AvatarWithLabel,
  ConfirmDialog,
  ContractDetailCar,
  ContractDetailClient,
  ContractDetailProposal,
  ContractDetailForm,
  ContractOwnership,
  DocumentItem,
  DocumentLink,
  FileContract,
  FinalProposalForm,
  MessageDialog,
  RefuseDepositForm,
  RefuseRentDepositForm,
  RefuseDocumentForm,
  TrashIcon
} from 'components'
import numeral from 'numeral'
import { roleVar, userIdVar } from 'cache'
import {
  useQuery,
  useApolloClient,
  useMutation,
  useReactiveVar
} from '@apollo/client'
import { GET_FILE_URL_DOCUMENT, GET_SETTINGS } from 'query'
import {
  DELETE_CONTRACT,
  SET_CONTRACT_STATUS,
  SET_CONTRACT_DOCUMENT_STATUS,
  SET_CONTRACT_DOCUMENT_FILE,
  SET_CONTRACT_DEPOSIT_STATUS,
  SET_CONTRACT_DEPOSIT_FILE,
  SET_CONTRACT_RENTDEPOSIT_STATUS,
  SET_CONTRACT_RENTDEPOSIT_FILE
} from 'mutation'
import OutcomeForm from './outcomeForm'
import { color } from 'theme'

interface ContractDetailProps {
  contract: ContractData
  lists: ListsData[]
  startPolling: () => void
  stopPolling: () => void
  // onUpdateClient: () => void
  // onDeleteClient?: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    //padding: theme.spacing(2, 4)
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  col3: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns:
      'calc(33.33% - 10.67px) calc(33.34% - 10.67px) calc(33.33% - 10.67px)',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  col2: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
    gridGap: theme.spacing(2)
  },
  col1: {
    marginBottom: theme.spacing(2)
  },
  bottomCTA: {
    margin: theme.spacing(2, 0),
    textAlign: 'right'
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    margin: '20px 0 40px'
  },
  created: {
    padding: theme.spacing(2)
  }
}))

const getRentsFromOutcome = (outcome: ContractOutcome): number => {
  let rents = 0
  switch (outcome) {
    case ContractOutcome.APPROVED_RENT0:
      rents = 0
      break
    case ContractOutcome.APPROVED_RENT3:
      rents = 3
      break
    case ContractOutcome.APPROVED_RENT6:
      rents = 6
      break
    case ContractOutcome.APPROVED_RENT9:
      rents = 9
      break
    case ContractOutcome.APPROVED_RENT12:
      rents = 12
      break
  }
  return rents
}

const getTotalRentDeposit: (
  vat: number,
  rentsNumber: number,
  rent: number
) => number = (vat = 22, rentsNumber, rent) => {
  const noVat = rent * rentsNumber
  return (noVat * (vat + 100)) / 100
}

const getTotalDeposit: (vat: number, deposit: number) => number = (
  vat = 22,
  deposit
) => {
  return (deposit * (vat + 100)) / 100
}

const ContractDetail: React.FC<ContractDetailProps> = ({
  contract,
  lists,
  startPolling,
  stopPolling
}) => {
  const classes = useStyles()
  const history = useHistory()
  const inputEl = useRef<HTMLInputElement>(null)
  const client = useApolloClient()
  const roleCache = useReactiveVar(roleVar)
  const userIdCache = useReactiveVar(userIdVar)
  const { enqueueSnackbar } = useSnackbar()
  const [isDeposit, setIsDeposit] = useState(false)
  const [isRentDeposit, setIsRentDeposit] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [showDetailForm, setShowDetailForm] = useState(false)
  const [showDeleteContract, setShowDeleteContract] = useState(false)
  const [showProposalForm, setShowProposalForm] = useState(false)
  const [showOutcomeForm, setShowOutcomeForm] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState(
    'La dimensione del file non può superare i 2MB'
  )
  const [currentDoc, setCurrentDoc] = useState('')
  const [showRefuseDocumentForm, setShowRefuseDocumentForm] = useState(false)
  const [showRefuseDepositForm, setShowRefuseDepositForm] = useState(false)
  const [showRefuseRentDepositForm, setShowRefuseRentDepositForm] = useState(
    false
  )

  const { data: settings, loading: loadingSettings } = useQuery<getSettings>(
    GET_SETTINGS,
    { fetchPolicy: 'cache-first' }
  )

  const [
    setContractStatusMutation,
    { loading: isChangingStatus }
  ] = useMutation<setContractStatus, setContractStatusVariables>(
    SET_CONTRACT_STATUS
  )

  const [deleteContractMutation] = useMutation<
    deleteContract,
    deleteContractVariables
  >(DELETE_CONTRACT, {
    onCompleted: () => {
      enqueueSnackbar('Contratto eliminato', {
        variant: 'success'
      })
      setShowDeleteContract(false)
      history.push('/contracts')
    },
    onError: err => {
      console.log(error)
      setShowDeleteContract(false)
    }
  })

  const [
    setContractDocumentStatusMutation,
    { loading: isChangingContractDocumentStatus }
  ] = useMutation<
    setContractDocumentStatus,
    setContractDocumentStatusVariables
  >(SET_CONTRACT_DOCUMENT_STATUS)

  const [
    setContractDocumentFileMutation,
    { loading: isChangingContractDocumentFile }
  ] = useMutation<setContractDocumentFile, setContractDocumentFileVariables>(
    SET_CONTRACT_DOCUMENT_FILE
  )

  const [
    setContractDepositStatusMutation,
    { loading: isChangingContractDepositStatus }
  ] = useMutation<setContractDepositStatus, setContractDepositStatusVariables>(
    SET_CONTRACT_DEPOSIT_STATUS
  )

  const [
    setContractDepositFileMutation,
    { loading: isChangingContractDepositFile }
  ] = useMutation<setContractDepositFile, setContractDepositFileVariables>(
    SET_CONTRACT_DEPOSIT_FILE
  )

  const [
    setContractRentDepositStatusMutation,
    { loading: isChangingContractRentDepositStatus }
  ] = useMutation<
    setContractRentDepositStatus,
    setContractRentDepositStatusVariables
  >(SET_CONTRACT_RENTDEPOSIT_STATUS)

  const [
    setContractRentDepositFileMutation,
    { loading: isChangingContractRentDepositFile }
  ] = useMutation<
    setContractRentDepositFile,
    setContractRentDepositFileVariables
  >(SET_CONTRACT_RENTDEPOSIT_FILE)

  const isOperator = ['OPERATOR', 'ADMIN'].includes(roleCache)
  const hasDetail = !!(contract.r2gContractId && contract.r2gClientId)
  const hasFile = !!contract.fileContract

  const list = lists.find(listItem => listItem.id === contract.documentList?.id)
  const approvedDocs = contract.documents.filter(
    doc => doc?.status === DocumentStatus.APPROVED
  )
  const notApprovedDocs = contract.documents.filter(
    doc => doc?.status !== DocumentStatus.APPROVED
  )
  const isOwner = userIdCache === (contract.operator && contract.operator.id)

  const approveDocument = async (documentId: string): Promise<void> => {
    await setContractDocumentStatusMutation({
      variables: {
        documentId,
        contractId: contract.id,
        status: DocumentStatus.APPROVED
      }
    })
  }

  const deleteDocument = async (documentId: string): Promise<void> => {
    await setContractDocumentFileMutation({
      variables: {
        documentId,
        contractId: contract.id,
        url: null
      }
    })
    await setContractDocumentStatusMutation({
      variables: {
        documentId,
        contractId: contract.id,
        status: DocumentStatus.PENDING
      }
    })
  }

  const deleteDeposit = async (): Promise<void> => {
    await setContractDepositFileMutation({
      variables: {
        contractId: contract.id,
        url: null
      }
    })
    await setContractDepositStatusMutation({
      variables: {
        contractId: contract.id,
        status: DocumentStatus.PENDING
      }
    })
  }
  const deleteRentDeposit = async (): Promise<void> => {
    await setContractRentDepositFileMutation({
      variables: {
        contractId: contract.id,
        url: null
      }
    })
    await setContractRentDepositStatusMutation({
      variables: {
        contractId: contract.id,
        status: DocumentStatus.PENDING
      }
    })
  }

  const approveDeposit = async (): Promise<void> => {
    await setContractDepositStatusMutation({
      variables: {
        contractId: contract.id,
        status: DocumentStatus.APPROVED
      }
    })
  }

  const approveRentDeposit = async (): Promise<void> => {
    await setContractRentDepositStatusMutation({
      variables: {
        contractId: contract.id,
        status: DocumentStatus.APPROVED
      }
    })
  }

  // const deleteContractAction = async (): Promise<void> => {
  //   await deleteContractMutation({
  //     variables: {
  //       contractId: contract.id
  //     }
  //   })
  // }

  const getDocFilename = (id: string) => {
    const docModel = list?.documents.find(item => item.id === id) || null
    if (!docModel) return 'documento-senza-nome'
    return docModel.label.replace(/('|\s)/g, '-').toLowerCase()
  }

  if (loadingSettings) return null
  const vat: number = settings?.getSettings?.vat || 0
  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2" display="inline">
            Riepilogo pratica:&nbsp;
          </Typography>
          <Typography variant="h2" color="primary" display="inline">
            {(contract.status !== ContractStatus.CREATED &&
              contract.r2gContractId) ||
              'codice pratica non assegnato'}
          </Typography>
          {contract.status !== ContractStatus.CLOSED && (
            <IconButton
              onClick={() => {
                stopPolling()
                setShowDeleteContract(true)
              }}
              color="secondary"
              disabled={!isOwner}
            >
              <TrashIcon />
            </IconButton>
          )}
        </div>
        <div style={{ width: 250 }}>
          {isOperator && (
            <ContractOwnership
              contractId={contract.id}
              operatorId={contract.operator?.id || null}
            />
          )}
        </div>
      </div>
      {isOperator && (
        <div style={{ margin: '16px 0' }}>
          <AvatarWithLabel
            id={contract.dealer.id}
            smallLabel={contract.dealer.displayName[0]}
            fullLabel={contract.dealer.displayName}
          />
        </div>
      )}
      <div className={classes.col3}>
        <ContractDetailCar car={contract.car} />
        <ContractDetailProposal
          proposal={contract.proposal}
          onUpdateProposal={() => setShowProposalForm(true)}
          notes={contract.notes}
          disabled={!isOwner}
          readonly={![ContractStatus.CREATED].includes(contract.status)}
          isOperator={isOperator}
        />
        <ContractDetailClient client={contract.client} />
      </div>
      {contract.status === ContractStatus.CREATED && isOperator && (
        <Paper elevation={0} className={classes.created}>
          <>
            <Typography variant="h1">STATO DELLA PRATICA: PENDING</Typography>
            <Typography variant="h5" style={{ marginBottom: 16 }}>
              {hasDetail &&
                contract.fileContract &&
                'Questa pratica è pronta per essere avviata.'}
              {!hasDetail &&
                !contract.fileContract &&
                'I dati di questa pratica non sono ancora stati inseriti. Inseriscili e carica il contratto per poter avviare la pratica.'}
              {hasDetail &&
                !contract.fileContract &&
                'I dati di questa pratica sono stati inseriti. Carica il contratto per poter avviare la pratica.'}
            </Typography>
            <div className={classes.col2}>
              <div>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    ID Pratica:{' '}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {contract.r2gContractId || '-'}
                  </Typography>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    ID Cliente:{' '}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {contract.r2gClientId || '-'}
                  </Typography>
                </div>
              </div>
              <div>
                {' '}
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    Lista documenti:{' '}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {contract.documentList ? contract.documentList.label : '-'}
                  </Typography>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    Modulo SPOT:{' '}
                  </Typography>
                  <Typography
                    variant="h4"
                    display="inline"
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(`/spot/${contract.id}`)
                    }}
                  >
                    SCARICA MODULO
                  </Typography>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <FileContract
                    contractId={contract.id}
                    dealerId={contract.dealer.id}
                    url={contract.fileContract as string}
                  />
                </div>
              </div>
            </div>

            <Button
              size="medium"
              color="primary"
              variant="contained"
              disabled={!isOwner}
              onClick={() => setShowDetailForm(true)}
            >
              {hasDetail ? 'Aggiorna i dati' : 'Inserisci i dati'}
            </Button>
          </>
        </Paper>
      )}

      {contract.status === ContractStatus.CREATED && !isOperator && (
        <Paper elevation={0} className={classes.created}>
          <>
            <Typography variant="h1">STATO DELLA PRATICA: PENDING</Typography>
            <Typography variant="h5">
              In attesa che l'operatore Noloplan avvii la pratica.
            </Typography>
          </>
        </Paper>
      )}

      {[
        ContractStatus.WAITING_SCORING,
        ContractStatus.WAITING_DOCS,
        ContractStatus.WAITING_DIGITAL_SIGN,
        ContractStatus.WAITING_DEPOSIT,
        ContractStatus.CLOSED
      ].includes(contract.status) && (
        <Paper elevation={0} className={classes.created}>
          <>
            <Typography variant="h1" style={{ marginBottom: 16 }}>
              STATO DELLA PRATICA:{' '}
              {contract.status === ContractStatus.WAITING_DOCS && (
                <span style={{ color: color.BlueBright }}>
                  IN ATTESA DEI DOCUMENTI
                </span>
              )}
              {contract.status === ContractStatus.WAITING_SCORING && (
                <span style={{ color: color.BlueBright }}>
                  IN ATTESA DI SCORING
                </span>
              )}
              {contract.status === ContractStatus.WAITING_DEPOSIT && (
                <span style={{ color: color.BlueBright }}>
                  APPROVATA - IN ATTESA DEL BONIFICO E/O ANTICIPO
                </span>
              )}
              {contract.status === ContractStatus.WAITING_DIGITAL_SIGN && (
                <span style={{ color: color.BlueBright }}>
                  IN ATTESA DELLA FIRMA DIGITALE
                </span>
              )}
              {contract.status === ContractStatus.CLOSED &&
                contract.outcome === ContractOutcome.REFUSED && (
                  <span style={{ color: color.BlueBright }}>RIFIUTATA</span>
                )}
              {contract.status === ContractStatus.CLOSED &&
                contract.outcome !== ContractOutcome.REFUSED && (
                  <span style={{ color: color.BlueBright }}>CHIUSA</span>
                )}
            </Typography>
            <Typography variant="h5" style={{ marginBottom: 16 }}>
              {contract.status === ContractStatus.WAITING_SCORING &&
                (isOperator
                  ? "È in corso la verifica dello scoring. Setta l'esito una volta ricevuto il feedback da parte di Rent2Go"
                  : 'È in corso la verifica dello scoring')}
              {contract.status === ContractStatus.WAITING_DOCS &&
                (isOperator
                  ? 'Attendi il caricamento dei documenti da parte del concessionario. Dopo averli approvati, puoi richiedere lo scoring.'
                  : "Carica i documenti richiesti e attendi l'approvazione da parte di Noloplan")}
              {contract.status === ContractStatus.WAITING_DEPOSIT &&
                `La pratica è stata approvata con ${getRentsFromOutcome(
                  contract.outcome
                )} canone di cauzione.
                  `}
            </Typography>
            {contract.status === ContractStatus.WAITING_DEPOSIT &&
              (isOperator ? (
                <>
                  Attendi il caricamento della distinta del bonifico da parte
                  del concessionario. Dopo averlo approvato, puoi procedere con
                  la firma digitale.
                  <br />
                </>
              ) : (
                <>
                  <br />
                  Carica la distinta del bonifico e attendi l'approvazione da
                  parte di Noloplan.
                </>
              )) && (
                <>
                  {getRentsFromOutcome(contract.outcome) > 0 && (
                    <>
                      <br />
                      <Typography variant="h4">Cauzione</Typography>
                      <b>- Importo Bonifico:</b>{' '}
                      {getRentsFromOutcome(contract.outcome)} canoni -{' '}
                      {numeral(
                        getTotalRentDeposit(
                          vat,
                          getRentsFromOutcome(contract.outcome),
                          contract.proposal.rent
                        ) / 100
                      ).format('0,0.00')}
                      €<br />
                      <b>- Intestato a:</b> Rent2go S.r.l.
                      <br />
                      <b>- IBAN:</b> IT34F0569611000000009872X75
                      <br />
                      <b>- Causale:</b> deposito a cauzione cliente{' '}
                      {contract.client.name}{' '}
                      {contract.client.__typename === 'ClientPrivate' &&
                        contract.client.lastname}{' '}
                      {contract.r2gClientId} - n.
                      {contract.r2gContractId}
                      <br />
                    </>
                  )}
                  {contract.proposal.deposit > 0 && (
                    <>
                      <br />
                      <Typography variant="h4">Anticipo</Typography>
                      <b>- Importo Bonifico:</b>{' '}
                      {numeral(
                        getTotalDeposit(vat, contract.proposal.deposit) / 100
                      ).format('0,0.00')}
                      €<br />
                      <b>- Intestato a:</b> Rent2go S.r.l.
                      <br />
                      <b>- IBAN :</b> IT61B0569611000000009613X10
                      <br />
                      <b>- Causale:</b> deposito anticipo cliente{' '}
                      {contract.client.name}{' '}
                      {contract.client.__typename === 'ClientPrivate' &&
                        contract.client.lastname}{' '}
                      {contract.r2gClientId} - n. {contract.r2gContractId}
                      <br />
                      <br />
                    </>
                  )}
                </>
              )}

            <div className={classes.col2}>
              <div>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    ID Pratica:{' '}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {contract.r2gContractId || '-'}
                  </Typography>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    ID Cliente:{' '}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {contract.r2gClientId || '-'}
                  </Typography>
                </div>
              </div>
              <div>
                {' '}
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    Lista documenti:{' '}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {contract.documentList ? contract.documentList.label : '-'}
                  </Typography>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Typography variant="h4" display="inline">
                    Modulo SPOT:{' '}
                  </Typography>
                  <Typography
                    variant="h4"
                    display="inline"
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(`/spot/${contract.id}`)
                    }}
                  >
                    SCARICA MODULO
                  </Typography>

                  {/* <Button
                    size="medium"
                    color="primary"
                    variant="text"
                    onClick={() => {
                      window.open(`/spot/${contract.id}`)
                      // history.push(`/spot/${contract.id}`)
                      // const data = await client.query({
                      //   query: GET_SPOT_MODULE,
                      //   fetchPolicy: 'network-only',
                      //   variables: { contractId: contract.id }
                      // })
                      // window.scrollTo(0, 0)
                      // html2pdf()
                      //   .set({
                      //     margin: 0,
                      //     filename: 'modulo-spot.pdf',
                      //     image: { type: 'png' },
                      //     html2canvas: { scale: 1.5 },
                      //     jsPDF: {
                      //       unit: 'mm',
                      //       format: 'a4',
                      //       orientation: 'portrait'
                      //     }
                      //   })
                      //   .from(data.data.getSpotModule, 'string')
                      //   .toPdf()
                      //   .save()
                    }}
                  >
                    Stampa
                  </Button> */}
                </div>
                <div
                  style={{
                    marginBottom: 16,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h4" display="inline">
                    File contratto:&nbsp;
                  </Typography>
                  <Typography variant="h4" color="primary" display="inline">
                    <a
                      href={contract.fileContract as string}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      SCARICA CONTRATTO
                    </a>
                  </Typography>
                  {/* <Button
                    size="small"
                    href={contract.fileContract as string}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Scarica
                  </Button> */}
                </div>
              </div>
            </div>
          </>
        </Paper>
      )}

      {[ContractStatus.WAITING_DEPOSIT].includes(contract.status) && (
        <div style={{ marginTop: 32 }}>
          <Typography variant="h2">
            Bonifico delle rate di acconto e/o dell'anticipo
          </Typography>
          <div className={classes.col3}>
            {[
              ContractOutcome.APPROVED_RENT3,
              ContractOutcome.APPROVED_RENT6,
              ContractOutcome.APPROVED_RENT9,
              ContractOutcome.APPROVED_RENT12
            ].includes(contract.outcome) && (
              <DocumentItem
                title="Distinta del bonifico"
                description="Deposito a cauzione"
                document={contract.rentDeposit as DepositType}
                isOperator={isOperator}
                isLoading={
                  (isChangingContractDocumentStatus ||
                    isChangingContractDocumentFile ||
                    isChangingContractRentDepositStatus ||
                    isChangingContractRentDepositFile) &&
                  isDeposit
                }
                isUploading={isUploading && isRentDeposit}
                onDelete={() => deleteRentDeposit()}
                onApprove={() => {
                  approveRentDeposit()
                }}
                onRefuse={() => {
                  setShowRefuseRentDepositForm(true)
                }}
                onUpload={() => {
                  if (inputEl && inputEl.current) {
                    // setCurrentDoc((doc as DocumentData).documentId)
                    setIsRentDeposit(true)
                    setIsDeposit(false)
                    inputEl.current.click()
                  }
                }}
              />
            )}
            {contract.proposal.deposit > 0 && (
              <DocumentItem
                title="Distinta del bonifico"
                description="Deposito anticipo"
                document={contract.deposit as DepositType}
                isOperator={isOperator}
                isLoading={
                  (isChangingContractDocumentStatus ||
                    isChangingContractDocumentFile ||
                    isChangingContractDepositStatus ||
                    isChangingContractDepositFile) &&
                  isDeposit
                }
                isUploading={isUploading && isDeposit}
                onDelete={() => deleteDeposit()}
                onApprove={() => {
                  approveDeposit()
                }}
                onRefuse={() => {
                  setShowRefuseDepositForm(true)
                }}
                onUpload={() => {
                  if (inputEl && inputEl.current) {
                    // setCurrentDoc((doc as DocumentData).documentId)
                    setIsDeposit(true)
                    setIsRentDeposit(false)
                    inputEl.current.click()
                  }
                }}
              />
            )}
          </div>
        </div>
      )}

      {[
        ContractStatus.WAITING_SCORING,
        ContractStatus.WAITING_DEPOSIT,
        ContractStatus.WAITING_DIGITAL_SIGN,
        ContractStatus.CLOSED
      ].includes(contract.status) && (
        <div style={{ marginTop: 32 }}>
          <Typography variant="h2">Elenco dei documenti</Typography>
          {contract.deposit?.status === DocumentStatus.APPROVED && (
            <DocumentLink
              document={contract.deposit}
              title="Distinta del bonifico dell'anticipo"
            />
          )}
          {contract.rentDeposit?.status === DocumentStatus.APPROVED && (
            <DocumentLink
              document={contract.rentDeposit}
              title="Distinta del bonifico delle rate"
            />
          )}
          {contract.documents.map(doc => {
            const docModel =
              list?.documents.find(item => item.id === doc?.documentId) || null
            return (
              <DocumentLink
                key={doc?.documentId}
                document={doc as DocumentData}
                title={docModel ? docModel.label : 'Documento sconosciuto'}
              />
            )
          })}
        </div>
      )}

      {[ContractStatus.WAITING_DOCS].includes(contract.status) && (
        <div style={{ marginTop: 32, marginBottom: 32 }}>
          <Typography variant="h2" style={{ marginBottom: '0.5em' }}>
            Documenti da verificare/caricare
          </Typography>
          {notApprovedDocs.length === 0 && (
            <Typography variant="h5" style={{ marginBottom: 32 }}>
              Non ci sono documenti da verificare/caricare
            </Typography>
          )}
          <div className={classes.col3}>
            {notApprovedDocs.map(doc => {
              const docModel =
                list?.documents.find(item => item.id === doc?.documentId) ||
                null
              return (
                <DocumentItem
                  document={doc as DocumentData}
                  key={doc?.documentId}
                  title={docModel ? docModel.label : ''}
                  description={docModel ? docModel.description : ''}
                  isOperator={isOperator}
                  isLoading={
                    (isChangingContractDocumentStatus ||
                      isChangingContractDocumentFile) &&
                    currentDoc === doc?.documentId
                  }
                  // isUploading={true}
                  isUploading={isUploading && currentDoc === doc?.documentId}
                  onApprove={() => {
                    setCurrentDoc((doc as DocumentData).documentId)
                    approveDocument((doc as DocumentData).documentId)
                  }}
                  onDelete={() => {
                    setCurrentDoc((doc as DocumentData).documentId)
                    deleteDocument((doc as DocumentData).documentId)
                  }}
                  onRefuse={() => {
                    setCurrentDoc((doc as DocumentData).documentId)
                    setShowRefuseDocumentForm(true)
                  }}
                  onUpload={() => {
                    if (inputEl && inputEl.current) {
                      setCurrentDoc((doc as DocumentData).documentId)
                      setIsDeposit(false)
                      setIsRentDeposit(false)
                      inputEl.current.click()
                    }
                  }}
                />
              )
            })}
          </div>
          <Typography variant="h2" style={{ marginBottom: '0.5em' }}>
            Documenti approvati
          </Typography>
          {approvedDocs.length === 0 && (
            <Typography variant="h5" style={{ marginBottom: 32 }}>
              Non ci sono documenti approvati
            </Typography>
          )}
          <div className={classes.col3}>
            {contract.status === ContractStatus.WAITING_DOCS &&
              approvedDocs.map(doc => {
                const docModel =
                  list?.documents.find(item => item.id === doc?.documentId) ||
                  null

                return (
                  <DocumentItem
                    document={doc as DocumentData}
                    key={doc?.documentId}
                    title={docModel ? docModel.label : ''}
                    description={docModel ? docModel.description : ''}
                    isOperator={isOperator}
                    isUploading={isUploading && currentDoc === doc?.documentId}
                    isLoading={
                      (isChangingContractDocumentStatus ||
                        isChangingContractDocumentFile) &&
                      currentDoc === doc?.documentId
                    }
                    onApprove={() => {
                      setCurrentDoc((doc as DocumentData).documentId)
                      approveDocument((doc as DocumentData).documentId)
                    }}
                    onDelete={() => {
                      setCurrentDoc((doc as DocumentData).documentId)
                      deleteDocument((doc as DocumentData).documentId)
                    }}
                    onRefuse={() => {
                      setCurrentDoc((doc as DocumentData).documentId)
                      setShowRefuseDocumentForm(true)
                    }}
                    onUpload={() => {
                      if (inputEl && inputEl.current) {
                        setIsDeposit(false)
                        setIsRentDeposit(false)
                        setCurrentDoc((doc as DocumentData).documentId)
                        inputEl.current.click()
                      }
                    }}
                  />
                )
              })}
          </div>
        </div>
      )}

      <div className={classes.bottomCTA}>
        {contract.status === ContractStatus.CREATED && isOperator && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={!(isOwner && hasFile && hasDetail) || isChangingStatus}
            onClick={async () => {
              await setContractStatusMutation({
                variables: {
                  contractId: contract.id,
                  status: ContractStatus.WAITING_DOCS
                }
              })
            }}
          >
            AVVIA PRATICA
          </Button>
        )}
        {contract.status === ContractStatus.WAITING_DOCS && isOperator && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={
              !(
                isOwner &&
                hasFile &&
                hasDetail &&
                notApprovedDocs.length === 0
              ) || isChangingStatus
            }
            onClick={async () => {
              await setContractStatusMutation({
                variables: {
                  contractId: contract.id,
                  status: ContractStatus.WAITING_SCORING
                }
              })
            }}
          >
            RICHIEDI SCORING
          </Button>
        )}
        {contract.status === ContractStatus.WAITING_SCORING && isOperator && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={
              !(
                isOwner &&
                hasFile &&
                hasDetail &&
                notApprovedDocs.length === 0
              ) || isChangingStatus
            }
            onClick={() => setShowOutcomeForm(true)}
          >
            IMPOSTA L'ESITO
          </Button>
        )}
        {contract.status === ContractStatus.WAITING_DEPOSIT && isOperator && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={
              (contract.outcome !== ContractOutcome.APPROVED_RENT0 &&
                contract.rentDeposit?.status !== DocumentStatus.APPROVED) ||
              (contract.proposal.deposit > 0 &&
                contract.deposit?.status !== DocumentStatus.APPROVED) ||
              isChangingStatus
            }
            onClick={async () => {
              await setContractStatusMutation({
                variables: {
                  contractId: contract.id,
                  status: ContractStatus.WAITING_DIGITAL_SIGN
                }
              })
            }}
          >
            RICHIEDI FIRMA DIGITALE
          </Button>
        )}
        {contract.status === ContractStatus.WAITING_DIGITAL_SIGN && isOperator && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={isChangingStatus}
            onClick={async () => {
              await setContractStatusMutation({
                variables: {
                  contractId: contract.id,
                  status: ContractStatus.CLOSED
                }
              })
            }}
          >
            CHIUDI PRATICA
          </Button>
        )}
      </div>

      <RefuseDocumentForm
        contractId={contract.id}
        documentId={currentDoc as string}
        open={showRefuseDocumentForm}
        onClose={() => setShowRefuseDocumentForm(false)}
      />

      <RefuseDepositForm
        contractId={contract.id}
        open={showRefuseDepositForm}
        onClose={() => setShowRefuseDepositForm(false)}
      />

      <RefuseRentDepositForm
        contractId={contract.id}
        open={showRefuseRentDepositForm}
        onClose={() => setShowRefuseRentDepositForm(false)}
      />

      <ContractDetailForm
        open={showDetailForm}
        contract={contract}
        handleClose={() => setShowDetailForm(false)}
      />

      <OutcomeForm
        hasProposalDeposit={contract.proposal.deposit > 0}
        open={showOutcomeForm}
        contractId={contract.id}
        onClose={() => setShowOutcomeForm(false)}
      />

      <FinalProposalForm
        open={showProposalForm}
        contractId={contract.id}
        proposal={contract.proposal}
        dealerNotes={contract.notes}
        handleClose={() => setShowProposalForm(false)}
      />

      <MessageDialog
        open={showError}
        title="Attenzione"
        onClose={() => setShowError(false)}
      >
        {error}
      </MessageDialog>

      <ConfirmDialog
        onConfirm={async () => {
          await deleteContractMutation({
            variables: {
              contractId: contract.id
            }
          })
        }}
        onClose={() => {
          setShowDeleteContract(false)
        }}
        title="Attenzione"
        open={showDeleteContract}
      >
        Sei sicuro di voler cancellare questa pratica? L'operazione è
        irreversibile.
      </ConfirmDialog>

      <input
        ref={inputEl}
        type="file"
        accept="image/jpeg,image/gif,image/png,application/pdf"
        id="input-upload"
        style={{ display: 'none' }}
        onChange={async e => {
          // Verifica dimensione file
          const files = e.target.files
          if (files && files.length > 0 && files[0].size < 2 * 1024 * 1024) {
            console.log(files[0])

            let extension = ''
            let type = files[0].type

            switch (type) {
              case 'application/pdf':
                extension = 'pdf'
                break
              case 'image/png':
                extension = 'png'
                break
              case 'image/gif':
                extension = 'gif'
                break
              case 'image/jpg':
                extension = 'jpg'
                break
              case 'image/jpeg':
                extension = 'jpeg'
                break
            }

            setIsUploading(true)
            try {
              let filename: string = ''
              if (isDeposit) {
                filename = 'anticipo-contratto'
              } else if (isRentDeposit) {
                filename = 'anticipo-rate'
              } else {
                filename = getDocFilename(currentDoc)
              }
              filename += `.${extension}`

              const urlRequest = await client.query({
                query: GET_FILE_URL_DOCUMENT,
                variables: {
                  r2gClientId: contract.r2gClientId,
                  r2gContractId: contract.r2gContractId,
                  dealerId: contract.dealer.id,
                  filename
                }
              })
              const signedUrl = urlRequest.data.getSignedUrlForDocumentFile
              const fileUrl = signedUrl.split('?')[0]
              const response = await fetch(signedUrl, {
                method: 'PUT',
                body: files[0]
              })
              if (response.ok) {
                if (isDeposit) {
                  setContractDepositFileMutation({
                    variables: {
                      contractId: contract.id,
                      url: fileUrl
                    }
                  })
                } else if (isRentDeposit) {
                  setContractRentDepositFileMutation({
                    variables: {
                      contractId: contract.id,
                      url: fileUrl
                    }
                  })
                } else {
                  setContractDocumentFileMutation({
                    variables: {
                      documentId: currentDoc,
                      contractId: contract.id,
                      url: fileUrl
                    }
                  })
                }
              }
            } catch (err) {
              console.log('Error', err)
              setError(err)
              setIsUploading(false)
              if (inputEl && inputEl.current) inputEl.current.value = ''
            }
          } else {
            setError('La dimensione del file non può superare i 2MB')
            setShowError(true)
          }
          if (inputEl && inputEl.current) inputEl.current.value = ''
          setIsUploading(false)
        }}
      />
    </div>
  )
}

export default ContractDetail
