import React from 'react'

import { Button, Paper, Typography, makeStyles } from '@material-ui/core'

import { getContract_getContract_proposal as ProposalData } from 'generated/schemaTypes'
import numeral from 'numeral'

interface ContractDetailProposalProps {
  proposal: ProposalData
  onUpdateProposal: () => void
  notes: string | null
  readonly: boolean
  isOperator: boolean
  disabled: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    display: 'flex',
    flexWrap: 'wrap'
    // alignContent: 'space-between'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
    width: '100%',
    minHeight: '70px',
    alignItems: 'flex-start',
    borderBottom: `solid 1px ${theme.palette.text.primary}`
  },
  row: {
    marginBottom: '1em'
  },
  block: {
    marginBottom: '2em'
  },
  bigH1: {
    fontSize: '1.4em',
    fontWeight: 700
  }
}))

const ContractDetailProposal: React.FC<ContractDetailProposalProps> = ({
  proposal,
  onUpdateProposal,
  notes,
  readonly,
  isOperator,
  disabled
}) => {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.head}>
        <div>
          <Typography variant="h1">{proposal.months} mesi</Typography>
          <Typography variant="h4">
            {numeral(proposal.km).format()} km/anno
          </Typography>
        </div>
        <div>
          <Typography variant="h1" color="primary">
            <span className={classes.bigH1}>
              {numeral(proposal.rent / 100).format('0,0')} €
            </span>
          </Typography>
        </div>
      </div>
      <div>
        <div className={classes.block}>
          <div className={classes.row}>
            <Typography display="inline" variant="h5">
              Anticipo:{' '}
            </Typography>
            <Typography display="inline" variant="h4">
              {numeral(proposal.deposit / 100).format('0,0.00')} €
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              Codice Infocar:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.infoCar}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              Auto sostitutiva:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.replacementCar ? 'SI' : 'NO'}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              Pneumatici invernali:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.winterTires * 4}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              Pneumatici estivi:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.summerTires * 4}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              Pneumatici all seasons:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.allSeasonTires * 4}
            </Typography>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              RCA:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              SI
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              KASKO:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.kasko}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              ARD:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.ard}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              PAI:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.pai ? 'SI' : 'NO'}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" display="inline">
              Cristalli:{' '}
            </Typography>
            <Typography variant="h4" display="inline">
              {proposal.windows ? 'SI' : 'NO'}
            </Typography>
          </div>
        </div>
        <Typography className={classes.row} variant="h6">
          Messa su strada {numeral(proposal.mss / 100).format('0,0.00')} €
        </Typography>
        <div></div>
        {!readonly &&
          isOperator &&
          (notes ? (
            <div className={classes.row}>
              <Typography
                variant="h4"
                color="secondary"
                style={{ marginTop: 32 }}
              >
                Attenzione! Il concessionario ha richiesto le seguenti
                modifiche:
              </Typography>
              <Typography variant="h6">{notes}</Typography>
            </div>
          ) : (
            <div className={classes.row}>
              <Typography variant="h4" style={{ marginTop: 32 }}>
                Il concessionario non ha richiesto modifiche
              </Typography>
              <Typography variant="h6">{notes}</Typography>
            </div>
          ))}
        {!readonly && isOperator && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onUpdateProposal}
          >
            Modifica
          </Button>
        )}
      </div>
    </Paper>
  )
}

export default ContractDetailProposal
