import React from 'react'

import { SvgIcon, SvgIconProps } from '@material-ui/core'
import { ReactComponent as TrashSVG } from 'assets/trash-icon.svg'
import { ReactComponent as PencilSVG } from 'assets/pencil-icon.svg'
import { ReactComponent as ClipSVG } from 'assets/clip-icon.svg'
import { ReactComponent as DownloadSVG } from 'assets/download-icon.svg'
import { ReactComponent as UploadSVG } from 'assets/upload-icon.svg'

import { ReactComponent as MenuCarSVG } from 'assets/menu-car.svg'
import { ReactComponent as MenuCarActiveSVG } from 'assets/menu-car_active.svg'
import { ReactComponent as MenuUserSVG } from 'assets/menu-user.svg'
import { ReactComponent as MenuUserActiveSVG } from 'assets/menu-user_active.svg'
import { ReactComponent as MenuDealerSVG } from 'assets/menu-dealer.svg'
import { ReactComponent as MenuDealerActiveSVG } from 'assets/menu-dealer_active.svg'
import { ReactComponent as MenuContractSVG } from 'assets/menu-contract.svg'
import { ReactComponent as MenuContractActiveSVG } from 'assets/menu-contract_active.svg'

const TrashIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={TrashSVG} viewBox="0 0 14 22" />
}

const PencilIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} component={PencilSVG} viewBox="0 0 19.369 20.595" />
  )
}

const ClipIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={ClipSVG} viewBox="0 0 18.383 20.074" />
}

const DownloadIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={DownloadSVG} viewBox="0 0 18 20" />
}

const UploadIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={UploadSVG} viewBox="0 0 18 20" />
}

const MenuCarIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={MenuCarSVG} viewBox="0 0 48 42" />
}
const MenuCarActiveIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={MenuCarActiveSVG} viewBox="0 0 48 42" />
}
const MenuDealerIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={MenuDealerSVG} viewBox="0 0 48 48" />
}
const MenuDealerActiveIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} component={MenuDealerActiveSVG} viewBox="0 0 48 48" />
  )
}
const MenuContractIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={MenuContractSVG} viewBox="0 0 48 48" />
}
const MenuContractActiveIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} component={MenuContractActiveSVG} viewBox="0 0 48 48" />
  )
}
const MenuUserIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} component={MenuUserSVG} viewBox="0 0 48 48" />
}
const MenuUserActiveIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props} component={MenuUserActiveSVG} viewBox="0 0 48 48" />
  )
}

export {
  TrashIcon,
  PencilIcon,
  ClipIcon,
  DownloadIcon,
  UploadIcon,
  MenuCarIcon,
  MenuCarActiveIcon,
  MenuContractIcon,
  MenuContractActiveIcon,
  MenuDealerIcon,
  MenuDealerActiveIcon,
  MenuUserIcon,
  MenuUserActiveIcon
}
