import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  MenuItem,
  Typography,
  Button
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CREATE_CONTRACT } from 'mutation'
import { GET_CLIENTS_SIMPLE } from 'query'
import { CustomInputComponent } from 'components'

import {
  // getDocument_getDocument as DocumentType,
  createContract as createContractMutation,
  createContractVariables,
  getClients,
  getClientsVariables
} from 'generated/schemaTypes'

// const getOptionsClient = (
//   clients?: getClients_getClients_clients[]
// ): Array<{ label: string; value: string }> => {
//   if (!clients) return []
//   return clients.map(client => {
//     if (client.__typename === 'ClientCompany') {
//       return {
//         label: client.name,
//         value: client.id
//       }
//     } else {
//       return {
//         label: client.name + client.lastname,
//         value: client.id
//       }
//     }
//   })
// }

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  }
}))

export interface CreateContractFormValues {
  clientId: string
  // documentListId: string
  notes: string
}

const defaultInitialValues = {
  clientId: '',
  notes: ''
  // documentListId: ''
}

const validationSchema = Yup.object({
  clientId: Yup.string().required('Campo obbligatorio')
  // documentListId: Yup.string().required('Campo obbligatorio')
})

interface CreateContractFormProps {
  open: boolean
  proposalId: string
  dealerId: string
  handleClose: () => void
}

const CreateContractForm: React.FC<CreateContractFormProps> = ({
  open,
  proposalId,
  handleClose,
  dealerId
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const classes = useStyles()

  const [createContract] = useMutation<
    createContractMutation,
    createContractVariables
  >(CREATE_CONTRACT, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Contratto creato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
      history.push(`/contracts/${data.createContract.id}`)
    },
    onError: err => {
      console.log('Errore!', err)
    }
    // refetchQueries: [{ query: GET_DOCUMENTS }]
  })

  const {
    data: dataClients
    // loading: loadingClients,
    // error: errorClients
  } = useQuery<getClients, getClientsVariables>(GET_CLIENTS_SIMPLE, {
    variables: { where: { dealerIds: [dealerId] } }
  })

  // const dataClients: Array<any> = []

  const formik = useFormik<CreateContractFormValues>({
    initialValues: defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const variables: any = {
        data: {
          clientId: values.clientId,
          proposalId: proposalId,
          // documentListId: values.documentListId,
          notes: values.notes || null
        }
      }

      await createContract({ variables })
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => {
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '560px', padding: '45px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h3" style={{ marginBottom: '1em' }}>
          Genera nuova pratica
        </Typography>

        {/* ClientId */}
        <CustomInputComponent
          name="clientId"
          error={formik.touched.clientId && !!formik.errors.clientId}
          id="clientId"
          label="Cliente"
          value={formik.values.clientId}
          errorLabel={formik.errors.clientId}
          onChange={formik.handleChange}
          type="select"
        >
          {dataClients?.getClients.clients.map(client => (
            <MenuItem key={client.id} value={client.id}>
              {client.__typename === 'ClientCompany'
                ? client.name
                : client.name + ' ' + client.lastname}
            </MenuItem>
          ))}
        </CustomInputComponent>

        {/* ListId */}
        {/* <CustomInputComponent
          name="documentListId"
          error={
            formik.touched.documentListId && !!formik.errors.documentListId
          }
          id="documentListId"
          label="Lista documenti"
          value={formik.values.documentListId}
          errorLabel={formik.errors.documentListId}
          onChange={formik.handleChange}
          type="select"
        >
          {dataLists?.getLists.map(list => (
            <MenuItem key={list.id} value={list.id}>
              {list.label}
            </MenuItem>
          ))}
        </CustomInputComponent> */}

        {/* <Autocomplete
          // className={classes.autocomplete}
          options={getOptionsClient(dataClients?.getClients.clients)}
          onChange={(event: any, value: any, reason: string) => {
            formik.setFieldValue('clientId', value ? value.id : '')
          }}
          value={formik.values.clientId || null}
          // popupIcon={<ArrowDownSVG />}
          getOptionLabel={(option: any) => option.id}
          getOptionSelected={(option: any, value: any) => {
            return option === formik.values.clientId
          }}
          renderOption={option => <div>{option.label}</div>}
          renderInput={(params: any) => (
            <TextField
              {...params}
              // className={classes.inputDealer}
              label="Seleziona cliente"
              color="secondary"
              variant="outlined"
            />
          )}
        /> */}

        <div>
          {/* Description */}
          <CustomInputComponent
            name="notes"
            type="textarea"
            error={formik.touched.notes && !!formik.errors.notes}
            id="notes"
            label="Descrizione"
            value={formik.values.notes}
            errorLabel={formik.errors.notes}
            onChange={formik.handleChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          Avvia Pratica
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateContractForm
