import React, { ReactNode } from 'react'

import { Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
interface BackProps {
  children: ReactNode
  url: string
}

const Back: React.FC<BackProps> = ({ children, url }) => {
  const history = useHistory()
  return (
    <div style={{ marginBottom: '1em' }}>
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => history.push(url)}
      >
        <ArrowBackIcon /> <Typography variant="h4">{children}</Typography>
      </div>
    </div>
  )
}

export default Back
