import React from 'react'
import { roleVar, userIdVar } from 'cache'
import { useReactiveVar } from '@apollo/client'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Button, Paper, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import {
  AvatarWithLabel,
  CarHeader,
  ButtonAdd,
  CarOwnership,
  Deadline
} from 'components'
import { color } from 'theme'
import { getCars_getCars_cars as CarType } from 'generated/schemaTypes'
import { brandLabel } from 'data/brands'
import numeral from 'numeral'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  col: {
    alignContent: 'space-between',
    flexWrap: 'wrap',
    display: 'flex',
    width: '33.33%'
  },
  col1: {},
  col2: {},
  col3: {
    maxWidth: 250,
    '& > div': {
      width: '100%'
    }
  },
  carHeaderWrapper: {
    marginBottom: theme.spacing(2)
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    marginLeft: -theme.spacing(4),
    marginBottom: -theme.spacing(4)
  },
  thumb: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '50%',
    backgroundPosition: 'center'
  },
  goToAutoWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(80,80,80,0.8)',
    opacity: 0,
    transition: 'opacity 0.25s ease',
    '&:hover': {
      opacity: 1
    }
  },
  goToAuto: {
    position: 'absolute',
    padding: '10px 15px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    minWidth: 220,
    borderRadius: theme.spacing(1),
    border: 'solid 2px white'
  },
  ProposalRecap: {
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '50%'
    },
    '& > div:last-child': {
      borderLeft: `solid 1px ${color.MediumLightGrey}`,
      textAlign: 'right'
    }
  },
  addButton: {
    marginLeft: '20px'
  },
  sold: {
    background: theme.palette.primary.main,
    padding: '0.5em',
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top right',
    transform: 'translate(-25%,0%) rotate(-45deg)',
    '& h3': {
      color: 'white'
    }
  }
}))

const ProposalRecap: React.FC<{
  rent: number
  km: number
  months: number
  deposit: number
}> = ({ rent, deposit, km, months }) => {
  const classes = useStyles()
  // const kmTotali = (km * months) / 12
  return (
    <div className={classes.ProposalRecap}>
      <div>
        <Typography variant="h2">{months} mesi</Typography>
        <Typography variant="h5">{numeral(km).format()} km annuali</Typography>
        <Typography variant="h5">
          Anticipo {numeral(deposit / 100).format('0,0.00')} €
        </Typography>
      </div>
      <div>
        <Typography variant="h1">{Math.trunc(rent / 100)}€/m</Typography>
      </div>
    </div>
  )
}

const CarSummaryCard: React.FC<{
  car: CarType
  onCreateProposal: () => void
}> = ({ car, onCreateProposal }) => {
  const classes = useStyles()
  const history = useHistory()
  const roleCache = useReactiveVar(roleVar)
  const userIdCache = useReactiveVar(userIdVar)
  const isDealer = roleCache === 'DEALER'
  return (
    <Paper className={classes.root}>
      <div className={clsx(classes.col1, classes.col)}>
        {car.sold && (
          <div className={classes.sold}>
            <Typography color="inherit" variant="h3">
              Venduta
            </Typography>
          </div>
        )}
        <div className={classes.carHeaderWrapper}>
          <CarHeader
            model={car.model}
            brand={brandLabel(car.brand)}
            trimLevel={car.trimLevel}
            registration={car.registration}
            insertDate={car.insertDate}
            km={car.km}
          />
        </div>
        <div className={classes.imageWrapper}>
          <RouterLink to={`/cars/${car.id}`}>
            <div
              className={classes.thumb}
              style={{
                backgroundImage: `url(${car.image || '/car.png'})`
              }}
            ></div>

            <div className={classes.goToAutoWrapper}>
              <Typography
                className={classes.goToAuto}
                align="center"
                variant="h2"
              >
                Mostra auto
              </Typography>
            </div>
          </RouterLink>
        </div>
      </div>
      <div className={clsx(classes.col2, classes.col)}>
        <div style={{ width: '100%' }}>
          <Typography display="inline" variant="h1">
            {car.proposals.length}&nbsp;
          </Typography>
          <Typography display="inline" variant="h5">
            {car.proposals.length === 1 ? 'Proposta' : 'Proposte'}
          </Typography>
        </div>
        {car.proposals.length === 1 && (
          <>
            <ProposalRecap
              km={car.proposals[0].km}
              rent={car.proposals[0].rent}
              months={car.proposals[0].months}
              deposit={car.proposals[0].deposit}
            />
          </>
        )}
        {car.proposals.length > 1 && (
          <>
            <ProposalRecap
              km={car.proposals[0].km}
              rent={car.proposals[0].rent}
              months={car.proposals[0].months}
              deposit={car.proposals[0].deposit}
            />
            <ProposalRecap
              km={car.proposals[car.proposals.length - 1].km}
              rent={car.proposals[car.proposals.length - 1].rent}
              months={car.proposals[car.proposals.length - 1].months}
              deposit={car.proposals[car.proposals.length - 1].deposit}
            />
          </>
        )}
        <div style={{ display: 'flex', width: '100%' }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={() => {
              history.push(`/cars/${car.id}?tab=proposalsList`)
            }}
            disabled={car.proposals.length === 0}
          >
            Mostra proposte
          </Button>
          {!isDealer && (
            <ButtonAdd
              style={{ marginLeft: '16px' }}
              disabled={
                car.proposals.length > 49 ||
                !car.operator ||
                (car.operator && car.operator.id !== userIdCache) ||
                car.sold
              }
              onClick={onCreateProposal}
            />
          )}
        </div>
      </div>
      <div className={clsx(classes.col3, classes.col)}>
        {!isDealer && (
          <div>
            <CarOwnership
              operatorId={car.operator ? car.operator.id : null}
              carId={car.id}
            />
          </div>
        )}
        <AvatarWithLabel
          id={car.dealer.id}
          smallLabel={car.dealer.displayName[0]}
          fullLabel={car.dealer.displayName}
        />
        <div>
          <Deadline registrationDate={new Date(car.registration)} />
        </div>
      </div>
    </Paper>
  )
}

export default CarSummaryCard
