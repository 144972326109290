import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { CREATE_OPERATOR, UPDATE_OPERATOR } from 'mutation'
import { CustomInputComponent } from 'components'
import { color } from 'theme'

import {
  getOperator_getOperator as OperatorType,
  createOperatorVariables,
  updateOperatorVariables,
  createOperator,
  updateOperator
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  baseInfoWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    gridTemplateRows: 'auto auto auto',
    position: 'relative',
    marginBottom: 30
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  grid: {
    display: 'grid',
    margin: 'auto',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    columnGap: 50
  },
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignContent: 'space-between',
    '& > *': {
      width: '100%',
      '& > *': {
        width: '100%'
      }
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 360,
    height: 180,
    backgroundPosition: 'center'
  }
}))

export interface OperatorFormValues {
  name: string
  lastname: string
  password: string
  email: string
}

const defaultInitialValues = {
  password: '',
  name: '',
  lastname: '',
  email: ''
}

const getValidationSchema = (create: boolean): Yup.ObjectSchema => {
  let schema: any = {
    name: Yup.string().required('Campo obbligatorio'),
    lastname: Yup.string().required('Campo obbligatorio'),
    email: Yup.string()
      .email('Indirizzo email non valido')
      .required('Campo obbligatorio')
  }
  if (create) schema.password = Yup.string().required('Campo obbligatorio')
  return Yup.object(schema)
}

const parseInitialValue = (input: OperatorType): OperatorFormValues => {
  return {
    password: '',
    name: input.name,
    lastname: input.lastname,
    email: input.email
  }
}

interface OperatorFormProps {
  open: boolean
  handleClose: () => void
  operator: OperatorType | null
}

const ClientForm: React.FC<OperatorFormProps> = ({
  open,
  operator,
  handleClose
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const updateMode = !!operator

  const classes = useStyles()

  const initialValues = operator ? parseInitialValue(operator) : null

  const [createOperatorMutation] = useMutation<
    createOperator,
    createOperatorVariables
  >(CREATE_OPERATOR, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Operatore creato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
      history.push(`/operators/${data.createOperator.id}`)
    },
    onError: err => {
      console.log('Errore!', err)
    }
  })

  const [updateOperatorMutation] = useMutation<
    updateOperator,
    updateOperatorVariables
  >(UPDATE_OPERATOR, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Concessionario modificato', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore', err)
    }
  })

  const formik = useFormik<OperatorFormValues>({
    initialValues: initialValues || defaultInitialValues,
    validationSchema: getValidationSchema(!updateMode),
    enableReinitialize: true,
    onSubmit: async values => {
      let variables: any = {
        data: {
          name: values.name,
          lastname: values.lastname,
          email: values.email
        }
      }
      if (updateMode) {
        variables.id = operator?.id
        await updateOperatorMutation({ variables })
      } else {
        variables.data.password = values.password
        await createOperatorMutation({ variables })
      }
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { maxWidth: '1264px', padding: '50px 80px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Informazioni di base
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* NOME */}
            <CustomInputComponent
              name="name"
              error={formik.touched.name && !!formik.errors.name}
              id="name"
              label="Nome*"
              value={formik.values.name}
              errorLabel={formik.errors.name}
              onChange={formik.handleChange}
            />
            {/* COGNOME */}
            <CustomInputComponent
              name="lastname"
              error={formik.touched.lastname && !!formik.errors.lastname}
              id="lastname"
              label="Cognome*"
              value={formik.values.lastname}
              errorLabel={formik.errors.lastname}
              onChange={formik.handleChange}
            />
            {/* EMAIL */}
            <CustomInputComponent
              name="email"
              error={formik.touched.email && !!formik.errors.email}
              id="email"
              label="Email*"
              value={formik.values.email}
              errorLabel={formik.errors.email}
              onChange={formik.handleChange}
            />
            {!updateMode && (
              <CustomInputComponent
                name="password"
                error={formik.touched.password && !!formik.errors.password}
                id="password"
                label="Password*"
                type="password"
                value={formik.values.password}
                errorLabel={formik.errors.password}
                onChange={formik.handleChange}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          {updateMode ? 'Modifica operatore' : 'Aggiungi operatore'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientForm
