import React, { ReactNode } from 'react'

import { makeStyles } from '@material-ui/core'
import { TOOLBAR_H } from 'theme'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'url("empty-bg.png")',
    margin: '0 auto',
    position: 'relative',
    height: `calc(100vh - ${TOOLBAR_H + theme.spacing(2)}px)`,
    backgroundSize: '100% auto',
    backgroundPosition: 'top center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      height: `calc(100vh - ${TOOLBAR_H + theme.spacing(4)}px)`
    }
  }
}))

const NoElements: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        <div>
          <img src="car-empty.png" alt="" />
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default NoElements
