import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'

import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SET_SETTINGS } from 'mutation'
import { GET_SETTINGS } from 'query'
import { CustomInputComponent } from 'components'
import { color } from 'theme'

import {
  getSettings_getSettings as SettingsType,
  setSettings,
  setSettingsVariables
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  baseInfoWrapper: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    gridTemplateRows: 'auto auto auto',
    position: 'relative',
    marginBottom: 30
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  grid: {
    display: 'grid',
    margin: 'auto',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    columnGap: 50
  },
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignContent: 'space-between',
    '& > *': {
      width: '100%',
      '& > *': {
        width: '100%'
      }
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 360,
    height: 180,
    backgroundPosition: 'center'
  }
}))

export interface SettingsFormValues {
  emailScoringR2G: string
  emailDigitalSignR2G: string
  vat: string
}

const getInitialValues = (data: SettingsType): SettingsFormValues => {
  return {
    emailScoringR2G: data.emailScoringR2G || '',
    emailDigitalSignR2G: data.emailDigitalSignR2G || '',
    vat: data.vat ? data.vat.toString() : '22'
  }
}

interface SettingsFormProps {
  open: boolean
  onClose: () => void
  settings: SettingsType
}

const validationSchema = Yup.object({
  emailDigitalSignR2G: Yup.string().email('Indirizzo email non valido'),
  emailScoringR2G: Yup.string().email('Indirizzo email non valido')
})

const SettingsForm: React.FC<SettingsFormProps> = ({
  open,
  settings,
  onClose
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const [setSettingsMutation] = useMutation<setSettings, setSettingsVariables>(
    SET_SETTINGS,
    {
      onCompleted: async data => {
        formik.resetForm()
        enqueueSnackbar('Settings aggiornati', {
          variant: 'success'
        })
        formik.resetForm()
        onClose()
      },
      onError: err => {
        console.log('Errore!', err)
      },
      refetchQueries: [{ query: GET_SETTINGS }]
    }
  )

  const formik = useFormik<SettingsFormValues>({
    initialValues: getInitialValues(settings),
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      await setSettingsMutation({
        variables: {
          emailScoringR2G: values.emailScoringR2G || null,
          emailDigitalSignR2G: values.emailDigitalSignR2G || null,
          vat: values.vat ? parseInt(values.vat) : null
        }
      })
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        formik.resetForm()
        onClose()
      }}
      PaperProps={{
        style: { maxWidth: '1264px', padding: '50px 80px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Informazioni di base
          </Typography>
          <div className={classes.baseInfoWrapper}>
            {/* EMAIL */}
            <CustomInputComponent
              name="emailScoringR2G"
              error={
                formik.touched.emailScoringR2G &&
                !!formik.errors.emailScoringR2G
              }
              id="emailScoringR2G"
              label="Email scoring Rent2Go"
              value={formik.values.emailScoringR2G}
              errorLabel={formik.errors.emailScoringR2G}
              onChange={formik.handleChange}
            />
            {/* EMAIL */}
            <CustomInputComponent
              name="emailDigitalSignR2G"
              error={
                formik.touched.emailDigitalSignR2G &&
                !!formik.errors.emailDigitalSignR2G
              }
              id="emailDigitalSignR2G"
              label="Email firma digitale Rent2Go"
              value={formik.values.emailDigitalSignR2G}
              errorLabel={formik.errors.emailDigitalSignR2G}
              onChange={formik.handleChange}
            />
            {/* EMAIL */}
            <CustomInputComponent
              name="vat"
              error={formik.touched.vat && !!formik.errors.vat}
              id="vat"
              type="number"
              label="Percentuale IVA"
              value={formik.values.vat}
              errorLabel={formik.errors.vat}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          Aggiorna settings
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SettingsForm
