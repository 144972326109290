import React, { ReactNode } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'

interface MessageDialogProps {
  onClose: () => void
  title: string
  children: ReactNode
  open: boolean
  cta?: string
}

const MessageDialog: React.FC<MessageDialogProps> = ({
  onClose,
  title,
  children,
  open,
  cta = 'CONTINUA'
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">{children}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small" autoFocus>
          {cta}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageDialog
