import React from 'react'

import {
  Button,
  IconButton,
  IconButtonProps,
  makeStyles
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { color } from 'theme'
import { TrashIcon, PencilIcon } from 'components'
import { PauseCircle, PlayCircle } from 'mdi-material-ui'

const useStyles = makeStyles(theme => ({
  icon: {
    transform: 'scale(0.7)',
    [theme.breakpoints.up('xl')]: {
      transform: 'scale(1)'
    }
  },
  modify: {
    color: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  suspend: {
    color: theme.palette.secondary.main,
    '& svg': {
      fill: theme.palette.secondary.main
    }
  },
  activate: {
    color: theme.palette.secondary.main,
    '& svg': {
      fill: theme.palette.secondary.main
    }
  },
  delete: {
    color: theme.palette.error.main,
    '& svg': {
      fill: theme.palette.error.main
    }
  },
  add: {
    padding: 13,
    backgroundColor: theme.palette.primary.main,
    fill: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '&.Mui-disabled': {
      backgroundColor: color.LightGrey
    },
    [theme.breakpoints.up('xl')]: {
      padding: 16
    }
  }
}))

const ButtonModify: React.FC<{
  label?: string
  onClick: () => void
  disabled?: boolean
}> = ({ label, onClick, disabled = false }) => {
  const classes = useStyles({ disabled })
  return label ? (
    <Button
      className={classes.modify}
      disabled={disabled}
      startIcon={<PencilIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    <IconButton
      className={classes.modify}
      onClick={onClick}
      disabled={disabled}
    >
      <PencilIcon />
    </IconButton>
  )
}

const ButtonSuspend: React.FC<{
  label?: string
  onClick: () => void
  disabled?: boolean
}> = ({ label, onClick, disabled = false }) => {
  const classes = useStyles({ disabled })
  return label ? (
    <Button
      className={classes.suspend}
      disabled={disabled}
      startIcon={<PauseCircle />}
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    <IconButton
      className={classes.suspend}
      onClick={onClick}
      disabled={disabled}
    >
      <PencilIcon />
    </IconButton>
  )
}
const ButtonActivate: React.FC<{
  label?: string
  onClick: () => void
  disabled?: boolean
}> = ({ label, onClick, disabled = false }) => {
  const classes = useStyles({ disabled })
  return label ? (
    <Button
      className={classes.activate}
      disabled={disabled}
      startIcon={<PlayCircle />}
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    <IconButton
      className={classes.activate}
      onClick={onClick}
      disabled={disabled}
    >
      <PencilIcon />
    </IconButton>
  )
}

const ButtonDelete: React.FC<{
  label?: string
  onClick: () => void
  disabled?: boolean
}> = ({ label, onClick, disabled = false }) => {
  return label ? (
    <Button
      color="secondary"
      disabled={disabled}
      startIcon={<TrashIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    <IconButton color="secondary" onClick={onClick} disabled={disabled}>
      <TrashIcon />
      {/* <TrashSVG className={classes.icon} /> */}
    </IconButton>
  )
}

const ButtonAdd: React.FC<IconButtonProps> = props => {
  const classes = useStyles({ disabled: props.disabled || false })
  return (
    <IconButton
      {...props}
      color="primary"
      className={classes.add}
      disabled={props.disabled}
    >
      <AddIcon />
    </IconButton>
  )
}

export { ButtonActivate, ButtonDelete, ButtonModify, ButtonAdd, ButtonSuspend }
