import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useReactiveVar } from '@apollo/client'

import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { UPDATE_PASSWORD } from 'mutation'
import { CustomInputComponent } from 'components'
import { color } from 'theme'

import { updatePassword, updatePasswordVariables } from 'generated/schemaTypes'
import { userIdVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  baseInfoWrapper: {
    // display: 'grid',
    // justifyContent: 'space-between',
    // gridTemplateColumns: '280px 280px 280px',
    // gridTemplateRows: 'auto auto auto',
    // position: 'relative',
    // marginBottom: 30
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  },
  grid: {
    display: 'grid',
    margin: 'auto',
    justifyContent: 'space-between',
    gridTemplateColumns: '280px 280px 280px',
    columnGap: 50
  },
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    alignContent: 'space-between',
    '& > *': {
      width: '100%',
      '& > *': {
        width: '100%'
      }
    }
  },
  addButton: {
    position: 'absolute',
    background: color.BlueBright,
    color: 'white',
    right: -50,
    top: 7,
    padding: 0,
    height: 40,
    width: 40,
    '&.Mui-disabled': {
      background: color.LightGrey,
      color: color.MediumLightGrey
    }
  },
  deleteButton: {
    position: 'absolute',
    top: -18,
    right: -11
  },
  calculatedPrice: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 360,
    height: 180,
    backgroundPosition: 'center'
  }
}))

export interface PasswordFormValues {
  password: string
  confirmPassword: string
}

const defaultInitialValues = {
  password: '',
  confirmPassword: ''
}

interface PasswordFormProps {
  open: boolean
  handleClose: () => void
}

const ClientForm: React.FC<PasswordFormProps> = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const userIdCache = useReactiveVar(userIdVar)
  const classes = useStyles()
  const [updatePasswordMutation] = useMutation<
    updatePassword,
    updatePasswordVariables
  >(UPDATE_PASSWORD, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar('Password modificata', {
        variant: 'success'
      })
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore!', err)
    }
  })

  const formik = useFormik<PasswordFormValues>({
    initialValues: defaultInitialValues,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'La password deve essere lunga almeno 8 caratteri')
        .required('Campo obbligatorio'),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Le password non coincidono!')
        .required('Campo obbligatorio')
    }),

    enableReinitialize: true,
    onSubmit: async values => {
      let variables: any = {
        userId: userIdCache,
        password: values.password
      }
      await updatePasswordMutation({ variables })
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { padding: '50px 80px', boxShadow: 'contentBox', maxWidth: 500 }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '1em' }}>
            Modifica password
          </Typography>
          <div className={classes.baseInfoWrapper}>
            <CustomInputComponent
              name="password"
              error={formik.touched.password && !!formik.errors.password}
              id="password"
              label="Password"
              value={formik.values.password}
              errorLabel={formik.errors.password}
              onChange={formik.handleChange}
            />
            <CustomInputComponent
              name="confirmPassword"
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              id="confirmPassword"
              label="Conferma password"
              value={formik.values.confirmPassword}
              errorLabel={formik.errors.confirmPassword}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          Modifica password
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientForm
