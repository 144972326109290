import React from 'react'

import { Paper, makeStyles, Typography } from '@material-ui/core'

import {
  getDealer_getDealer as DealerData,
  UserStatus
} from 'generated/schemaTypes'
import { ButtonActivate, ButtonModify, ButtonSuspend } from 'components'

interface DealerDetailProps {
  dealer: DealerData
  isOperator?: boolean
  onUpdateDealer: () => void
  onActivateDealer?: () => void
  onSuspendDealer?: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4)
  },
  data: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '50%',
      marginBottom: 25
    }
  },
  ctaWrapper: {
    margin: '20px 0 40px'
  }
}))

const DealerDetail: React.FC<DealerDetailProps> = ({
  dealer,
  onUpdateDealer,
  onActivateDealer = () => {},
  onSuspendDealer = () => {},
  isOperator = false
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h1">
        {dealer.company}
        {dealer.status === UserStatus.SUSPENDED && ' (sospeso)'}
      </Typography>

      <div className={classes.ctaWrapper}>
        <ButtonModify
          label="Modifica concessionario"
          onClick={onUpdateDealer}
        />
        {isOperator &&
          (dealer.status === UserStatus.ACTIVE ? (
            <>
              <ButtonSuspend
                label="Sospendi concessionario"
                onClick={onSuspendDealer}
              />
            </>
          ) : (
            <ButtonActivate
              label="Attiva concessionario"
              onClick={onActivateDealer}
            />
          ))}
      </div>

      <div className={classes.data}>
        <Typography variant="h4">Indirizzo</Typography>
        <Typography variant="h5">
          {dealer.address.addressLine}, {dealer.address.zip}{' '}
          {dealer.address.city} ({dealer.address.province})
        </Typography>
        <Typography variant="h4">Email</Typography>
        <Typography variant="h5">{dealer.email}</Typography>

        <Typography variant="h4">Telefono fisso</Typography>
        <Typography variant="h5">{dealer.phone || '-'}</Typography>

        <Typography variant="h4">Codice fiscale</Typography>
        <Typography variant="h5">{dealer.cf}</Typography>

        <Typography variant="h4">Partita Iva</Typography>
        <Typography variant="h5">{dealer.pi}</Typography>

        <Typography variant="h4">
          Codice univoco fatturazione elettronica
        </Typography>
        <Typography variant="h5">{dealer.sdi}</Typography>

        <Typography variant="h4">IBAN</Typography>
        <Typography variant="h5">{dealer.iban}</Typography>

        <Typography variant="h4">Contatto principale</Typography>
        <Typography variant="h5">
          {dealer.primaryContact.name} {dealer.primaryContact.lastname}{' '}
          {dealer.primaryContact.phone}
        </Typography>

        <Typography variant="h4">Contatto secondario</Typography>
        <Typography variant="h5">
          {dealer.secondaryContact
            ? `${dealer.secondaryContact.name} ${dealer.secondaryContact.lastname} ${dealer.secondaryContact.phone}`
            : '-'}
        </Typography>
      </div>
    </Paper>
  )
}

export default DealerDetail
