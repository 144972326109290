import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { color } from 'theme'

interface StyleProps {
  isActive: boolean
}

const useStyles = makeStyles(theme => ({
  root: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 8,
    margin: '0 3px',
    backgroundColor: props.isActive ? color.BlueBright : 'transparent',
    '& h6': {
      padding: '3px 5px',
      color: props.isActive ? 'white' : color.DarkGrey
    },
    [theme.breakpoints.down('lg')]: {}
  })
}))

const FilterButton: React.FC<{
  active: boolean
  onClick: () => void
  label: string
}> = ({ active, onClick, label }) => {
  const classes = useStyles({ isActive: active })
  return (
    <div className={classes.root} onClick={onClick}>
      <Typography variant="h6" display="inline">
        {label}
      </Typography>
    </div>
  )
}

export default FilterButton
