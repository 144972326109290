const priceStringToNumber = (price: string | number): number => {
  if (!price) return 0
  return parseInt(price.toString().replace('.', ''))
}
const priceNumberToString = (price: number | undefined): string => {
  if (price === 0) return '0.00'
  if (!price) return ''
  const priceString = price.toString()
  return [
    priceString.slice(0, priceString.length - 2),
    '.',
    priceString.slice(priceString.length - 2, priceString.length)
  ].join('')
}

const uuidTo16Index = (id: string): number => {
  return parseInt(id[0], 16)
}

// const sendFile = async (file: File, signedUrl: string): void => {

// }

export { priceStringToNumber, priceNumberToString, uuidTo16Index }
