import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  MenuItem,
  Typography,
  Button
} from '@material-ui/core'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SET_CONTRACT_DETAIL } from 'mutation'
import { GET_LISTS, GET_CONTRACT } from 'query'
import { CustomInputComponent } from 'components'

import {
  // getDocument_getDocument as DocumentType,
  setContractDetail as setContractDetailMutation,
  setContractDetailVariables,
  // setContractStatus as setContractStatusMutation,
  // setContractStatusVariables,
  getContract_getContract as ContractType,
  getLists
} from 'generated/schemaTypes'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(6, 17)
  },
  dialogContent: {
    overflow: 'visible',
    padding: 0
  }
}))

export interface ContractDetailFormValues {
  documentListId: string
  r2gContractId: string
  r2gClientId: string
}

const defaultInitialValues = {
  documentListId: '',
  r2gContractId: '',
  r2gClientId: ''
}

const validationSchema = Yup.object({
  documentListId: Yup.string().required('Campo obbligatorio'),
  r2gContractId: Yup.string().required('Campo obbligatorio'),
  r2gClientId: Yup.string().required('Campo obbligatorio')
})

interface ContractDetailFormProps {
  open: boolean
  contract: ContractType
  handleClose: () => void
}

const ContractDetailForm: React.FC<ContractDetailFormProps> = ({
  open,
  contract,
  handleClose
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()

  const update = !!(
    contract.r2gClientId &&
    contract.r2gContractId &&
    contract.documentList
  )

  const [setContractDetail] = useMutation<
    setContractDetailMutation,
    setContractDetailVariables
  >(SET_CONTRACT_DETAIL, {
    onCompleted: async data => {
      formik.resetForm()
      enqueueSnackbar(
        update
          ? 'Dati della pratica aggiornati'
          : 'Dati della pratica inseriti',
        {
          variant: 'success'
        }
      )
      formik.resetForm()
      handleClose()
    },
    onError: err => {
      console.log('Errore!', err)
    },
    refetchQueries: update
      ? []
      : [{ query: GET_CONTRACT, variables: { contractId: contract.id } }]
  })

  const { data: dataLists, loading: loadingLists } = useQuery<getLists>(
    GET_LISTS
  )

  const formik = useFormik<ContractDetailFormValues>({
    initialValues: update
      ? {
          r2gClientId: contract.r2gClientId as string,
          r2gContractId: contract.r2gContractId as string,
          documentListId: contract.documentList?.id as string
        }
      : defaultInitialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const variables: any = {
        contractId: contract.id,
        documentListId: values.documentListId,
        r2gContractId: values.r2gContractId,
        r2gClientId: values.r2gClientId
      }

      await setContractDetail({ variables })
    }
  })

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => {
        formik.resetForm()
        handleClose()
      }}
      PaperProps={{
        style: { maxWidth: '560px', padding: '45px' }
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h3" style={{ marginBottom: '1em' }}>
          Inserici i dati della pratica
        </Typography>

        {/* ID Cliente Rent2Go */}
        <CustomInputComponent
          name="r2gClientId"
          error={formik.touched.r2gClientId && !!formik.errors.r2gClientId}
          id="r2gClientId"
          label="ID Cliente Rent2Go"
          value={formik.values.r2gClientId}
          errorLabel={formik.errors.r2gClientId}
          onChange={formik.handleChange}
        />

        {/* ID Pratica Rent2Go */}
        <CustomInputComponent
          name="r2gContractId"
          error={formik.touched.r2gContractId && !!formik.errors.r2gContractId}
          id="r2gContractId"
          label="ID Pratica Rent2Go"
          value={formik.values.r2gContractId}
          errorLabel={formik.errors.r2gContractId}
          onChange={formik.handleChange}
        />

        {/* ListId */}
        {!loadingLists && (
          <CustomInputComponent
            disabled={update}
            name="documentListId"
            error={
              formik.touched.documentListId && !!formik.errors.documentListId
            }
            id="documentListId"
            label="Lista documenti"
            value={formik.values.documentListId}
            errorLabel={formik.errors.documentListId}
            onChange={formik.handleChange}
            type="select"
          >
            {dataLists?.getLists.map(list => (
              <MenuItem key={list.id} value={list.id}>
                {list.label}
              </MenuItem>
            ))}
          </CustomInputComponent>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            formik.submitForm()
          }}
        >
          {update ? 'Aggiorna dati' : 'Inserisci dati'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContractDetailForm
