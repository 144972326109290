import React, { ReactNode } from 'react'

import { makeStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ApplicationBar, VerticalNav } from 'components'
import { TOOLBAR_H } from 'theme'
import { roleVar } from 'cache'
import { useReactiveVar } from '@apollo/client'

interface StyleProps {
  showFilters: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    width: '100%',
    overflow: 'hidden'
  },

  navigationWrapper: (props: StyleProps) => ({
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    top: TOOLBAR_H + theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      left: theme.spacing(4),
      bottom: theme.spacing(4),
      top: TOOLBAR_H + theme.spacing(4)
    }
  }),
  contentWrapper: (props: StyleProps) => ({
    marginLeft: theme.spacing(4) + 117,
    marginRight: theme.spacing(2),
    paddingTop: TOOLBAR_H + theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(8) + 124,
      marginRight: theme.spacing(4),
      paddingTop: TOOLBAR_H + theme.spacing(4)
    }
  })
}))

const PageLayout: React.FC<{ children?: ReactNode; hideFilters?: boolean }> = ({
  children = null,
  hideFilters = false
}) => {
  // const { data } = useQuery(GUI)
  // const { loading: userLoading, data, error } = useQuery(CURRENT_USER)
  const roleCache = useReactiveVar(roleVar)
  // Filtri visibili solo all'operatore/admin (a meno che non siano disabilitati dalla view)
  const showFilters = roleCache !== 'DEALER' && !hideFilters

  const classes = useStyles({ showFilters })
  // if (userLoading) return <FullscreenLoader />
  // if (error) return <div>Error!</div>
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <ApplicationBar />
        <main className={classes.main}>
          <div className={classes.navigationWrapper}>
            <VerticalNav />
          </div>
          <div className={classes.contentWrapper}>{children}</div>
        </main>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default PageLayout
