import React, { useState } from 'react'
import { roleVar, userIdVar } from 'cache'
import {
  useQuery,
  useApolloClient,
  useMutation,
  useReactiveVar
} from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Paper, Typography, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
  createProposal,
  createProposalVariables,
  getCar_getCar as CarType,
  getProposal_getProposal as ProposalType,
  me as UserType,
  me_me_Operator as OperatorType
} from 'generated/schemaTypes'
import {
  AddFab,
  ButtonAdd,
  AvatarWithLabel,
  ButtonDelete,
  ButtonModify,
  CarHeader,
  CarThumbnail,
  CarInfoTable,
  CarOptionalsList,
  CarOwnership,
  ConfirmDialog,
  CreateContractForm,
  ClientPrivateForm,
  ClientCompanyForm,
  Deadline,
  FileOffer,
  FullscreenLoader,
  ProposalCard,
  ProposalForm
} from 'components'
import { GET_CAR, CURRENT_USER } from 'query'
import { DELETE_PROPOSAL, CREATE_PROPOSAL } from 'mutation'
import { brandLabel } from 'data/brands'
import AddProposalCard from './addProposalCard'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  flex: {
    display: 'flex',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    '& > *': {
      width: '100%'
    }
  },
  grid2Col: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      columnGap: theme.spacing(4)
    },
    [theme.breakpoints.up('xl')]: {
      columnGap: theme.spacing(6)
    }
  },
  innerGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 20
  },
  imageWrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'middle',
    height: 0,
    paddingBottom: '50%',
    marginLeft: -theme.spacing(4),
    marginBottom: -theme.spacing(4),
    '& img': {
      display: 'block'
    }
  },
  ctaWrapper: {
    margin: '20px 0 40px'
  },
  activeButton: {
    color: 'white',
    background: theme.palette.primary.main,
    '&:hover': {
      color: 'inherit'
    }
  },
  inactiveButton: {
    // color: 'gray'
  }
}))

function useQueryURL() {
  return new URLSearchParams(useLocation().search)
}

const CarDetail: React.FC<{
  car: CarType
  onDeleteCar: () => void
  onUpdateCar: () => void
}> = ({ car, onUpdateCar, onDeleteCar }) => {
  const classes = useStyles()
  const query = useQueryURL()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { data: userData } = useQuery<UserType>(CURRENT_USER)
  const roleCache = useReactiveVar(roleVar)
  const userIdCache = useReactiveVar(userIdVar)
  const isOperator = ['OPERATOR', 'ADMIN'].includes(roleCache)
  const client = useApolloClient()

  const [
    duplicateProposalMutation,
    { loading: isDuplicatingProposal }
  ] = useMutation<createProposal, createProposalVariables>(CREATE_PROPOSAL, {
    onCompleted: async data => {
      enqueueSnackbar('Proposta duplicata', {
        variant: 'success'
      })
    },
    onError: err => {
      console.log('Errore', err)
    },
    refetchQueries: [{ query: GET_CAR, variables: { id: car.id } }]
  })

  const initialTab: string | null = query.get('tab')
  const [activePanel, setActivePanel] = useState<string>(
    initialTab || 'carDetail'
  )
  const [showClientForm, setShowClientForm] = useState(false)
  const [formType, setFormType] = useState<'private' | 'company'>('private')
  const [showProposalForm, setShowProposalForm] = useState(false)
  const [showContractForm, setShowContractForm] = useState(false)
  const [showDeleteProposal, setShowDeleteProposal] = useState(false)
  const [currentProposal, setCurrentProposal] = useState<
    ProposalType | undefined
  >(undefined)

  const duplicateProposal = async (proposal: ProposalType) => {
    await duplicateProposalMutation({
      variables: {
        data: {
          carId: car.id,
          months: proposal.months,
          km: proposal.km,
          mss: proposal.mss,
          rent: proposal.rent,
          infoCar: proposal.infoCar,
          winterTires: proposal.winterTires,
          summerTires: proposal.summerTires,
          allSeasonTires: proposal.allSeasonTires,
          deposit: proposal.deposit,
          replacementCar: proposal.replacementCar,
          windows: proposal.windows,
          kasko: proposal.kasko,
          ard: proposal.ard,
          pai: proposal.pai
        }
      }
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.grid2Col}>
        <div className={classes.innerGrid}>
          <CarThumbnail
            src={car.image}
            carId={car.id}
            dealerId={car.dealer.id}
            sold={car.sold}
          />
          <CarHeader
            model={car.model}
            brand={brandLabel(car.brand)}
            trimLevel={car.trimLevel}
            registration={car.registration}
            km={car.km}
            insertDate={car.insertDate}
          />
        </div>

        <div className={classes.innerGrid}>
          <div className={classes.flex} style={{ width: 250 }}>
            <div>
              <Typography display="inline" variant="h1">
                {car.proposals.length}&nbsp;
              </Typography>
              <Typography display="inline" variant="h5">
                {car.proposals.length === 1 ? 'proposta' : 'proposte'}
              </Typography>
            </div>
            {isOperator && !car.sold && (
              <>
                <Typography variant="h6">
                  Ci sono {car.proposals.length}{' '}
                  {car.proposals.length === 1 ? 'proposta' : 'proposte'} per
                  questa auto,{' '}
                  {car.proposals.length === 50
                    ? 'non puoi aggiungerne altre.'
                    : `puoi aggiungerne ancora ${50 - car.proposals.length}`}
                </Typography>

                <div>
                  <ButtonAdd
                    size="small"
                    disabled={
                      car.proposals.length > 49 ||
                      !car.operator ||
                      (car.operator && car.operator.id !== userIdCache)
                    }
                  />
                  <Button
                    size="medium"
                    color="primary"
                    onClick={() => {
                      setCurrentProposal(undefined)
                      setShowProposalForm(true)
                    }}
                    disabled={
                      car.proposals.length > 49 ||
                      !car.operator ||
                      (car.operator && car.operator.id !== userIdCache)
                    }
                  >
                    Aggiungi proposta
                  </Button>
                </div>
              </>
            )}
          </div>
          <div style={{ width: 250 }} className={classes.flex}>
            <div>
              {isOperator && (
                <CarOwnership
                  operatorId={car.operator ? car.operator.id : null}
                  carId={car.id}
                />
              )}
            </div>
            <div>
              <AvatarWithLabel
                fullLabel={car.dealer.displayName}
                id={car.dealer.id}
                smallLabel={car.dealer.displayName[0]}
              />
            </div>
            <div>
              {!car.sold && (
                <Deadline registrationDate={new Date(car.registration)} />
              )}
            </div>
          </div>
        </div>
      </div>
      {!car.sold && (
        <>
          {/* <div className={classes.ctaWrapper}>
            <ButtonModify label="Modifica auto" onClick={onUpdateCar} />
            <ButtonDelete label="Elimina auto" onClick={onDeleteCar} />
          </div> */}
          <FileOffer url={car.offer} carId={car.id} dealerId={car.dealer.id} />
        </>
      )}

      {/* <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        onChange={() => {}}
      >
        <Tab label="Dettaglio auto" />
        <Tab label="Proposte" />
      </Tabs> */}

      <div style={{ marginTop: '3em', marginBottom: '1em' }}>
        <Button
          variant="outlined"
          className={
            activePanel === 'carDetail'
              ? classes.activeButton
              : classes.inactiveButton
          }
          color="inherit"
          size="large"
          onClick={() => {
            setActivePanel('carDetail')
            history.push(`/cars/${car.id}?tab=carDetail`)
          }}
        >
          Dettaglio Auto
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="outlined"
          className={
            activePanel === 'carDetail'
              ? classes.inactiveButton
              : classes.activeButton
          }
          color="inherit"
          size="large"
          onClick={() => {
            setActivePanel('proposalsList')
            history.push(`/cars/${car.id}?tab=proposalsList`)
          }}
        >
          Proposte
        </Button>
      </div>
      {/* <div style={{ marginTop: '3em', marginBottom: '2em' }}>
        <Typography
          variant="h2"
          display="inline"
          style={{
            textDecoration: activePanel === 'carDetail' ? 'underline' : 'none',
            cursor: 'pointer',
            opacity: activePanel === 'carDetail' ? 1 : 0.5
          }}
          onClick={() => {
            setActivePanel('carDetail')
            history.push(`/cars/${car.id}?tab=carDetail`)
          }}
        >
          Dettaglio auto
        </Typography>
        <Typography variant="h2" display="inline">
          {' | '}
        </Typography>
        <Typography
          variant="h2"
          display="inline"
          style={{
            textDecoration:
              activePanel === 'proposalsList' ? 'underline' : 'none',
            cursor: 'pointer',
            opacity: activePanel === 'proposalsList' ? 1 : 0.5
          }}
          onClick={() => {
            setActivePanel('proposalsList')
            history.push(`/cars/${car.id}?tab=proposalsList`)
          }}
        >
          Proposte
        </Typography>
      </div> */}
      {/* Tab dettaglio */}
      <div role="tabpanel" hidden={activePanel !== 'carDetail'}>
        <Paper
          className={classes.grid2Col}
          style={{ padding: 32 }}
          elevation={0}
        >
          {/* <div> */}
          <CarInfoTable
            power={car.power}
            displacement={car.displacement}
            listPrice={car.listPrice}
            finalPrice={
              car.dealerPrice +
              car.optionalsAfterMarket.reduce((tot, { price }) => {
                return tot + price
              }, 0)
            }
            manufacturerCode={car.manufacturerCode}
            fuelSystem={car.fuelSystem}
            plate={car.plate}
          />
          {/* </div> */}
          <CarOptionalsList
            optionals={car.optionals}
            optionalsAfterMarket={car.optionalsAfterMarket}
          />
        </Paper>
        {/* <div>
          <Typography variant="h4">
            Prezzo finale {numeral(car.totalPrice / 100).format('0,0.00')} €
            (iva esclusa)
          </Typography>
        </div> */}
        {!car.sold && (
          <>
            <div className={classes.ctaWrapper}>
              <ButtonModify label="Modifica auto" onClick={onUpdateCar} />
              <ButtonDelete label="Elimina auto" onClick={onDeleteCar} />
            </div>
            {/* <FileOffer url={car.offer} carId={car.id} dealerId={car.dealer.id} /> */}
          </>
        )}
      </div>
      {/* Tab proposte */}
      <div role="tabpanel" hidden={activePanel !== 'proposalsList'}>
        {car.proposals.map(proposal => {
          return (
            <ProposalCard
              onDuplicate={() => {
                duplicateProposal(proposal)
              }}
              canDuplicate={!car.sold && car.proposals.length < 50}
              onCreatePrivateClient={() => {
                setFormType('private')
                setShowClientForm(true)
              }}
              onCreateCompanyClient={() => {
                setFormType('company')
                setShowClientForm(true)
              }}
              isOperator={isOperator}
              disableActions={
                !car.operator || car.operator.id !== userIdCache || car.sold
              }
              disableContract={car.sold}
              key={proposal.id}
              proposal={proposal}
              onDelete={() => {
                setCurrentProposal(proposal)
                setShowDeleteProposal(true)
              }}
              onUpdate={() => {
                setCurrentProposal(proposal)
                setShowProposalForm(true)
              }}
              onCombine={() => {
                setCurrentProposal(proposal)
                setShowContractForm(true)
              }}
            />
          )
        })}
        {userData && isOperator && !car.sold && car.proposals.length < 50 && (
          <AddProposalCard
            name={(userData.me as OperatorType).name}
            onCreate={() => setShowProposalForm(true)}
            count={50 - car.proposals.length}
            disabled={!(car.operator && car.operator.id === userIdCache)}
          />
        )}
      </div>
      <ProposalForm
        proposal={currentProposal}
        carId={car.id}
        open={showProposalForm}
        handleClose={() => setShowProposalForm(false)}
      />
      <ConfirmDialog
        onConfirm={async () => {
          await client.mutate({
            mutation: DELETE_PROPOSAL,
            variables: { id: (currentProposal as ProposalType).id },
            refetchQueries: [{ query: GET_CAR, variables: { id: car.id } }]
          })
          setShowDeleteProposal(false)
          enqueueSnackbar('Proposta eliminata', {
            variant: 'success'
          })
        }}
        onClose={() => {
          setShowDeleteProposal(false)
        }}
        title="Attenzione"
        open={showDeleteProposal}
      >
        Sei sicuro di voler cancellare questa proposta? L'operazione è
        irreversibile.
      </ConfirmDialog>
      {car.operator &&
        car.operator.id === userIdCache &&
        !car.sold &&
        car.proposals.length < 50 && (
          <AddFab onClick={() => setShowProposalForm(true)} />
        )}
      <CreateContractForm
        open={showContractForm}
        dealerId={car.dealer.id}
        handleClose={() => setShowContractForm(false)}
        proposalId={currentProposal?.id || ''}
      />

      <ClientPrivateForm
        open={showClientForm && formType === 'private'}
        client={null}
        dealerId={car.dealer.id}
        handleClose={() => {
          setShowClientForm(false)
        }}
      />
      <ClientCompanyForm
        open={showClientForm && formType === 'company'}
        client={null}
        dealerId={car.dealer.id}
        handleClose={() => {
          setShowClientForm(false)
        }}
      />
      {isDuplicatingProposal && <FullscreenLoader />}
    </div>
  )
}

export default CarDetail
