import React, { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_LISTS } from 'query'
import { makeStyles, Typography } from '@material-ui/core'
import { getLists, getLists_getLists as ListType } from 'generated/schemaTypes'
import { Redirect } from 'react-router-dom'
import { AddFab, ListItem, ListForm } from 'components'
import { roleVar } from 'cache'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    margin: '0 auto',
    position: 'relative',
    height: '100%'
  },
  createCar: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(4)
  }
}))

export default function Cars() {
  const classes = useStyles()

  const [showListForm, setShowListForm] = useState(false)
  const [currentList, setCurrentList] = useState<ListType | null>(null)
  const roleCache = useReactiveVar(roleVar)
  const {
    data: dataLists,
    loading: loadingLists,
    error: errorLists
  } = useQuery<getLists>(GET_LISTS, {
    fetchPolicy: 'network-only'
  })

  const lists = dataLists ? dataLists.getLists : []

  if (errorLists) return <div>Error!</div>

  if (roleCache !== 'ADMIN') return <Redirect to={{ pathname: '/cars' }} />

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h2">Liste documenti</Typography>
        {loadingLists && (
          <Typography
            variant="h4"
            align="center"
            style={{ marginTop: '100px', height: 50 }}
          >
            Caricamento delle liste di documenti in corso...
          </Typography>
        )}
        {!loadingLists && !errorLists && (
          <>
            {lists.length === 0 && (
              <Typography
                variant="h4"
                align="center"
                style={{ marginTop: '100px', height: 50 }}
              >
                Non è ancora stata creato nessuna lista.
              </Typography>
            )}
            <ul>
              {lists.map(list => (
                <li key={list.id}>
                  <ListItem
                    label={list.label}
                    description={list.description || ''}
                    onUpdate={() => {
                      const currentList = lists.find(
                        _list => _list.id === list.id
                      )
                      setCurrentList(currentList ? currentList : null)
                      setShowListForm(true)
                    }}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <AddFab
        onClick={() => {
          setShowListForm(true)
        }}
      />
      <ListForm
        open={showListForm}
        list={currentList}
        handleClose={() => {
          setShowListForm(false)
          setCurrentList(null)
        }}
      />
    </>
  )
}
